<template>
    <div class="valuer_tool-default-layout-grid">
        <h1 v-if="!isMobile" class="valuer_tool-title">
            {{ $t('valuer_tool.valuation.title') }}
        </h1>
        <value-card />
        <value-card v-if="hasRenov" post-reno />
        <grid-card
            :title="$t('valuer_tool.valuation.scheduled_renov.title')"
            :is-full="hasRenov"
            no-mobile-toggle
            wrap-header
            :no-padding="hasRenov"
            :no-stretch="!hasRenov"
        >
            <template slot="header">
                <b-switch v-model="hasRenov" />
                <p v-if="hasRenov">
                    {{ $t('valuer_tool.valuation.scheduled_renov.comment') }}
                </p>
            </template>
            <template v-if="hasRenov">
                <div class="renovation_card--content">
                    <h5>
                        {{ $t('valuer_tool.valuation.scheduled_renov.budget') }}
                    </h5>
                    <b-field
                        :label="$t('monolith.global.total')"
                        class="renovation_card--content--field"
                    >
                        <b-input v-model="getFeatures.renovation_cost" type="number" />
                        <p class="control">
                            <span class="button is-static">
                                {{ $t('unit.euro') }}
                            </span>
                        </p>
                    </b-field>
                </div>
            </template>
        </grid-card>
        <grid-card :title="$t('valuer_tool.valuation.comments')" is-full no-mobile-toggle>
            <b-input v-model="remarks" type="textarea" rows="5" />
        </grid-card>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import { mapGetters as vuexGetters } from 'vuex'
import ValueCard from './ValueCard.vue'
import GridCard from '../../elements/GridCard'

export default {
    name: 'Valuation',
    components: {
        GridCard,
        ValueCard,
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, [
                'getFeatures',
                'get_map_scene2d',
                'getValuation',
            ]),
            ...mapMutations(requestObjects, ['SET_FEATURES', 'SET_VALUATION']),
        }
    },
    data() {
        return {
            prev_renovation_cost: null,
            hasRenov: false,
        }
    },
    computed: {
        ...vuexGetters(['isMobile']),
        remarks: {
            get() {
                return this.getValuation.remarks
            },
            set(remarks) {
                this.SET_VALUATION({ remarks })
            },
        },
    },
    watch: {
        hasRenov() {
            if (this.getFeatures.renovation_cost) {
                this.prev_renovation_cost = this.getFeatures.renovation_cost
                this.SET_FEATURES({ renovation_cost: 0 })
            } else {
                this.SET_FEATURES({
                    renovation_cost: this.prev_renovation_cost ?? 1,
                })
            }
        },
    },
    mounted() {
        this.hasRenov = !!this.getFeatures.renovation_cost
    },
}
</script>
<style lang="scss" scoped>
.renovation_card--content {
    border-top: 1px solid #d6e2e9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    h5 {
        color: #001837;
        font-size: 14px;
        font-weight: 500;
    }

    &--field {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        & ::v-deep label {
            color: #798da6;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0 !important;
        }
    }
}
</style>
