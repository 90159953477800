<template>
    <div class="floorplan_layout">
        <div v-if="!isMobile" class="floorplan_layout-header">
            <h1 class="floorplan_layout-header-breadcrumbs">
                <router-link
                    class="floorplan_layout-header-breadcrumbs__link"
                    :to="{
                        params: {
                            lang: $route.params.lang,
                        },
                        query: $route.query,
                        name: 'valuation.valuer-app.interior.floorplan.floors',
                    }"
                >
                    {{ $t('valuer_tool.interior.floors.title') }}
                </router-link>
                <img :src="chevron" class="chevron" />
                <span>
                    {{ $t('valuer_tool.interior.floors.floor') + ' ' + floor_level }}
                </span>
            </h1>
            <div class="floorplan_layout-header-actions">
                <b-button
                    v-if="showGenerate"
                    type="is-primary"
                    icon-right="flash"
                    @click="generateRooms"
                >
                    {{ $t('valuer_tool.interior.rooms.generate') }}
                </b-button>
                <b-button type="is-primary" icon-right="plus" @click="openRoomsModal">
                    {{ $t('valuer_tool.interior.rooms.add_room') }}
                </b-button>
            </div>
        </div>
        <empty-list v-if="is_rooms_empty" type="rooms" />
        <div v-else class="rooms">
            <grid-card
                v-for="(room, i) in rooms"
                :key="`room_${i}`"
                class="room"
                no-padding
                :title="isMobile ? room.type : null"
                mobile-margin
                @click="editRoom(i)"
            >
                <template v-if="isMobile" slot="header">
                    <div class="room-header_mobile-actions">
                        <icon-button icon="delete" @click="deleteRoom(i)" />
                        <icon-button icon="edit" @click="editRoom(i)" />
                    </div>
                </template>
                <div v-if="!isMobile" class="room-header">
                    <h2>{{ room.type }}</h2>
                    <div class="room-header-actions">
                        <icon-button icon="delete" @click="deleteRoom(i)" />
                        <icon-button icon="edit" @click="editRoom(i)" />
                    </div>
                </div>
                <div class="room-content">
                    <div class="room-content-item">
                        <label>{{ $t('features.f_living_area') }}</label>
                        <span>{{ room.living_area }} {{ $t('unit.m2') }}</span>
                    </div>
                    <div class="room-content-item">
                        <label>{{ $t('global.comments') }}</label>
                        <span>{{ room.comment }}</span>
                    </div>
                    <div v-if="picsInRoom(room).length" class="room-content-item">
                        <label>{{ $t('valuer_tool.global.photos') }}</label>
                        <div class="room_photos">
                            <img
                                v-for="(pic, i) in picsInRoom(room)"
                                :key="room.room_id + '_pic' + i"
                                :src="pic.preview_url"
                                class="room_photos-item"
                            />
                        </div>
                    </div>
                </div>
            </grid-card>
        </div>
        <div v-if="isMobile" class="mobile_actions_container">
            <b-button
                v-if="showGenerate"
                type="is-primary"
                icon-right="flash"
                class="mobile_action-buttn"
                @click="generateRooms"
            >
                {{ $t('valuer_tool.interior.rooms.generate') }}
            </b-button>
            <b-button
                type="is-primary"
                icon-right="plus"
                class="mobile_action-buttn"
                @click="openRoomsModal"
            >
                {{ $t('valuer_tool.interior.rooms.add_room') }}
            </b-button>
        </div>
        <b-modal
            v-if="is_room_modal_open"
            v-model="is_room_modal_open"
            destroy-on-hide
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            class="modal"
            @close="closeRoomsModal"
        >
            <div class="room_modal">
                <div class="room_modal-header">
                    <h4 class="room_modal-header-title">
                        {{
                            $t(
                                `valuer_tool.interior.rooms.${
                                    isEditing !== false ? 'edit_room' : 'add_room'
                                }`
                            )
                        }}
                    </h4>
                </div>
                <div class="room_modal-content">
                    <b-field
                        label="What is the type of room?"
                        :type="{ 'is-danger': $v.new_room.type.$invalid }"
                        :message="
                            !$v.new_room.type.singleIteration
                                ? `A room named ${new_room.type} already exists.`
                                : null
                        "
                    >
                        <b-input v-model="new_room.type" type="text" />
                    </b-field>
                    <b-field :label="$t('features.f_living_area')">
                        <b-input v-model="new_room.living_area" type="number" />
                    </b-field>
                    <b-field :label="$t('global.comments')" class="input--full">
                        <b-input v-model="new_room.comment" type="textarea" />
                    </b-field>
                    <div class="upload_container">
                        <documents
                            purpose="interior_picture"
                            :request-ref="getRequestRef"
                            :disabled="isModalUploadDisabled"
                            type="picture"
                            :room-id="current_room_id"
                            thumbnails
                        />
                    </div>
                </div>
                <div class="room_modal-footer">
                    <b-button @click="closeRoomsModal">Cancel</b-button>
                    <b-button
                        type="is-primary"
                        :disabled="isConfirmRoomDisabled"
                        @click="confirmRoom()"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                    <b-button
                        v-if="isEditing === false"
                        type="is-primary"
                        :disabled="isConfirmRoomDisabled"
                        @click="confirmRoom(true)"
                    >
                        {{ $t('valuer_tool.interior.rooms.add_and_new_room') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from '@/composables/fake-vuex'
import { mapGetters as vuexGetters } from 'vuex'
import { useRequest } from '@/composables/request'
import { useDocuments } from '@/composables/documents'
import { useUser } from '@/composables/user'
import IconButton from '@/components/elements/IconButton.vue'
import Documents from '@/apps/Valuation/components/ValuerTool/elements/DocMgmt/Documents.vue'
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import EmptyList from './EmptyList.vue'
import chevron from '@/shared/assets/icons/chevron.svg'
import utils from '@/shared/plugins/utils'

let newRoom = () => ({
    type: '',
    living_area: 0,
    comment: '',
    id: utils.uuidv4(),
})

export default {
    name: 'Rooms',
    components: {
        EmptyList,
        IconButton,
        GridCard,
        Documents,
    },
    setup() {
        const requestObjects = useRequest()
        const documentsObjects = useDocuments()
        const { hasRole } = useUser()
        return {
            hasRole,
            ...mapGetters(requestObjects, [
                'getFeatures',
                'getOVMFeatures',
                'getRequestRef',
            ]),
            ...mapMutations(requestObjects, ['SET_OVM_FEATURES', 'SET_FEATURES']),
            ...mapGetters(documentsObjects, ['getDocuments']),
            ...mapActions(documentsObjects, ['remove_document', 'add_documents']),
        }
    },
    data: () => ({
        is_room_modal_open: false,
        new_room: newRoom(),
        chevron,
        isEditing: false,
    }),
    validations: {
        new_room: {
            type: {
                singleIteration: function(value) {
                    let foundItem = this.rooms.findIndex((x) => x.type === value)
                    return foundItem === -1 || foundItem === this.isEditing
                },
            },
        },
    },
    computed: {
        ...vuexGetters(['isMobile']),
        showGenerate() {
            return this.is_rooms_empty && this.hasRole('developer')
        },
        is_rooms_empty() {
            return Array.isArray(this.rooms) ? !this.rooms.length : true
        },
        floor_level() {
            return this.$route.query.floor
        },
        isModalUploadDisabled() {
            return utils.isEmptyStr(this.new_room.type)
        },
        current_room_id() {
            return this.new_room.id
        },
        isConfirmRoomDisabled() {
            return utils.isEmptyStr(this.new_room.type)
        },
        floorIndex() {
            return this.getOVMFeatures.floors?.findIndex(
                (floor) => floor.level === +this.floor_level
            )
        },
        rooms: {
            get() {
                return this.getOVMFeatures.floors?.[this.floorIndex]?.rooms
            },
            set(rooms) {
                const floors = this.getOVMFeatures.floors
                floors[this.floorIndex].rooms = rooms
                this.SET_OVM_FEATURES({ floors })
            },
        },
    },
    mounted() {
        if (this.floorIndex === -1) {
            this.$buefy.toast.open({
                duration: 5000,
                message: this.$t('valuer_tool.interior.floors.redirected'),
                position: this.isMobile ? 'is-top' : 'is-bottom',
                type: 'is-warning',
            })
            this.$router.push({
                name: 'valuation.valuer-app.interior.floorplan.floors',
                query: { valuation_request_ref: this.$route.query.valuation_request_ref },
            })
        }
    },
    methods: {
        openRoomsModal() {
            this.new_room = newRoom()
            this.is_room_modal_open = true
        },
        closeRoomsModal() {
            this.is_room_modal_open = false
            this.isEditing = false
            this.new_room = newRoom()
        },
        confirmRoom(continueCreating = false) {
            if (this.isEditing === false) {
                this.rooms.push(this.new_room)
            } else {
                this.rooms[this.isEditing] = this.new_room
            }
            if (!continueCreating) {
                this.closeRoomsModal()
            } else {
                this.new_room = newRoom()
            }
        },
        picsInRoom(room) {
            let pics = this.getDocuments.filter((doc) => doc.room_id === room.id)

            pics.forEach((pic) => {
                pic.preview_url =
                    utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                        this.$config.VALUATION_API_URL,
                        'document',
                        pic.document_ref,
                    ]) + '?view=true'
            })

            return pics
        },
        generateRooms() {
            this.rooms = [
                {
                    type: 'Entryway (test)',
                    living_area: 5,
                    comment:
                        'This is a test comment.\nThe entryway is quite narrow. It has been renovated in 2021.',
                    id: utils.uuidv4(),
                },
                {
                    type: 'Kitchen (test)',
                    living_area: 15,
                    comment:
                        'This is a test comment.\nThe kitchen is nice and has been renovated in 2022.',
                    id: utils.uuidv4(),
                },
                {
                    type: 'Living room (test)',
                    living_area: 25,
                    comment:
                        'This is a test comment.\nThe living room is very spatious but the floors show signs of ageing.',
                    id: utils.uuidv4(),
                },
            ]
        },
        deleteRoom(index) {
            this.rooms.splice(index, 1)
        },
        editRoom(index) {
            this.isEditing = index
            this.new_room = { ...this.rooms[index] }
            this.is_room_modal_open = true
        },
        delete_document(document_ref) {
            this.$axios
                .delete(
                    utils.urlJoin(this.$config.VALUATION_API_URL, [
                        'document',
                        document_ref,
                    ])
                )
                .then((_) => {
                    this.remove_document(document_ref)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.rooms {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media screen and (max-width: 999px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 97px;
    }

    .room {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media screen and (max-width: 999px) {
            padding: 16px 0;
            gap: 0;
        }

        &-header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &_mobile-actions {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                margin-right: 8px;
            }

            &-actions {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &-item {
                display: flex;
                flex-direction: column;
                gap: 5px;

                label {
                    color: #798da6;
                    font-size: 12px;
                    font-weight: 500;
                }

                span {
                    color: #001837;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    white-space: pre-line;
                }

                .room_photos {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    &-item {
                        width: 56px;
                        height: 56px;
                        position: relative;
                        border-radius: 8px;
                        object-fit: cover;
                        border: 1px solid #d6e2e9;
                    }
                }
            }
        }
    }
}

.floorplan_layout-header-breadcrumbs {
    &__link {
        color: #627ca1;
        &:hover {
            color: #009cff;
        }
    }
}

.upload_container {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-pictures {
        display: flex;
        flex-direction: row;
        gap: 12px;

        img {
            height: 55px;
            width: 55px;
            border-radius: 8px;
            border: 1px solid #d6e2e9;
            object-fit: cover;
            object-position: center center;
        }
    }
}

.room_modal {
    height: fit-content;
    border-radius: 8px;
    border: 1px solid #d6e2e9;
    background: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);
    overflow: hidden;

    & > * {
        padding: 24px;

        @media screen and (max-width: 999px) {
            padding: 16px;
        }
    }

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        &-title {
            color: #001837;
            font-size: 20px;
            font-weight: 700;
            flex-grow: 1;
        }
    }

    &-content {
        border-top: 1px solid #d6e2e9;
        border-bottom: 1px solid #d6e2e9;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        min-width: 35vw;

        @media screen and (max-width: 999px) {
            display: flex;
            flex-direction: column;
            gap: 16px;

            & ::v-deep .field {
                margin-bottom: 0 !important;
            }
        }

        .input--full {
            grid-column: 1 / 3;
        }

        .upload_draggable {
            width: 100%;
        }
    }

    &-footer {
        display: flex;
        flex-direction: row;
        gap: 16px;

        & > * {
            flex-grow: 1;
        }

        @media screen and (max-width: 999px) {
            padding: 16px;
            flex-wrap: wrap;
            gap: 8px;
        }
    }
}
</style>
