<template>
    <grid-card :title="$t('valuer_tool.general.size.title')" :is-full="!is_apartment">
        <div class="grid_card-content_flex">
            <div class="content_flex-item">
                <b-field :label="$t('features.f_living_area')">
                    <b-input v-model="f_living_area" />
                </b-field>
            </div>
            <div class="content_flex-item">
                <b-field
                    v-if="f_inner_volume"
                    :label="$t('features.f_approx_living_area')"
                >
                    <b-input
                        :value="Math.floor(Math.floor(f_inner_volume) / 2.8)"
                        type="number"
                        step=".01"
                        disabled
                    />
                </b-field>
            </div>
            <div
                class="content_flex-item"
                :class="{ 'content_flex-item--full': is_apartment }"
            >
                <b-field :label="$t('features.f_parcel_area')">
                    <b-input v-model="f_parcel_area" />
                </b-field>
            </div>
            <div v-if="is_house" class="content_flex-item">
                <b-field :label="$t('features.f_building_area')">
                    <b-input v-model="f_building_area" />
                </b-field>
            </div>
        </div>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'

export default {
    name: 'Size',
    components: {
        GridCard,
    },
    data: () => ({
        f_living_area: null,
        f_parcel_area: null,
        f_building_area: null,
        f_inner_volume: null,
    }),
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures']),
            ...mapMutations(requestObjects, ['SET_FEATURES']),
        }
    },
    computed: {
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_house() {
            return utils.is_house(this.building_type)
        },
        is_apartment() {
            return utils.is_apartment(this.building_type)
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        is_building() {
            return utils.is_building(this.building_type)
        },
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_living_area(val) {
            this.SET_FEATURES({
                f_living_area: +val,
            })
        },
        f_parcel_area(val) {
            this.SET_FEATURES({
                f_parcel_area: +val,
            })
        },
        f_building_area(val) {
            this.SET_FEATURES({
                f_building_area: +val,
            })
        },
        f_inner_volume(val) {
            this.SET_FEATURES({
                f_inner_volume: +val,
            })
        },
    },
    mounted() {
        this.f_living_area = this.getFeatures.f_living_area
        this.f_parcel_area = this.getFeatures.f_parcel_area
        if (this.getFeatures.f_inner_volume) {
            this.f_inner_volume = this.getFeatures.f_inner_volume
        }
        if (this.is_house) {
            this.f_building_area = this.getFeatures.f_building_area
        }
    },
}
</script>
