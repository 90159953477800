import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

// we can access router now, but I think better will be to pass router data to action call from component
//import router from '../router'

import auth from './Auth/index'
import log from './Log/index'
import valuationStore from '@/apps/Valuation/store'
import ersStore from '@/apps/ERS/store'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        loading: false,
        helpPage: null,
        unread_messages: null,
        isRefMapShown_mobile: 1,
        isMobile: utils.isMobile(),
    }
}

const state = getDefaultState()

export default new Vuex.Store({
    state,
    getters: {
        flow_type() {
            return Vue.prototype.$config.FLOW_TYPE
        },
        hasUnreadMessages(state) {
            return !!state.unread_messages
        },
        getFullStore(state, getters, rootState, rootGetters) {
            let data = { ...state }
            data.valuationStore = getters['getFullValuationStore']
            data.auth = rootGetters['auth/']
            return data
        },
        getFullValuationStore(state, getters, rootState, rootGetters) {
            return rootGetters['valuationStore/getFullValuationStore']
        },
        getFeatures(state, getters, rootState, rootGetters) {
            return rootGetters['valuationStore/Address/getFeatures']
        },
        get_map_scene2d(state, getters, rootState, rootGetters) {
            return rootGetters['valuationStore/Address/get_map_scene2d']
        },
        getDVMFeatures(state, getters, rootState, rootGetters) {
            return rootGetters['valuationStore/Address/getDVMFeatures']
        },
        getOVMFeatures(state, getters, rootState, rootGetters) {
            return rootGetters['valuationStore/Address/getOVMFeatures']
        },
        getValuation(state, getters, rootState, rootGetters) {
            return rootGetters['valuationStore/Address/getValuation']
        },
        getRefMapShown(state) {
            return state.isRefMapShown_mobile
        },
        isMobile(state) {
            return state.isMobile
        },
    },
    mutations: {
        UPDATE_IS_MOBILE(state) {
            state.isMobile = utils.isMobile()
        },
        SET_HELP_PAGE(state, page) {
            state.helpPage = page
        },
        SET_UNREAD_MESSAGES(state, unread_messages) {
            state.unread_messages = unread_messages
        },
        TOGGLE_REF_MAP_MOBILE(state, isShown) {
            state.isRefMapShown_mobile = isShown
        },
    },
    actions: {
        track_event(context, { event, route, data }) {
            let { fullPath, query, params, path, name } = route
            axios.post(utils.urlJoin(Vue.prototype.$config.VALUATION_API_URL, '/track'), {
                event: {
                    name: event,
                    location: { fullPath, query, params, path, name },
                    data,
                },
            })
        },
    },
    modules: {
        auth,
        valuationStore,
        ersStore,
        log,
    },
})
