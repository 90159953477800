<template>
    <div>
        <div id="cronofy-calendar-sync"></div>
    </div>
</template>

<script>
import { loadScript } from 'vue-plugin-load-script'

export default {
    name: 'CronofySync',
    props: {
        elementToken: {
            default: null,
            type: String,
        },
        authorizationUrl: {
            required: true,
            type: String,
        },
    },
    data: function() {
        return {
            cronofySync: null,
        }
    },
    computed: {
        getLang() {
            return this.$route.params.lang
        },
    },
    watch: {
        getLang(lang) {
            if (!this.cronofySync) return

            this.cronofySync.update({
                locale: lang,
            })
        },
    },
    mounted() {
        // Will only be loaded once:
        loadScript('https://elements.cronofy.com/js/CronofyElements.v1.56.5.js').then(
            () => {
                // TODO: we might want to get redirect URL from parent component (and ensure it does not change)
                const redirect_url = new URL(window.location.href)
                redirect_url.searchParams.delete('tab')
                redirect_url.searchParams.append('tab', 'availability')

                // eslint-disable-next-line no-undef
                this.cronofySync = CronofyElements.CalendarSync({
                    target_id: 'cronofy-calendar-sync',
                    locale: this.$route.params.lang,
                    data_center: 'de',
                    element_token: this.elementToken,
                    authorization_url: this.authorizationUrl,
                    authorization: {
                        redirect_uri: redirect_url,
                    },

                    styles: {
                        prefix: 'CalendarSync',
                    },
                })
            }
        )
    },
}
</script>
