import i18n from '@/i18n'
import ERSLayout from './layout.vue'
import QuestionnaireLayout from './questionnaireLayout.vue'
import Welcome from './components/pages/Welcome'
import BuildingView from './components/pages/address/BuildingView'
import BuildingDimensions from './components/pages/building_dimensions/BuildingDimensions'
import LivingArea from './components/pages/building_dimensions/LivingArea'
import Attic from './components/pages/building_dimensions/Attic'
import Basement from './components/pages/building_dimensions/Basement'
import MapSelect from './components/pages/address/MapSelect'
import BuildingType from './components/pages/BuildingType'
import EPC from './components/pages/house_info/EPC'
import EpcScore from './components/pages/house_info/EpcScore'
import ConstructionYear from './components/pages/house_info/ConstructionYear'
import WallInsulation from './components/pages/insulation/WallInsulation'
import RoofInsulation from './components/pages/insulation/RoofInsulation'
import FloorInsulation from './components/pages/insulation/FloorInsulation'
import WindowGlazing from './components/pages/insulation/WindowGlazing'
import EndingSection from './components/pages/insulation/EndingSection'
import SolarPanels from './components/pages/consumption/SolarPanels'
// import Ventilation from './components/pages/consumption/Ventilation'
import Consumers from './components/pages/consumption/Consumers'
import Heating from './components/pages/consumption/Heating'
import Consumption from './components/pages/consumption/Consumption'
import EnergyConsumption from './components/pages/consumption/EnergyConsumption'
//import HeatingConsumption from './components/pages/consumption/HeatingConsumption'
import ElectricVehicles from './components/pages/consumption/ElectricVehicles'
import Result from './components/pages/result/Result'
import Report from './components/pages/result/Report'
import OutOfBounds from './components/pages/OutOfBounds'
import Submitted from './components/pages/Submitted'
import Terms from './components/pages/Terms'
import Downtime from './components/pages/Downtime'
import Unavailable from './components/pages/Unavailable'
import Summary from './components/pages/Summary.vue'
import CustomerRef from './components/pages/CustomerRef.vue'

const ersRoutes = [
    {
        path: '/ers',
        name: 'ers',
        component: ERSLayout,
        redirect: {
            name: 'ers.welcome',
        },
        children: [
            {
                path: 'welcome',
                name: 'ers.welcome',
                component: Welcome,
            },
            {
                path: 'questionnaire',
                name: 'ers-questionnaire',
                component: QuestionnaireLayout,
                children: [
                    {
                        path: 'customer-ref',
                        name: 'ers.customer-ref',
                        component: CustomerRef,
                        meta: {
                            step: 0,
                            substep: 0,
                        },
                    },
                    {
                        path: 'building-type',
                        name: 'ers.building-type',
                        component: BuildingType,
                        meta: {
                            step: 1,
                            substep: 0,
                        },
                    },
                    {
                        path: 'address',
                        name: 'ers.address',
                        // async loading as this component loads a 8MB json file
                        component: () =>
                            import('./components/pages/building_dimensions/Address'),
                        meta: {
                            step: 2,
                            substep: 0,
                        },
                    },
                    {
                        path: 'insulation/attic',
                        name: 'ers.attic',
                        component: Attic,
                        meta: {
                            step: 3,
                            substep: 1,
                        },
                    },
                    {
                        path: 'insulation/basement',
                        name: 'ers.basement',
                        component: Basement,
                        meta: {
                            step: 3,
                            substep: 2,
                        },
                    },
                    {
                        path: 'building/dimensions',
                        name: 'ers.building-dimensions',
                        component: BuildingDimensions,
                        meta: (route, livingAreaChanged) => {
                            return {
                                step: 3,
                                substep: livingAreaChanged ? 4 : 3,
                            }
                        },
                    },
                    {
                        path: 'features/living-area',
                        name: 'ers.living-area',
                        component: LivingArea,
                        meta: (route, level) => {
                            return {
                                step: 3,
                                substep: level && level > 2 ? 3 : 4,
                            }
                        },
                    },
                    {
                        path: 'features/epc',
                        name: 'ers.epc',
                        component: EPC,
                        meta: {
                            step: 4,
                            substep: 1,
                        },
                    },
                    {
                        path: 'features/epc-score',
                        name: 'ers.epc-score',
                        component: EpcScore,
                        meta: {
                            step: 4,
                            substep: 1,
                        },
                    },
                    {
                        path: 'features/construction-year',
                        name: 'ers.construction-year',
                        component: ConstructionYear,
                        meta: {
                            step: 4,
                            substep: 2,
                        },
                    },
                    {
                        path: 'insulation/roof',
                        name: 'ers.roof-insulation',
                        component: RoofInsulation,
                        meta: {
                            step: 4,
                            substep: 3,
                        },
                    },
                    {
                        path: 'insulation/walls',
                        name: 'ers.wall-insulation',
                        component: WallInsulation,
                        meta: {
                            step: 4,
                            substep: 4,
                        },
                    },
                    {
                        path: 'insulation/floor',
                        name: 'ers.floor-insulation',
                        component: FloorInsulation,
                        meta: {
                            step: 4,
                            substep: 5,
                        },
                    },
                    {
                        path: 'insulation/windows',
                        name: 'ers.window-glazing',
                        component: WindowGlazing,
                        meta: {
                            step: 4,
                            substep: 6,
                        },
                    },
                    {
                        path: 'insulation/heating',
                        name: 'ers.heating',
                        component: Heating,
                        meta: {
                            step: 4,
                            substep: 7,
                        },
                    },
                    {
                        path: 'consumption/solar-panels',
                        name: 'ers.solar-panels',
                        component: SolarPanels,
                        meta: {
                            step: 4,
                            substep: 8,
                        },
                    },

                    {
                        path: 'consumption/end-section',
                        name: 'ers.end-section',
                        component: EndingSection,
                        meta: {
                            step: 5,
                            substep: 0,
                        },
                    },

                    {
                        path: 'energy-consumption',
                        name: 'ers.energy-consumption',
                        component: EnergyConsumption,
                        meta: {
                            step: 5,
                            substep: 0,
                        },
                    },

                    // {
                    //     path: 'consumption/ventilation',
                    //     name: 'ers.ventilation',
                    //     component: Ventilation,
                    //     meta: {
                    //         step: 4,
                    //         substep: 2,
                    //     },
                    // },
                    {
                        path: 'consumption/consumers',
                        name: 'ers.consumers',
                        component: Consumers,
                        meta: {
                            step: 5,
                            substep: 1,
                        },
                    },
                    {
                        path: 'consumption/big-consumers',
                        name: 'ers.big-consumers',
                        component: ElectricVehicles,
                        meta: {
                            step: 5,
                            substep: 2,
                        },
                    },
                    {
                        path: 'consumption',
                        name: 'ers.fuel-consumption',
                        component: Consumption,
                        meta: (route) => ({
                            step: 5,
                            substep: route.params.fuel === 'electricity' ? 4 : 5,
                        }),
                    },
                    {
                        path: 'out-of-bounds',
                        name: 'ers.out-of-bounds',
                        component: OutOfBounds,
                        meta: {
                            step: 2,
                            substep: 0,
                        },
                    },
                    {
                        path: 'processing',
                        name: 'ers.processing',
                        component: Submitted,
                        meta: {
                            step: 6,
                            substep: 0,
                        },
                    },
                    {
                        path: 'unavailable',
                        name: 'ers.unavailable',
                        component: Unavailable,
                    },
                ],
            },
            {
                path: 'result',
                name: 'ers.result',
                component: Result,
            },
            {
                path: 'report',
                name: 'ers.report',
                component: Report,
            },
            {
                path: 'terms-conditions',
                name: 'ers.terms-conditions',
                component: Terms,
            },
            {
                path: 'down',
                name: 'ers.down',
                component: Downtime,
            },
            {
                path: 'summary',
                name: 'ers.summary',
                component: Summary,
            },
        ],
    },
]

export default ersRoutes
