<template>
    <div class="valuer-settings">
        <section class="container is-max-desktop">
            <div v-if="isWarningMessage || !cronofyUser" class="warning">
                <div class="warning__icon">
                    <img src="@/assets/icons/ic-warning.svg" alt="" />
                </div>
                <p class="warning__text">
                    {{ $t('valuer_tool.valuer_settings.availability_warning') }}
                </p>
            </div>
            <b-tabs
                v-model="valuerTab"
                position="is-centered"
                class="block"
                :destroy-on-hide="true"
            >
                <b-tab-item :label="$t('valuer_tool.valuer.info')" value="info">
                    <div class="main">
                        <h1>{{ $t('valuer_tool.borrower.valuer_info.title') }}</h1>
                        <p>
                            {{ $t('valuer_tool.borrower.valuer_info.description') }}
                        </p>
                        <div class="main__content">
                            <div class="cards">
                                <div class="card">
                                    <div class="card__title">
                                        {{
                                            $t(
                                                'valuer_tool.borrower.valuer_info.profile_card-title'
                                            )
                                        }}
                                    </div>
                                    <div class="card-content">
                                        <div class="card-content__section">
                                            <h2>{{ $t('monolith.global.email') }}:</h2>
                                            <span>{{ getEmail }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="getAccreditations && getAccreditations.length"
                                    class="card"
                                >
                                    <div class="card__title">
                                        {{ $t('valuer_tool.valuer.accreditations') }}
                                    </div>
                                    <div class="card-content">
                                        <div
                                            v-for="acc in getAccreditations"
                                            :key="acc.institution"
                                            class="card-content__section"
                                        >
                                            <div class="card-content__section-title">
                                                {{ acc.institution }}
                                            </div>
                                            <h2>
                                                {{
                                                    $t(
                                                        'valuer_tool.borrower.valuer_info.accreditation_card-number'
                                                    )
                                                }}
                                                :
                                            </h2>
                                            <span>{{ acc.accreditation_number }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item
                    :label="$t('valuer_tool.valuer.availability')"
                    value="availability"
                >
                    <template v-if="loading || (!cronofyUser && !cronofyRedirect)">
                        <progress class="progress is-small" max="100"></progress>
                    </template>
                    <template v-else>
                        <CronofySync
                            v-if="cronofyRedirect"
                            :element-token="
                                cronofyUser ? cronofyUser.cronofy_element_token : null
                            "
                            :authorization-url="cronofyRedirect"
                        />
                        <template v-if="cronofyUser">
                            <AvailabilityRules
                                :cronofy-user="cronofyUser"
                                @on-rules-change="handleRules"
                            />
                        </template>
                    </template>
                </b-tab-item>
                <b-tab-item
                    :label="$t('valuer_tool.valuer.appointments')"
                    value="appointments"
                >
                    <Summary :display-list="true"></Summary>
                </b-tab-item>
            </b-tabs>
        </section>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'
import CronofySync from './cronofyElements/CronofySync'
import AvailabilityRules from './AvailabilityRules'
import Summary from '../request/ovm/Summary'

export default {
    name: 'ValuerSettings',
    components: {
        CronofySync,
        AvailabilityRules,
        Summary,
    },
    data: () => ({
        cronofyUser: null,
        cronofyRedirect: null,
        loading: true,
        effectiveUserId: null,
        valuerTab: 'info',
        rules: [],
        isWarningMessage: false,
    }),
    computed: {
        ...mapGetters('auth', ['userId', 'getEmail', 'getAccreditations']),
        queryUserId() {
            return this.$route.query.id
        },
    },
    watch: {
        rules(newVal) {
            this.isWarningMessage = newVal.length === 0
        },
        valuerTab(newVal) {
            if (this.$route.query.tab !== newVal)
                this.$router.replace({ query: { tab: newVal } })
        },
    },
    mounted() {
        // TODO: this should never occur?
        // if (!(this.queryUserId || this.userId)) {
        //     this.$store.dispatch('auth/check_authentication').then(() => {
        //     })

        // TODO: this should all be moved to its own component only loaded when the tab is active:
        const fn = this.$route.query.code
            ? this.cronofyLoginCallback
            : this.loadCronofyUser

        fn().then((res) => {
            const cronofyUrl = new URL(res.data.cronofy_url)
            cronofyUrl.searchParams.append('redirect_uri', window.location.href)
            this.cronofyRedirect = cronofyUrl.href

            // if user is not logged into Cronofy, API returns 307 with only cronofy_url
            if (res.status === 200) this.cronofyUser = res.data

            this.loading = false

            this.isWarningMessage = this.cronofyUser?.availability_rules.length === 0
        })

        if (this.$route.query.tab) {
            this.valuerTab = this.$route.query.tab
        }
    },
    methods: {
        loadCronofyUser() {
            return this.$axios.get(
                utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'availability',
                    'user',
                    this.queryUserId || this.userId,
                ]),
                { validateStatus: (code) => code === 200 || code === 307 }
            )
        },
        handleRules(rules) {
            this.rules = rules
        },
        cronofyLoginCallback() {
            // Returning from a Cronofy add account flow
            const code = this.$route.query.code
            const state = this.$route.query.state

            this.$router.replace({
                query: {
                    ...this.$route.query,
                    code: undefined,
                    state: undefined,
                },
            })

            // NOTE: Cronofy stupidly adds this to the URL, then complains that redirect_url does not match previous request
            // TODO: see if we can ensure that we are using the same URL as the one provided in CronofySync
            const redirect_uri = new URL(window.location.href)
            redirect_uri.searchParams.delete('authenticated_profile_id')

            return this.$axios.post(
                utils.urlJoin(this.$config.VALUATION_API_URL, [
                    'availability',
                    'user',
                    this.userId,
                ]),
                {
                    code,
                    state,
                    redirect_uri: redirect_uri,
                }
            )
        },
    },
}
</script>
<style lang="scss" scoped>
.valuer-settings {
    background: #f9fafc;
    padding-block: 3rem;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-block: 2.5rem;
    gap: 3rem;
    flex: 1 0 0;
    h1 {
        color: #001837;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    p {
        color: #798da6;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    &__content {
        display: flex;
        min-width: 100%;
        gap: 1rem;
        flex-wrap: wrap;
    }
}

.cards {
    .card {
        flex: 1 0 0;
        &__title {
            display: flex;
            padding: 1.5rem;
            align-items: flex-start;
            gap: 1.25rem;
            align-self: stretch;
            border-bottom: 1px solid #d6e2e9;
            background: #fff;
            color: #001837;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; /* 125% */
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }
        &-content {
            display: flex;
            padding: 1.5rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            align-self: stretch;
            &__section {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.25rem;
                &-title {
                    color: #001837;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.25rem; /* 100% */
                    margin-bottom: 1rem;
                }
                h2 {
                    color: rgba(0, 24, 55, 0.6);
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.25rem; /* 166.667% */
                    margin-bottom: 0.25rem;
                }
                span {
                    color: #001837;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.25rem; /* 142.857% */
                }
            }
        }
    }
}

.warning {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    background: rgba(255, 170, 0, 0.2);
    margin: auto;
    margin-bottom: 1.5rem;
    max-width: 40rem;
    &__icon {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.52081rem;
        justify-content: center;
        align-items: center;
        gap: 0.52081rem;
        border-radius: 4.16669rem;
        background: rgba(255, 170, 0, 0.2);
        img {
            flex-shrink: 0;
            fill: #fa0;
        }
    }
    &__text {
        color: #001837;
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.125rem; /* 150% */
    }
}
</style>
