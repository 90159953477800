<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="result-page">
        <template v-if="getSimulationStatus === 'finished'">
            <div class="page_area cover_page">
                <div class="page-header">
                    <div>
                        <img src="@/assets/img/re-logo-tight.svg" class="re-logo" />
                    </div>
                    <img v-if="bankLogo" :src="bankLogo()" class="bank-logo" />
                </div>
                <h1 class="cover_page-title">
                    {{ $t('ers_report.title1') }}
                    <b>{{ $t('ers_report.title2') }}</b>
                    <br />
                    <b>{{ $te('ers_report.title3') ? $t('ers_report.title3') : '' }}</b>
                    {{ $t('ers_report.title4') }}
                </h1>
                <div class="cover_page-picture">
                    <div
                        class="cover_page-picture-content"
                        :class="{
                            'cover_page-picture-content--3d':
                                getMapScene3d.map_data_ready &&
                                +getFeatures.effective_level > 2,
                        }"
                    >
                        <renderer
                            v-if="
                                getMapScene3d.map_data_ready &&
                                    +getFeatures.effective_level > 2
                            "
                            class="ers-map-renderer"
                            :building-id="getMapScene3d.building_id"
                            :solar-potential="false"
                            :map-scene3d="getMapScene3d"
                            :show-attributions="false"
                            :auto-rotate="false"
                            @update:mapScene3d="SET_MAP_SCENE3D($event)"
                        />
                        <re-map
                            v-else
                            class="ers-map-renderer"
                            :building-id="
                                +getFeatures.effective_level < 2
                                    ? null
                                    : getAddress.building_id
                            "
                            :parcel-ids="getMapScene2d.parcel_ids"
                            :level="
                                +getFeatures.effective_level < 2
                                    ? 1
                                    : getFeatures.effective_level
                            "
                            :center="{
                                lat: getMapScene2d.center.lat,
                                lng: getMapScene2d.center.lng,
                            }"
                            :map-scene2d="getMapScene2d"
                            :flood-visible="false"
                            read-only
                            :size="+getFeatures.effective_level > 2 ? 'fit' : 'medium'"
                            :layers-control-visible="false"
                            :zoom-visible="false"
                        ></re-map>
                    </div>
                    <div class="cover_page-picture-disclaimer">
                        <h1>
                            <img src="@/assets/icons/alert-circle.svg" />
                            {{ $t('ers_report.disclaimer.title') }}
                        </h1>
                        <p>
                            {{ $t('ers_report.disclaimer.body1') }}
                            <b>
                                {{ $t('ers_report.disclaimer.body2') }}
                            </b>
                            {{ $t('ers_report.disclaimer.body3') }}
                        </p>
                        <p>
                            {{ $t('ers_report.disclaimer.body4') }}
                            <b>
                                {{ $t('ers_report.disclaimer.body5') }}
                            </b>
                            {{ $t('ers_report.disclaimer.body6') }}
                        </p>
                        <p>
                            <b>
                                {{ $t('ers_report.disclaimer.body7') }}
                            </b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="page_area">
                <div class="page-header">
                    <div>
                        <img src="@/assets/img/re-logo-tight.svg" class="re-logo" />
                        <img v-if="bankLogo" :src="bankLogo()" class="bank-logo" />
                    </div>
                    <div class="address">
                        <img
                            src="@/assets/icons/map-marker.svg"
                            alt=""
                            class="address__icon"
                        />
                        <div class="address__text">
                            <div class="text__street">
                                {{ formatAddress.firstLine }}
                            </div>
                            <div class="text__city">
                                {{ formatAddress.secondLine }}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="result-page__content _content">
                        <h1 class="result-page__title">
                            {{ $t('ers_report.header_title') }}
                        </h1>
                        <p class="result-page__subtitle">
                            {{ $t('ers_report.header_subtitle_pdf') }}
                        </p>
                    </div>
                    <div class="_content__results results">
                        <div class="results__epc">
                            <epc-score
                                :region="getRegion"
                                :label="getCurrentEpcLabel"
                                :score="Math.round(getCurrentScenario.epc_ind)"
                                :percent="getEpcPercents"
                            />
                            <consumption on-report />
                        </div>
                        <package-selector on-report />
                        <div
                            v-if="getScenariosAvailable"
                            class="results__renovations renovations"
                        >
                            <renovation-grid on-report />
                            <div class="renovations__summary">
                                <Summary on-report />
                                <property-value />
                                <Savings />
                            </div>
                        </div>
                    </div>
                </div>
                <p class="result-page__footnote">
                    {{ $t('energy.result.footnote') }}
                </p>
            </div>

            <div v-if="getScenariosAvailable" class="page_area page_area-savings">
                <div class="page-header">
                    <div>
                        <img src="@/assets/img/re-logo-tight.svg" class="re-logo" />
                        <img v-if="bankLogo" :src="bankLogo()" class="bank-logo" />
                    </div>
                    <div class="address">
                        <img
                            src="@/assets/icons/map-marker.svg"
                            alt=""
                            class="address__icon"
                        />
                        <div class="address__text">
                            <div class="text__street">
                                {{ formatAddress.firstLine }}
                            </div>
                            <div class="text__city">
                                {{ formatAddress.secondLine }}
                            </div>
                        </div>
                    </div>
                </div>
                <h1 class="page_area-savings__title">
                    {{ $t('ers_report.property_page.title') }}
                </h1>
                <p
                    class="page_area-savings__info-bubble"
                    v-html="
                        $t('ers_report.property_page.info_bubble', {
                            future_epc: parseInt(getSelectedScenario.epc_ind),
                            val: int_value_formatter(getPropertyValue),
                        })
                    "
                />
                <p class="page_area-savings__subtitle">
                    {{ $t('ers_report.property_page.subtitle') }}
                </p>

                <h1 class="page_area-savings__title">
                    {{ $t('ers_report.property_page.section_title') }}
                </h1>

                <section class="page_area-savings__section">
                    <div>
                        <h2 class="page_area-savings__section-title">
                            {{ $t('ers_report.property_page.epc_title') }}
                        </h2>
                        <span class="page_area-savings__section-score">
                            {{ parseInt(getRenovationsSummary.epcSaving) }}
                            kWh/m²
                        </span>
                        <span class="page_area-savings__section-unit">
                            {{ $t('ers_report.per_year') }}
                        </span>
                    </div>
                    <p class="page_area-savings__section-text">
                        {{ $t('ers_report.property_page.epc_text') }}
                    </p>
                </section>

                <section class="page_area-savings__section">
                    <div>
                        <h2 class="page_area-savings__section-title">
                            {{ $t('ers_report.property_page.carbon_title') }}
                        </h2>
                        <span class="page_area-savings__section-score">
                            {{ parseInt(getSelectedScenario.co2_savings) }}
                            kg CO₂
                        </span>
                        <span class="page_area-savings__section-unit">
                            {{ $t('ers_report.per_year') }}
                        </span>
                    </div>
                    <p class="page_area-savings__section-text">
                        {{ $t('ers_report.property_page.carbon_text') }}
                    </p>
                </section>

                <section class="page_area-savings__section">
                    <div>
                        <h2 class="page_area-savings__section-title">
                            {{ $t('ers_report.property_page.bill_title') }}
                        </h2>
                        <span class="page_area-savings__section-score">
                            {{
                                int_value_formatter(
                                    getRenovationsSummary.yearlySavings / 12
                                )
                            }}
                        </span>
                        <span class="page_area-savings__section-unit">
                            {{ $t('ers_report.per_month') }}
                        </span>
                    </div>
                    <p class="page_area-savings__section-text">
                        {{ $t('ers_report.property_page.bill_text') }}
                    </p>
                </section>

                <p class="result-page__footnote">
                    {{ $t('energy.result.footnote') }}
                </p>
            </div>

            <div
                v-for="(renovationPair, i) in getScenariosAvailable
                    ? getCompiledRenovationPairs
                    : []"
                :key="`page_${renovationPair[0].name}`"
                class="page_area"
            >
                <div class="page-header">
                    <div>
                        <img src="@/assets/img/re-logo-tight.svg" class="re-logo" />
                        <img v-if="bankLogo" :src="bankLogo()" class="bank-logo" />
                    </div>
                    <div class="address">
                        <img
                            src="@/assets/icons/map-marker.svg"
                            alt=""
                            class="address__icon"
                        />
                        <div class="address__text">
                            <div class="text__street">
                                {{ formatAddress.firstLine }}
                            </div>
                            <div class="text__city">
                                {{ formatAddress.secondLine }}
                            </div>
                        </div>
                    </div>
                </div>
                <renovation-page
                    v-for="(renovation, j) in renovationPair"
                    :key="`renovation_${renovation.name}`"
                    :number="2 * i + 1 + j"
                    :name="renovation.name"
                    :icon="getRenovationsAsset(`${renovation.index}-`)"
                    :price="renovation.price"
                    :region="getRegionISO"
                    :surface="renovation.area"
                    :subsidy-amt="null"
                    :extra-features="renovation.extra_features"
                />
                <p class="result-page__footnote">
                    {{ $t('energy.result.footnote') }}
                </p>
            </div>
            <div class="page_area page_area--input-summary">
                <div class="page-header">
                    <div>
                        <img src="@/assets/img/re-logo-tight.svg" class="re-logo" />
                        <img v-if="bankLogo" :src="bankLogo()" class="bank-logo" />
                    </div>
                    <div class="address">
                        <img
                            src="@/assets/icons/map-marker.svg"
                            alt=""
                            class="address__icon"
                        />
                        <div class="address__text">
                            <div class="text__street">
                                {{ formatAddress.firstLine }}
                            </div>
                            <div class="text__city">
                                {{ formatAddress.secondLine }}
                            </div>
                        </div>
                    </div>
                </div>

                <h1 class="result-page__title">
                    {{ $t('ers_report.summary_input_title') }}
                </h1>

                <div class="page-sections">
                    <div class="page-section">
                        <h1 class="page-section-title">{{ $t('steps.insulation') }}</h1>
                        <div class="summary-section">
                            <div
                                v-for="card in getEnergyCards"
                                :key="card.title"
                                class="summary-card"
                            >
                                <div class="summary-card-text">
                                    <h1>
                                        {{ card.title }}
                                    </h1>
                                    <p>
                                        {{ card.answer() }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="page-section">
                        <h1 class="page-section-title">{{ $t('steps.consumption') }}</h1>
                        <div class="summary-section">
                            <div
                                v-for="card in getConsumptionsCards"
                                :key="card.title"
                                class="summary-card"
                            >
                                <div class="summary-card-text">
                                    <h1>
                                        {{ card.title }}
                                    </h1>
                                    <p>
                                        {{ card.answer() }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <p class="result-page__footnote">
                    {{ $t('energy.result.footnote') }}
                </p>
            </div>
            <div class="page_area">
                <div class="page-header">
                    <div>
                        <img src="@/assets/img/re-logo-tight.svg" class="re-logo" />
                        <img v-if="bankLogo" :src="bankLogo()" class="bank-logo" />
                    </div>
                    <div class="address">
                        <img
                            src="@/assets/icons/map-marker.svg"
                            alt=""
                            class="address__icon"
                        />
                        <div class="address__text">
                            <div class="text__street">
                                {{ formatAddress.firstLine }}
                            </div>
                            <div class="text__city">
                                {{ formatAddress.secondLine }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-section">
                    <h1 class="result-page__title">
                        {{ $t('faq.how-total-investment-cost-calculated_question') }}
                    </h1>
                    <vue-markdown
                        :source="$t('faq.how-total-investment-cost-calculated_answer')"
                        class="page-section__markdown"
                    />
                </div>

                <p class="result-page__footnote">
                    {{ $t('energy.result.footnote') }}
                </p>
            </div>
        </template>

        <b-loading
            v-else
            v-model="isLoading"
            :is-full-page="true"
            :can-cancel="false"
        ></b-loading>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'

import ReMap from '@/components/common/Map.vue'
import Renderer from '@/apps/ERS/components/3DComponents/Renderer3D.vue'
import EpcScore from './EpcScore.vue'
import Consumption from './Consumption.vue'
import PackageSelector from './PackageSelector.vue'
import RenovationGrid from './RenovationGrid.vue'
import Summary from './Summary.vue'
import PropertyValue from './PropertyValue.vue'
import Savings from './Savings.vue'
import RenovationPage from './RenovationPage.vue'

import VueMarkdown from 'vue-markdown-render'

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'Report',
    components: {
        EpcScore,
        Consumption,
        PackageSelector,
        RenovationGrid,
        Summary,
        VueMarkdown,
        Savings,
        RenovationPage,
        ReMap,
        Renderer,
        PropertyValue,
    },
    data() {
        return {
            region: null,
            epcScore: null,
            currentScenario: null,
            epcLabel: null,
            consumptions: {},
            isLoading: true,
            isSummaryModalOpen: false,
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getScenarios',
            'getScenariosNames',
            'getSimulationStatus',
            'getAddress',
            'getFeatures',
            'getRegion',
            'getRegionISO',
            'getCurrentScenario',
            'getCurrentEpcLabel',
            'getEpcPercents',
            'getSelectedPackage',
            'getSelectedScenario',
            'getSelectedRenovationList',
            'getMapScene3d',
            'getMapScene2d',
            'getHeatingFuel',
            'getWallInsulation',
            'getRoofInsulation',
            'getFloorInsulation',
            'getRenovationsSummary',
            'getPropertyValue',
            'getScenariosAvailable',
        ]),
        formatAddress() {
            return utils.formatAddress(this.getAddress)
        },
        getCompiledRenovationPairs() {
            const pairs = []
            for (const [i, renovation] of this.getSelectedRenovationList.entries()) {
                if (i % 2 === 0) {
                    pairs.push([renovation])
                } else {
                    pairs[pairs.length - 1].push(renovation)
                }
            }
            return pairs
        },
        mailto_link() {
            const url = `${window.location.protocol}//${window.location.host}${this.$config.FRONTEND_URL}${this.$route.fullPath}`
            return `mailto:?subject=${encodeURIComponent(
                this.$t('email.sharing_subject')
            )}&body=${encodeURIComponent(this.$t('email.sharing_body', { url: url }))}`
        },
        getIsFuelElectricity() {
            return (
                this.getHeatingFuel === 'electricity' ||
                this.getFeatures.heating_attributes[0].heating_type === 'district_heating'
            )
        },
        getEnergyCards() {
            const buildingDimensionCard = () => {
                const {
                    f_neighbour_type,
                    f_top_floor,
                    f_percent_of_roof_flat,
                } = this.getFeatures
                const houseType =
                    f_neighbour_type === 'isolated'
                        ? this.$t('energy.building_dimensions.facade_type_2')
                        : f_neighbour_type === 'row_end'
                        ? this.$t('energy.building_dimensions.facade_type_1')
                        : this.$t('energy.building_dimensions.facade_type_0')
                if (+this.getFeatures.effective_level < 2) {
                    return {
                        title: this.$t('steps.building_dimensions'),
                        answer: () =>
                            `${this.$t(
                                'energy.building_dimensions.floors.label'
                            )} ${f_top_floor}, ${f_percent_of_roof_flat * 100}% ${this.$t(
                                'building_dimensions_page.is_flat'
                            )}, ${houseType}`,
                        routeName: 'ers.building-dimensions',
                    }
                } else if (+this.getFeatures.effective_level < 3) {
                    return {
                        title: this.$t('steps.building_dimensions'),
                        answer: () =>
                            `${this.$t(
                                'energy.building_dimensions.floors.label'
                            )} ${f_top_floor}, ${f_percent_of_roof_flat * 100}% ${this.$t(
                                'building_dimensions_page.is_flat'
                            )}`,
                        routeName: 'ers.building-dimensions',
                    }
                } else {
                    return null
                }
            }
            const answers = [
                {
                    title: this.$t('steps.attic'),
                    answer: () => {
                        if (!this.getFeatures.has_attic) {
                            return this.$t('common.no')
                        } else if (this.getFeatures.is_attic_heated) {
                            return this.$t('energy.summary.heated')
                        } else {
                            return this.$t('energy.summary.not_heated')
                        }
                    },
                    routeName: 'ers.attic',
                },
                {
                    title: this.$t('steps.basement'),
                    answer: () =>
                        this.getFeatures.has_basement
                            ? this.$t('common.yes')
                            : this.$t('common.no'),
                    routeName: 'ers.basement',
                },
                buildingDimensionCard(),
                {
                    title: this.$t('steps.living_area'),
                    answer: () => this.getFeatures.f_living_area + this.$t('unit.m2'),
                    routeName: 'ers.living-area',
                },
                {
                    title: this.$t('energy.summary.epc'),
                    answer: () => {
                        const {
                            has_epc_certificate,
                            is_epc_label,
                            is_epc_number,
                            f_epc_label,
                            f_epc_numeric,
                        } = this.getFeatures
                        if (!has_epc_certificate) {
                            return this.$t('common.no')
                        } else {
                            if (is_epc_number) {
                                return f_epc_numeric
                            } else {
                                return f_epc_label
                            }
                        }
                    },
                    routeName: !this.getFeatures.has_epc_certificate
                        ? 'ers.epc'
                        : 'ers.epc-score',
                },
                {
                    title: this.$t('steps.f_construction_year'),
                    answer: () => this.getFeatures.f_construction_year,
                    routeName: 'ers.construction-year',
                },
                {
                    title: this.$t('steps.walls'),
                    answer: () => {
                        const answer = this.getWallInsulation[0]
                        if (answer.has_insulation === 'idk')
                            return this.$t('common.i_dont_know')
                        else if (answer.has_insulation) {
                            return `${this.$t('energy.summary.insulation_year')}: ${
                                answer.renovation_year
                            }`
                        } else {
                            return this.$t('common.no')
                        }
                    },
                    routeName: 'ers.wall-insulation',
                },
                {
                    title: this.$t('steps.roof'),
                    answer: () => {
                        const answer = this.getRoofInsulation[0]
                        if (answer.has_insulation === 'idk')
                            return this.$t('common.i_dont_know')
                        else if (answer.has_insulation) {
                            return `${this.$t('energy.summary.insulation_year')}: ${
                                answer.renovation_year
                            }`
                        } else {
                            return this.$t('common.no')
                        }
                    },
                    routeName: 'ers.roof-insulation',
                },
                {
                    title: this.$t('steps.floor'),
                    answer: () => {
                        const answer = this.getFloorInsulation[0]
                        if (answer.has_insulation === 'idk')
                            return this.$t('common.i_dont_know')
                        else if (answer.has_insulation) {
                            return `${this.$t('energy.summary.insulation_year')}: ${
                                answer.renovation_year
                            }`
                        } else {
                            return this.$t('common.no')
                        }
                    },
                    routeName: 'ers.floor-insulation',
                },
                {
                    title: this.$t('steps.windows'),
                    answer: () => this.$t(`value.${this.getFeatures.window_type}`),
                    routeName: 'ers.window-glazing',
                },
                {
                    title: this.$t('steps.heating'),
                    answer: () => {
                        const isSubType =
                            this.getFeatures.heating_attributes[0].subtype !== null
                        const fuel = this.getHeatingFuel
                            ? this.$t(`value.${this.getHeatingFuel}`)
                            : this.$t(`value.${'electricity'}`)
                        const type = this.$t(
                            `value.${
                                this.getFeatures.heating_attributes[0].heating_type
                            }${
                                isSubType
                                    ? ':' + this.getFeatures.heating_attributes[0].subtype
                                    : ''
                            }`
                        )
                        const installationYear = this.getFeatures.heating_attributes[0]
                            .installation_year

                        return `${fuel} ${type ? '- ' + type : ''}: ${installationYear}`
                    },
                    routeName: 'ers.heating',
                },
                {
                    title: this.$t('steps.solar_panels'),
                    answer: () =>
                        this.getFeatures.photovoltaic_available
                            ? this.$t('common.yes')
                            : this.$t('common.no'),
                    routeName: 'ers.solar-panels',
                },
            ]
            return answers.filter((card) => card !== null)
        },
        getConsumptionsCards() {
            const {
                known_consumptions,
                estimated_consumptions,
                energy_consumption_known,
            } = this.getFeatures
            if (energy_consumption_known === null) return

            const elecCard = () => {
                let answer
                if (!estimated_consumptions) return
                if (
                    energy_consumption_known === false &&
                    estimated_consumptions.length > 0
                ) {
                    answer = estimated_consumptions.find(
                        (cons) => cons.consumption_fuel === 'electricity'
                    ).estimate
                    answer = this.$t(`energy.summary.${answer}`)
                } else if (known_consumptions.length > 0) {
                    const { amount, unit } = known_consumptions.find(
                        (cons) => cons.consumption_fuel === 'electricity'
                    )
                    answer = `${amount} ${
                        unit === 'kwh' ? this.$t('unit.kWh') : this.$t('unit.' + unit)
                    }`
                }
                return {
                    title: this.$t('energy.summary.electricity_consumption'),
                    answer: () => answer,
                    routeName: 'ers.consumers',
                }
            }

            const heatingCard = () => {
                if (this.getIsFuelElectricity) {
                    return null
                }
                let answer
                if (energy_consumption_known === false) {
                    if (estimated_consumptions.length === 0) {
                        answer = null
                    } else {
                        answer = estimated_consumptions.find(
                            (cons) => cons.consumption_fuel === this.getHeatingFuel
                        ).estimate
                    }
                } else {
                    const { amount, unit } = known_consumptions.find(
                        (cons) => cons.consumption_fuel === this.getHeatingFuel
                    )
                    answer = `${amount} ${
                        unit === 'kwh' ? this.$t('unit.kWh') : this.$t('unit.' + unit)
                    }`
                }
                return {
                    title: this.$t('energy.summary.heating_consumption'),
                    answer: () => answer,
                    routeName: 'ers.consumers',
                }
            }

            const answers = [
                {
                    title: this.$t('energy.summary.energy_consumption_known'),
                    answer: () => {
                        return `${
                            this.getFeatures.energy_consumption_known
                                ? this.$t('common.yes')
                                : this.$t('common.no')
                        }`
                    },
                    routeName: 'ers.energy-consumption',
                },
                {
                    title: this.$t('energy.summary.residents'),
                    answer: () => {
                        return `${this.getFeatures.n_residents}`
                    },
                    routeName: 'ers.consumers',
                },
                heatingCard(),
                elecCard(),
                {
                    title: this.$t('energy.summary.big_consumer'),
                    answer: () =>
                        this.getFeatures.has_big_energy_consumers
                            ? this.$t('common.yes')
                            : this.$t('common.no'),
                    routeName: 'ers.big-consumers',
                },
            ]
            return answers.filter((card) => card !== null)
        },
    },
    mounted() {
        // So that this only happens while this component is mounted
        document.querySelector('html').classList.add('font-8-px')

        this.load_simulation({
            route: this.$route,
        })
    },
    unmounted() {
        document.querySelector('html').classList.remove('font-8-px')
    },
    methods: {
        ...mapActions('ersStore', ['load_simulation', 'fetch_map_scene3d']),
        ...mapMutations('ersStore', ['SET_SELECTED_PACKAGE']),
        importAll(r) {
            return r.keys().map(r)
        },
        icons() {
            return this.importAll(
                require.context(`./renovations`, false, /\.(png|jpe?g|svg)$/)
            )
        },
        getRenovationsAsset(fileName) {
            return this.icons().filter((asset) => asset.indexOf(fileName) > -1)[0]
        },
        bankLogo() {
            let envi = this.$config.ENVIRONMENT
            if (envi.startsWith('avm')) return false

            let customers = this.importAll(
                require.context('./customers', false, /\.(png|jpe?g|svg)$/)
            )
            let bank = envi.split('-')[0]

            return customers.filter((asset) => asset.indexOf(bank) > -1)[0]
        },
        int_value_formatter(x) {
            return utils.int_value_formatter(x, this.$route.params.lang)
        },
    },
}
</script>

<style lang="scss">
@media print {
    html.font-8-px {
        font-size: 8px !important;
    }

    .cover_page .leaflet-control-zoom {
        display: none !important;
    }
    .summary-section {
        border-radius: 0.5rem;
        border: 0.5px solid #d6e2e9;
    }
    .summary-card:not(:last-child) {
        border-bottom: 0.5px solid #d6e2e9;
    }
    .summary-card-text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // padding: 0.5rem;
        h1 {
            border-right: 0.5px solid #d6e2e9;
            color: rgba(0, 24, 55, 0.5);
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        h1,
        p {
            padding: 0.5rem;
        }
        p {
            color: #001837;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}

.page_area-savings__info-bubble {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    b {
        color: #004d08;
        font-family: Satoshi;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        margin-inline-start: 0.5rem;
        line-height: normal;
    }
}
</style>
<style lang="scss" scoped>
.ers-map-renderer {
    min-height: 20rem;
}
.result-page {
    min-width: 100%;
    margin-block-end: auto;
    background: #f8f9fb;
    font-size: 8px !important;

    &__footnote {
        margin-top: 33px;
        color: #798da6;
        font-size: 1rem;
        font-weight: 400;
    }

    .page-sections {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-block-start: 1.5rem;
    }

    .page-section {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        &-title {
            color: #001837;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .page_area {
        width: 794px;
        height: 1122px;
        margin: 1cm auto;
        border: 1px #d3d3d3 solid;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        background: white;

        &:not(.cover_page) {
            background: url('../../../../../assets/img/ers/report-header.png') no-repeat,
                white;
            background-size: contain;
            background-position: right top;
            padding: 1.5cm;

            & > div:nth-child(2) {
                flex-grow: 2;
            }
        }

        .page-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1cm;

            .bank-logo {
                height: 1.5cm;
            }

            .re-logo {
                height: 1.2cm;

                & + .bank-logo {
                    height: 1.2cm;
                    position: relative;
                    bottom: 0.35cm;
                    margin-left: 1.5rem;
                }
            }

            .address {
                border-radius: 2px;
                border: 0.383px solid #d6e2e9;
                background: #fff;
                display: inline-flex;
                padding: 0.3cm;
                align-items: center;
                gap: 0.3cm;
            }

            .address__text {
                flex-grow: 1;
                color: #001837;
                font-size: 0.8rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .address__icon {
                height: 2.2rem;
            }
        }
    }

    .cover_page {
        .page-header {
            padding: 1.5cm;
            padding-bottom: 0;
        }

        &-title {
            color: #001837;
            font-size: 5.5rem;
            font-weight: 700;
            line-height: 6.5rem;
            margin: 0 1.5cm 5rem;

            b {
                color: var(--color-primary);
                font-weight: 700;
            }
        }

        &-picture {
            flex-grow: 2;
            background: #d1e5fb;
            border-radius: 2cm 2cm 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 1.5cm;

            &-content {
                width: 90%;
                height: 50%;
                border-radius: 0.5rem;
                overflow: hidden;
                border: 1px solid #d6e2e9;
                margin-top: 3cm;

                &--3d {
                    border: none;
                }
            }

            &-disclaimer {
                border-radius: 0.5rem;
                border: 1px solid rgba(0, 24, 55, 0.3);
                background: rgba(0, 24, 55, 0.1);
                padding: 1.25rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 1rem;
                align-self: flex-end;
                color: #001837;

                h1 {
                    display: flex;
                    align-items: center;
                    font-size: 1.5rem;
                    font-weight: 700;
                    gap: 0.5rem;

                    img {
                        height: 1.5rem;
                    }
                }

                p {
                    font-size: 1rem;
                    font-weight: 400;

                    b {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .result-page__title {
        font-size: 8px;
        color: #001837;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .result-page__subtitle {
        font-size: 8px;
        color: #798da6;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

::v-deep .page-section__markdown {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h1,
    h3 {
        margin-top: 1.5rem;
        color: #001837;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    h2 {
        color: #001837;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
        margin-top: 1.5rem;
    }
    p {
        margin-top: 1.5rem;
    }
    ul {
        margin-top: 4px;
    }
    p,
    li {
        color: #001837 !important;
        font-family: Satoshi;
        font-size: 1rem !important;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
    }
    li {
        padding-left: 4px;
        &:before {
            content: '• ';
        }
    }
}

.result-page__content {
    max-width: 70vw;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media only screen and (max-width: 1025px) {
    .result-page__content {
        max-width: 97vw;
    }
}

.results__epc {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 24px;
}

@media (max-width: 1200px) {
    .results__epc {
        grid-template-columns: 1fr;
    }
}

.results__renovations {
    display: grid;
    gap: 30px;
    grid-template-columns: 3fr 1.4fr;
    margin-top: 3rem;
}

@media only screen and (max-width: 1400px) {
    .results__renovations {
        grid-template-columns: 1fr;
    }
}

.renovations__summary {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-inline: auto;
    max-width: fit-content;
}

@media screen {
    .results__epc {
        // grid-template-columns: 1fr;
        justify-items: stretch;

        .address {
            display: none;
        }
    }
}
@media print {
    .result-page {
        background: transparent;

        &__footnote {
            margin-top: auto;
            color: #798da6;
            font-size: 1rem;
            font-weight: 400;
            padding-top: 3rem;
        }

        .page_area {
            width: 794px;
            height: 1122px;
            margin: 0;
            border: 0;
            box-shadow: none;
            page-break-after: always;
            &-savings {
                &__title {
                    color: #001837;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding-block-end: 1rem;
                    &--small {
                        padding-block-end: 1.5rem;
                        color: #001837;
                        font-size: 1.6rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
                &__subtitle {
                    color: #798da6;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                    margin-block-end: 1.5rem;
                }
                &__info-bubble {
                    display: flex;
                    padding: 0.5rem 1rem;
                    align-items: center;
                    gap: 0.5rem;
                    align-self: stretch;
                    border-radius: 0.5rem;
                    border: 0.5px solid #0fc222;
                    background: rgba(15, 194, 34, 0.07);
                    color: #004d08;
                    font-family: Satoshi;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-block: 1.5rem;
                }

                &__section {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    align-self: stretch;
                    border-radius: 1rem;
                    border: 0.5px solid #d6e2e9;
                    background: #fff;
                    overflow: hidden;
                    margin-block-start: 1.5rem;
                    div {
                        display: flex;
                        padding: 0.75rem;
                        // padding-inline-end: 8rem;
                        flex-direction: column;
                        min-width: 130px;
                        max-width: 130px;
                        gap: 0.5rem;
                        align-self: stretch;
                        background: rgba(15, 194, 34, 0.07);
                    }
                    &-title {
                        color: rgba(39, 55, 40, 0.5);
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                    &-score {
                        color: #001837;
                        font-size: 2rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                    &-unit {
                        color: #001837;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-block-start: -1rem;
                    }
                    &-text {
                        color: #798da6;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.5rem; /* 150% */
                        display: flex;
                        padding: 1.5rem;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 0.625rem;
                        flex: 1 0 0;
                        align-self: stretch;
                    }
                }
            }
        }
    }
    // .address {
    //     display: none;
    // }

    // .results__renovations {
    //     grid-template-columns: 1fr;
    // }

    ._content__results {
        .results__epc {
            grid-template-columns: auto max-content;
            gap: 0.3cm;
            margin-bottom: 0.5cm;
            width: 100%;
        }
    }
    .result-page__content {
        width: 100%;
        max-width: none;
        margin-bottom: 0.5cm;
        gap: 0.2cm;
    }
}
</style>
