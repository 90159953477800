<i18n lang="json">
{
    "en-BE": {
        "customer": "Customer",
        "actions": "Actions",
        "address": "Address",
        "type": "Type",
        "automatic_valuation": "Automatic valuation",
        "cancel": "Cancel",
        "customer_ref": "Customer ref",
        "edit": "Edit",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Number of facades",
            "f_construction_year": "Construction year",
            "f_living_area": "Living area",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "More information",
        "last_modified": "Last modified",
        "created_at": "Created",
        "missing_features": "The following features are missing, preventing an automatic valuation:",
        "ok": "OK",
        "per_page": "per page",
        "request_ref": "Request ref",
        "search": "Search",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "Awaiting onsite",
            "under-dvm-valuation": "Submitted",
            "draft": "Draft",
            "submitted": "Submitted",
            "exotic": "Awaiting onsite",
            "needs-review": "More information needed",
            "cancelled": "Cancelled",
            "valued": "Valued",
            "failed": "Failed"
        },
        "badges": {
            "resubmitted": "Resubmitted",
            "needs_on_site": "OVM Needed",
            "needs_borrower_email": "Borrower email needed",
            "needs_appointment_confirmation": "Appointment confirmation needed",
            "needs_borrower_action": "Borrower action needed",
            "appointment_confirmed": "Appointment confirmed"
        },
        "tooltip_text": "Follow here the status of your valuation requests",
        "review_tooltip": "This request has been analyzed by a valuation specialist and some answers need to be reviewed",
        "view_request": "View request",
        "dvm": "DVM",
        "ovm": "OVM",
        "appointment_summary": "Appointment",
        "lang": "Language",
        "valuer": "Valuer",
        "unassigned": "Unassigned",
        "new_request": "New Request",
        "create_report": "Generate report",
        "refresh_report": "Generate new report",
        "download_report": "Save report",
        "report_loading": "Report loading",
        "owner": "Owner",
        "take_ownership": "Take ownership",
        "sub_modal": {
            "content": "Do you confirm you want to take ownership of this request?\n The current owner will be notified of the ownership transfer.",
            "actions": {
                "cancel": "Cancel",
                "confirm": "Confirm"
            }
        },
        "valuer_change_modal": {
            "content": "This request is already assigned. Do you confirm you want to change the assigned valuer? This will prevent the previously assigned valuer from working on the request.",
            "actions": {
                "cancel": "Cancel",
                "confirm": "Confirm"
            }
        },
        "duplicates": "Duplicates",
        "duplicates_modal": {
            "title": "Showing duplicates for ",
            "close": "Close",
            "reason": "Reason",
            "reasons": {
                "same place": "Same place",
                "same address": "Same address",
                "same customer_ref": "Same customer reference",
                "close point": "Close point"
            },
            "open_dvm": "Open Valuation Tool for this request",
            "copy_ref": "Copy request's reference"
        },
        "tabs": {
            "my_requests": "My Requests",
            "last_month": "Last month",
            "last_year": "Last year",
            "all_requests": "All Requests",
            "search_request": "Search Request"
        },
        "need_someone_else": "Need to work on someone else's request?",
        "ing": {
            "customer_ref": "LOP ID"
        },
        "valuers_workload": "Valuers Workload"
    },
    "fr-BE": {
        "customer": "Client",
        "actions": "Actions",
        "address": "Adresse",
        "type": "Type",
        "automatic_valuation": "Valorisation automatique",
        "cancel": "Annuler",
        "customer_ref": "Référence interne",
        "edit": "Modifier",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "PEB",
            "f_touching_sides": "Nombre de façades",
            "f_construction_year": "Année de construction",
            "f_living_area": "Surface habitable",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "Plus d'information",
        "last_modified": "Dernière modification",
        "created_at": "Créé",
        "missing_features": "Les données suivantes sont manquantes, ce qui empêche une valorisation automatique:",
        "ok": "OK",
        "per_page": "par page",
        "request_ref": "Référence de demande",
        "search": "Rechercher",
        "status_col": "Statut",
        "status": {
            "awaiting-onsite": "Expertise sur place nécessaire",
            "under-dvm-valuation": "Envoyé",
            "draft": "À compléter",
            "submitted": "Envoyé",
            "exotic": "Expertise sur place nécessaire",
            "needs-review": "Plus d'information nécessaire",
            "cancelled": "Annulé",
            "valued": "Evalué",
            "failed": "Echec"
        },
        "badges": {
            "resubmitted": "Soumis de nouveau",
            "needs_on_site": "Expertise sur site recommandée"
        },
        "tooltip_text": "Suivez ici l'évolution de vos demandes",
        "review_tooltip": "Cette demande a été analysée par un expert immobilier et certaines réponses doivent être révisées",
        "view_request": "Voir la demande",
        "dvm": "DVM",
        "lang": "Language",
        "valuer": "Expert immobilier",
        "unassigned": "Non assignée",
        "new_request": "Nouvelle demande",
        "create_report": "Générer un rapport",
        "refresh_report": "Générer un rapport de nouveau",
        "download_report": "Télécharger un rapport",
        "report_loading": "Chargement du rapport",
        "owner": "Demandeur",
        "take_ownership": "Prendre en charge",
        "sub_modal": {
            "content": "Confirmez-vous prendre en charge cette demande ? Le demandeur actuel sera informé du changement d'utilisateur.",
            "actions": {
                "cancel": "Annuler",
                "confirm": "Confirmer"
            }
        },
        "valuer_change_modal": {
            "content": "Cette demande est déjà attribuée. Confirmez-vous que vous voulez changer l'expert assigné? Cela empêchera l'expert précédemment assigné de travailler sur la demande.",
            "actions": {
                "cancel": "Annuler",
                "confirm": "Confirmer"
            }
        },
        "duplicates": "Doublons",
        "duplicates_modal": {
            "title": "Affichage des doublons pour ",
            "close": "Fermer",
            "reason": "Raison",
            "reasons": {
                "same place": "Same place",
                "same address": "Même adresse",
                "same customer_ref": "Même référence interne",
                "close point": "Point proche"
            },
            "open_dvm": "Ouvrir la DVM pour cette requête",
            "copy_ref": "Copier la référence"
        },
        "tabs": {
            "my_requests": "Mes demandes",
            "last_month": "Dernier mois",
            "last_year": "Dernière année",
            "all_requests": "Toutes les demandes",
            "search_request": "Rechercher une demande"
        },
        "need_someone_else": "Besoin de travailler sur une demande d'un collègue?",
        "ing": {
            "customer_ref": "LOP ID"
        },
        "valuers_workload": "Charge de travail des Valuation Specialists"
    },
    "nl-BE": {
        "customer": "Klant",
        "actions": "Acties",
        "address": "Adres",
        "type": "Type",
        "automatic_valuation": "Automatische waardering",
        "cancel": "Annuleren",
        "customer_ref": "Interne referentie",
        "edit": "Bewerken",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Aantal gevels",
            "f_construction_year": "Bouwjaar",
            "f_living_area": "Bewoonbare oppervlakte",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "Meer informatie",
        "last_modified": "Laatst gewijzigd",
        "created_at": "Gecreëerd",
        "missing_features": "De volgende functies ontbreken, waardoor automatische waardering niet mogelijk is:",
        "my_requests": "Mijn aanvragen",
        "all_requests": "Alle aanvragen",
        "ok": "OK",
        "per_page": "per pagina",
        "request_ref": "Aanvraag referentie",
        "search": "Zoeken",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "On-site expertise vereist",
            "under-dvm-valuation": "Ingediend",
            "draft": "Ontwerp",
            "submitted": "Ingediend",
            "exotic": "On-site expertise vereist",
            "needs-review": "Meer informatie nodig",
            "cancelled": "Geannuleerd",
            "valued": "Gewaardeerd",
            "failed": "Gefaald"
        },
        "badges": {
            "resubmitted": "Opnieuw ingediend",
            "needs_on_site": "Expertise ter plaatse aanbevolen"
        },
        "tooltip_text": "Volg hier de status van uw aanvraagen",
        "review_tooltip": "Deze aanvraag is door een vastgoeddeskundige geanalyseerd en sommige antwoorden moeten worden herzien",
        "view_request": "Aanvraag bekijken",
        "dvm": "DVM",
        "lang": "Taal",
        "valuer": "Vastgoeddeskundige",
        "unassigned": "Niet toegewezen",
        "new_request": "Nieuwe aanvraag",
        "create_report": "Genereer een verslag",
        "refresh_report": "Genereer een nieuw verslag",
        "download_report": "Verslag opslaan",
        "report_loading": "Verslag is aan het laden",
        "owner": "Aanvrager",
        "take_ownership": "Aanvraag overnemen",
        "sub_modal": {
            "content": "Graag nogmaals bevestiging om deze aanvraag over te nemen. De huidige aanvrager zal hiervan een bericht krijgen.",
            "actions": {
                "cancel": "Annuleren",
                "confirm": "Bevestigen"
            }
        },
        "valuer_change_modal": {
            "content": "Dit verzoek is al toegewezen. Bevestigt u dat u de toegewezen expert wilt veranderen? Hierdoor kan de eerder toegewezen expert niet meer aan de aanvraag werken.",
            "actions": {
                "cancel": "Annuleren",
                "confirm": "Bevestigen"
            }
        },
        "duplicates": "Duplicaten",
        "duplicates_modal": {
            "title": "Duplicaten tonen voor ",
            "close": "Sluiten",
            "reason": "Grond",
            "reasons": {
                "same place": "Same place",
                "same address": "Zelfde adres",
                "same customer_ref": "Zelfde interne referentie",
                "close point": "Dichtbij punt"
            },
            "open_dvm": "Open DVM voor deze aanvraag",
            "copy_ref": "Kopieer de aanvraag referentie"
        },
        "tabs": {
            "my_requests": "Mijn aanvragen",
            "last_month": "Laatste maand",
            "last_year": "Laatste jaar",
            "all_requests": "Alle aanvragen",
            "search_request": "Aanvraag zoeken"
        },
        "need_someone_else": "Moet werken aan iemand anders zijn aanvraag?",
        "ing": {
            "customer_ref": "LOP ID"
        },
        "valuers_workload": "Werkdruk Valuations Specialists"
    }
}
</i18n>

<template>
    <div :class="isMobile ? 'dashboard-page' : 'section container is-fluid'">
        <b-collapse
            v-if="hasRole('dispatcher', 'dvm')"
            v-model="workloadOpen"
            class="card"
            animation="slide"
        >
            <template #trigger="props">
                <div class="card-header" role="button" :aria-expanded="props.open">
                    <p
                        class="is-size-4 has-text-weight-bold has-text-black card-header-title"
                    >
                        {{ $t('valuers_workload') }}
                    </p>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <valuer-workload
                    :valuers="valuers.dvm"
                    :requests="requests.filter((row) => row.valuation_type === 'dvm')"
                />
            </div>
        </b-collapse>
        <title-with-tooltip :no-margin="isMobile" :label="t('tooltip_text')">
            <template #title>
                <span class="is-capitalized">Dashboard</span>
            </template>
        </title-with-tooltip>
        <new-request-button v-if="!isMobile">
            <b-button
                :label="$t('dashboard.new_request.title')"
                type="is-primary"
                icon-left="plus"
                class="mt-1"
                size="is-medium"
            />
        </new-request-button>
        <a
            v-if="hasRole('dispatcher', 'dvm') && reportLink && !reportLoading"
            class="is-success button is-medium"
            :class="{
                'ml-1 mt-1': !isMobile,
            }"
            :href="reportLink"
            :download="`${reportFileName}.csv`"
        >
            <i class="mdi mdi-download mr-2"></i>
            {{ t('download_report') }}
        </a>
        <b-button
            v-if="hasRole('dispatcher', 'dvm')"
            :class="{
                'ml-1 mt-1': !isMobile,
                'button-loading': reportLoading,
            }"
            size="is-medium"
            type="is-primary"
            :icon-left="reportLoading || reportLink ? 'autorenew' : 'download'"
            :disabled="reportLoading"
            @click="generateReport"
        >
            {{
                t(
                    reportLoading
                        ? 'report_loading'
                        : reportLink
                        ? 'refresh_report'
                        : 'create_report'
                )
            }}
        </b-button>

        <div :class="isMobile ? 'dashboard-container' : 'columns is-multiline mt-4'">
            <div
                v-if="showTabs"
                class="is-flex is-w100"
                :class="{
                    'mx-2 mb-5': !isMobile,
                }"
            >
                <b-field>
                    <b-radio-button
                        v-model="requestFilter"
                        native-value="my_requests"
                        type="is-primary"
                        :size="isMobile ? 'is-medium' : 'is-small'"
                    >
                        <span>{{ $t(`tabs.my_requests`) }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-if="showAllRequestsTab"
                        v-model="requestFilter"
                        native-value="all_requests"
                        type="is-primary"
                        :size="isMobile ? 'is-medium' : 'is-small'"
                    >
                        <span>{{ $t(`tabs.all_requests`) }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-if="!showAllRequestsTab"
                        v-model="requestFilter"
                        native-value="search_request"
                        type="is-primary"
                        :size="isMobile ? 'is-medium' : 'is-small'"
                    >
                        <span>{{ $t(`tabs.search_request`) }}</span>
                    </b-radio-button>
                </b-field>
            </div>
            <div
                v-if="requestFilter === 'search_request'"
                class="is-flex is-justify-content-center is-align-items-center is-w100"
                :class="{
                    'm-2 mb-5': !isMobile,
                }"
            >
                <h3>{{ t('need_someone_else') }}</h3>
                <form
                    class="is-flex is-justify-content-center is-align-items-center"
                    @submit="searchByRefSubmit"
                >
                    <b-input
                        v-model="requestRefSearch"
                        class="mr-1 ml-2"
                        :placeholder="t('customer_ref')"
                    />
                    <b-button
                        type="is-primary"
                        :disabled="isSearchRefButtonDisabled"
                        @click="searchByRef"
                    >
                        {{ t('search') }}
                    </b-button>
                </form>
            </div>
            <div
                v-else
                class="is-flex is-w100"
                :class="{
                    'mx-2 mb-5': !isMobile,
                }"
            >
                <b-field class="filter-selector">
                    <b-radio-button
                        v-model="timeFilter"
                        native-value="last_year"
                        type="is-primary"
                        :size="isMobile ? 'is-medium' : 'is-small'"
                    >
                        <span>{{ $t(`tabs.last_year`) }}</span>
                    </b-radio-button>
                    <b-radio-button
                        v-model="timeFilter"
                        native-value="last_month"
                        type="is-primary"
                        :size="isMobile ? 'is-medium' : 'is-small'"
                    >
                        <span>{{ $t(`tabs.last_month`) }}</span>
                    </b-radio-button>
                </b-field>
            </div>
            <!-- input section -->
            <div
                class="is-flex is-w100 is-align-items-center"
                :class="{
                    'px-2': !isMobile,
                    'is-justify-content-space-between': accessibleModules.length > 1,
                    'is-justify-content-flex-end': accessibleModules.length <= 1,
                }"
            >
                <div v-if="accessibleModules.length > 1" class="request_type_filter">
                    <div v-for="m in accessibleModules" :key="`appToggle_${m}`">
                        <b-checkbox v-model="shownTypes" :native-value="m">
                            <svg-icon :icon="m" scale="1" />
                            {{ $t(`value.short.${m}`) }}
                        </b-checkbox>
                    </div>
                </div>
                <!-- per-page select -->
                <b-field v-if="!isMobile" position="is-right">
                    <b-select v-model="perPage" size="is-small">
                        <option v-for="item in perPageList" :key="item" :value="item">
                            {{ item }} {{ t('per_page') }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <!-- input section -->
            <div class="is-12" :class="{ column: !isMobile }">
                <b-table
                    v-if="!isMobile"
                    :data="tableData"
                    hoverable
                    mobile-cards
                    paginated
                    scrollable
                    pagination-size="is-small"
                    default-sort="modified_at"
                    default-sort-direction="desc"
                    :loading="
                        requestFilter === 'my_requests'
                            ? userRequestsIsLoading
                            : requestsIsLoading
                    "
                    :per-page="perPage"
                    :current-page.sync="currentPage"
                >
                    <template v-for="column in columns">
                        <b-table-column
                            :key="column.valuation_request_ref"
                            v-bind="column"
                        >
                            <template
                                v-if="column.searchable && !column.date"
                                #searchable="props"
                            >
                                <b-input
                                    v-model="props.filters[props.column.field]"
                                    :placeholder="t('search')"
                                    icon="magnify"
                                    size="is-small"
                                />
                            </template>
                            <template
                                v-else-if="column.searchable && column.date"
                                #searchable="props"
                            >
                                <span class="is-flex">
                                    <b-datepicker
                                        v-model="date_filters[props.column.field]"
                                        :placeholder="t('search')"
                                        icon="magnify"
                                        size="is-small"
                                        class="is-flex-grow-1"
                                    />
                                    <button
                                        v-if="date_filters[props.column.field]"
                                        class="clear-button"
                                        @click="clearDate(props.column.field)"
                                    >
                                        <b-icon icon="close-circle" size="is-small" />
                                    </button>
                                </span>
                            </template>
                            <template #default="props">
                                <b-skeleton
                                    v-if="props.row.valuation_request_ref === 'skeleton'"
                                    height="32px"
                                    width="100%"
                                    :rounded="true"
                                    :active="true"
                                />
                                <span
                                    v-else-if="column.date"
                                    :class="dateClass(props.row, column)"
                                >
                                    {{ date_format(props.row[column.field]) }}
                                </span>
                                <div
                                    v-else-if="column.field === 'valuation_request_ref'"
                                    class="is-flex-direction-row is-flex-wrap-nowrap is-align-items-center is-justify-content-center"
                                >
                                    <router-link
                                        v-if="
                                            isValuerOrTypeDispatcher(props.row) &&
                                                [
                                                    'submitted',
                                                    'needs-review',
                                                    'valued',
                                                    'exotic',
                                                ].includes(props.row.status)
                                        "
                                        :to="{
                                            name: 'valuation.request',
                                            params: {
                                                action: 'extra-info',
                                                lang: $route.params.lang,
                                            },
                                            query: {
                                                valuation_request_ref:
                                                    props.row.valuation_request_ref,
                                            },
                                        }"
                                    >
                                        {{ props.row[column.field] }}
                                    </router-link>
                                    <span v-else>
                                        {{ props.row[column.field] }}
                                    </span>
                                    <b-button
                                        v-if="
                                            (isTypeDispatcher(props.row) ||
                                                props.row.valuer?.is_self) &&
                                                props.row?.other_data?.similar_requests &&
                                                props.row.other_data.similar_requests
                                                    .length
                                        "
                                        size="is-small"
                                        type="is-warning"
                                        @click="load_duplicates(props.row)"
                                    >
                                        <svg-icon icon="duplicates" />
                                    </b-button>
                                </div>
                                <a
                                    v-else-if="column.field === 'display_request_ref'"
                                    :href="
                                        `//${
                                            props.row.deployment
                                        }.rock.estate/valuation/${
                                            $route.params.lang
                                        }/request/extra-info?valuation_request_ref=${
                                            props.row[column.field]
                                        }`
                                    "
                                    target="_blank"
                                >
                                    {{ props.row[column.field] }}
                                </a>
                                <div
                                    v-else-if="column.field == 'valuation_type'"
                                    class="val_type_content"
                                >
                                    <svg-icon :icon="props.row[column.field]" scale="1" />
                                    <span>
                                        {{ $t(`value.short.${props.row[column.field]}`) }}
                                    </span>
                                </div>
                                <div
                                    v-else-if="
                                        ['last_submission_lang', 'displayOwner'].includes(
                                            column.field
                                        )
                                    "
                                    class="level top"
                                >
                                    <span class="level-left">
                                        {{ props.row[column.field] }}
                                    </span>
                                </div>
                                <div v-else-if="column.field === 'displayStatus'">
                                    <span
                                        :class="
                                            !!(
                                                props.row.last_dvm_action_at &&
                                                props.row.status === 'draft'
                                            )
                                                ? 'has-text-weight-bold has-text-danger'
                                                : ''
                                        "
                                    >
                                        {{ props.row.displayStatus }}
                                    </span>
                                    <b-tooltip
                                        v-if="
                                            props.row.last_dvm_action_at &&
                                                props.row.status === 'draft'
                                        "
                                        type="is-danger"
                                        :label="$t('review_tooltip')"
                                        multilined
                                        position="is-right"
                                    >
                                        <div
                                            class="is-rounded is-tootip-btn has-text-weight-bold has-text-white has-background-danger"
                                        >
                                            !
                                        </div>
                                    </b-tooltip>
                                    <b-taglist>
                                        <b-tag
                                            v-if="
                                                isResubmitted(props.row) &&
                                                    !isOnlyOvmValuer &&
                                                    isValuerOrTypeDispatcher(props.row)
                                            "
                                            type="is-warning is-light"
                                        >
                                            {{ t('badges.resubmitted') }}
                                        </b-tag>
                                        <b-tag
                                            v-if="
                                                needsBorrowerEmail(props.row) &&
                                                    (hasRole('dispatcher', 'ovm') ||
                                                        props.row.owner.is_self)
                                            "
                                            type="is-warning"
                                        >
                                            {{ t('badges.needs_borrower_email') }}
                                        </b-tag>
                                        <b-tag
                                            v-if="
                                                needsAppointmentConfirmation(props.row) &&
                                                    isValuerOrTypeDispatcher(props.row)
                                            "
                                            type="is-warning"
                                        >
                                            {{
                                                t('badges.needs_appointment_confirmation')
                                            }}
                                        </b-tag>
                                        <b-tag
                                            v-if="
                                                needsBorrowerAction(props.row) &&
                                                    hasRole('dispatcher', 'ovm')
                                            "
                                            type="is-warning"
                                        >
                                            {{ t('badges.needs_borrower_action') }}
                                        </b-tag>
                                        <b-tag
                                            v-if="
                                                isAppointmentConfirmed(props.row) &&
                                                    (hasRole('dispatcher', 'ovm') ||
                                                        props.row.owner.is_self ||
                                                        props.row.valuer?.is_self)
                                            "
                                            type="is-success is-light"
                                        >
                                            {{ t('badges.appointment_confirmed') }}
                                        </b-tag>
                                    </b-taglist>
                                </div>
                                <action-buttons
                                    v-else-if="column.field === 'action'"
                                    :row="props.row"
                                    @update="
                                        updateRow(props.row.valuation_request_ref, $event)
                                    "
                                    @ownership="open_sub_modal(props.row)"
                                />
                                <div v-else-if="column.field === 'displayValuer'">
                                    <div
                                        v-if="
                                            ['dvm', 'ovm'].includes(
                                                props.row.valuation_type
                                            ) && isTypeDispatcher(props.row)
                                        "
                                        class="level top"
                                    >
                                        <b-field
                                            :type="valuerSelectType(props.row) || ''"
                                            class="mb-0"
                                        >
                                            <b-select
                                                v-model="
                                                    valuerSelects[
                                                        props.row.valuation_request_ref
                                                    ]
                                                "
                                                size="is-small"
                                                @input="onValuerChange($event, props.row)"
                                                :disabled="
                                                    props.row.valuer.assigned &&
                                                        props.row.valuation_type == 'ovm'
                                                "
                                            >
                                                <option value="N/A">
                                                    {{ t('unassigned') }}
                                                </option>
                                                <option
                                                    v-for="val in valuers[
                                                        props.row.valuation_type
                                                    ]"
                                                    :key="val.email"
                                                    :value="val.username"
                                                >
                                                    {{ val.email }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                        <b-tag
                                            v-if="valuerSelectType(props.row)"
                                            :type="valuerSelectType(props.row)"
                                            rounded
                                            class="ml-1 valuer-select-badge"
                                        />
                                    </div>
                                    <div v-else></div>
                                </div>
                                <span v-else>
                                    {{ props.row[column.field] }}
                                </span>
                            </template>
                        </b-table-column>
                    </template>
                </b-table>
                <mobile-table
                    v-else
                    :columns="columns"
                    :table-data="tableData"
                    :show-owner="requestFilter !== 'my_requests'"
                    :valuers="valuers"
                    :valuer-selects="valuerSelects"
                    :loading="
                        requestFilter === 'my_requests'
                            ? userRequestsIsLoading
                            : requestsIsLoading
                    "
                    @valuerChange="
                        (args) => {
                            onValuerChange(...args)
                        }
                    "
                    @updateValuerSelects="updateValSelects"
                    @updateRow="
                        (args) => {
                            updateRow(...args)
                        }
                    "
                    @openSubModal="open_sub_modal"
                />
                <!-- table section -->
            </div>
        </div>
        <b-modal
            v-model="valuer_change_modal_open"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal
            @close="close_valuer_change_modal"
        >
            <div v-if="valuer_change_modal_open" class="card confirm">
                <div class="modal-card valuer_change_modal p-5">
                    <p class="mb-5 has-text-centered">
                        {{ t('valuer_change_modal.content') }}
                    </p>
                    <div class="buttons is-centered">
                        <b-button
                            type="is-light"
                            size="is-small"
                            @click="close_valuer_change_modal"
                        >
                            {{ t('valuer_change_modal.actions.cancel') }}
                        </b-button>
                        <b-button
                            type="is-primary"
                            size="is-small"
                            @click="
                                updateValuer(modal_selected_valuer, modal_selected_row)
                            "
                        >
                            {{ t('valuer_change_modal.actions.confirm') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="substitution_modal_open"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="substitution_modal_open" class="card confirm">
                <div class="modal-card sub_modal p-5">
                    <p class="mb-5 has-text-centered">{{ t('sub_modal.content') }}</p>
                    <div class="buttons is-centered">
                        <b-button
                            type="is-light"
                            size="is-small"
                            @click="close_sub_modal"
                        >
                            {{ t('sub_modal.actions.cancel') }}
                        </b-button>
                        <b-button
                            type="is-primary"
                            size="is-small"
                            @click="take_ownership"
                        >
                            {{ t('sub_modal.actions.confirm') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="shownDuplicate"
            trap-focus
            :can-cancel="['escape']"
            width="fit-content"
        >
            <div v-if="shownDuplicate" class="card p-4">
                <p class="mb-5 has-text-centered is-size-5 has-text-black">
                    <b>
                        {{ $t('duplicates_modal.title') }}
                        {{ shownDuplicate.valuation_request_ref }}
                    </b>
                </p>
                <b-table
                    :data="duplicatesData"
                    hoverable
                    mobile-cards
                    paginated
                    scrollable
                    pagination-size="is-small"
                    default-sort="modified_at"
                    default-sort-direction="desc"
                    :loading="isDuplicatesLoading"
                    :per-page="10"
                    :current-page.sync="currentDuplicatesPage"
                    :searchable="false"
                    class="duplicates-table"
                >
                    <template v-for="column in duplicates_columns">
                        <b-table-column
                            :key="column.valuation_request_ref"
                            v-bind="column"
                        >
                            <template #header>
                                <div
                                    class="is-flex-direction-column is-align-items-center is-justify-content-flex-end"
                                >
                                    <div class="nowrap">{{ column.label }}</div>
                                    <div class="has-text-primary mt-3">
                                        {{
                                            column.date
                                                ? date_format(
                                                      shownDuplicate[column.field]
                                                  )
                                                : shownDuplicate[column.field]
                                        }}
                                    </div>
                                </div>
                            </template>
                            <template v-if="column.date" #default="props">
                                <span :class="dateClass(props.row, column)">
                                    {{ date_format(props.row[column.field]) }}
                                </span>
                            </template>
                            <template
                                v-else-if="column.field === 'reasons'"
                                #default="props"
                            >
                                {{ generateReasons(props.row[column.field]) }}
                            </template>
                            <template
                                v-else-if="column.field === 'valuation_request_ref'"
                                #default="props"
                            >
                                <router-link
                                    v-if="
                                        isValuerOrTypeDispatcher(props.row) &&
                                            [
                                                'submitted',
                                                'needs-review',
                                                'valued',
                                                'exotic',
                                            ].includes(props.row.status)
                                    "
                                    :to="{
                                        name: 'valuation.request',
                                        params: {
                                            action: 'extra-info',
                                            lang: $route.params.lang,
                                        },
                                        query: {
                                            valuation_request_ref:
                                                props.row.valuation_request_ref,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    {{ props.row[column.field] }}
                                </router-link>
                                <span v-else>
                                    {{ props.row[column.field] }}
                                </span>
                            </template>
                            <template
                                v-else-if="column.field === 'display_request_ref'"
                                #default="props"
                            >
                                <a
                                    :href="
                                        `//${
                                            props.row.deployment
                                        }.rock.estate/valuation/${
                                            $route.params.lang
                                        }/request/extra-info?valuation_request_ref=${
                                            props.row[column.field]
                                        }`
                                    "
                                    target="_blank"
                                >
                                    {{ props.row[column.field] }}
                                </a>
                            </template>
                            <template
                                v-else-if="
                                    ['last_submission_lang', 'displayOwner'].includes(
                                        column.field
                                    )
                                "
                                #default="props"
                            >
                                <div class="level top">
                                    <span class="level-left">
                                        {{ props.row[column.field] }}
                                    </span>
                                </div>
                            </template>
                            <template v-else #default="props">
                                {{ props.row[column.field] }}
                            </template>
                        </b-table-column>
                    </template>
                </b-table>
                <div
                    class="buttons is-flex-direction-row is-justify-content-end is-align-items-center mt-5"
                >
                    <b-button
                        v-if="
                            ['submitted', 'needs-review', 'valued', 'exotic'].includes(
                                shownDuplicate.status
                            ) && DVMEnabled
                        "
                        type="is-primary"
                        :tag="isDispatchDashboard ? 'a' : 'router-link'"
                        :target="isDispatchDashboard ? '_blank' : null"
                        :href="
                            isDispatchDashboard
                                ? `https://${shownDuplicate.deployment}.rock.estate/valuation/${$route.params.lang}/dvm?valuation_request_ref=${shownDuplicate.display_request_ref}`
                                : null
                        "
                        :to="{
                            name: 'valuation.dvm',
                            query: {
                                valuation_request_ref:
                                    shownDuplicate.valuation_request_ref,
                            },
                        }"
                    >
                        {{ $t('duplicates_modal.open_dvm') }}
                    </b-button>
                    <b-button @click="close_duplicates">
                        {{ $t('duplicates_modal.close') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
        <summary-modal ref="summaryModal" :unsubmit="true" />
        <!--<b-modal
            v-model="modal_open"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="modal_open" class="card confirm">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ t('info_title') }}</p>
                    </header>
                    <b-message type="is-primary" has-icon class="mb-0" size="is-medium">
                        <span class="is-modal-message">
                            {{ t('missing_features') }}

                            <ul>
                                <li
                                    v-for="(error,
                                    index) in row_data.avm_transaction.body.errors.filter(
                                        (error) => error.message === 'missing_parameter'
                                    )"
                                    :key="index"
                                >
                                    {{ t(`features.${error.parameter}`) }}
                                </li>
                            </ul>
                        </span>
                    </b-message>
                    <footer class="modal-card-foot">
                        <b-button
                            :label="t('ok')"
                            type="is-primary"
                            icon-left="close"
                            expanded
                            @click="modal_open = false"
                        />
                    </footer>
                </div>
            </div>
        </b-modal>-->
    </div>
</template>

<script>
import { useRequests } from '@/composables/requests'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'
import { localeMixin } from '@/components/mixins/locale.js'
import ValuerWorkload from './ValuerWorkload.vue'
import ActionButtons from './ActionButtons.vue'
import NewRequestButton from '@/components/common/NewRequestButton.vue'
import SummaryModal from '@/apps/ERS/components/pages/result/SummaryModal'
import MobileTable from './MobileTable.vue'

export default {
    name: 'Dashboard',
    components: {
        ValuerWorkload,
        SummaryModal,
        NewRequestButton,
        ActionButtons,
        MobileTable,
    },
    mixins: [localeMixin],
    setup() {
        const {
            requestFilter,
            timeFilter,
            requestsIsLoading,
            requestsIsFetching,
            requests,
            userRequestsIsLoading,
            userRequestsIsFetching,
            userRequests,
        } = useRequests()
        return {
            requestFilter,
            timeFilter,
            requestsIsLoading,
            requestsIsFetching,
            requests,
            userRequestsIsLoading,
            userRequestsIsFetching,
            userRequests,
        }
    },
    data() {
        return {
            shownTypes: [],
            workloadOpen: false,
            data_overrides: {},
            valuers: {
                dvm: [],
                ovm: [],
            },
            currentPage: 1,
            perPage: 10,
            perPageList: [5, 10, 25, 50],
            isLoading: true,
            shownDuplicate: null,
            isDuplicatesLoading: false,
            duplicatesData: [],
            currentDuplicatesPage: 1,
            loading_error: false,
            result: [],
            //modal_open: false,
            row_data: {},
            now: Date.now(),
            reportLoading: false,
            reportLink: null,
            reportFileName: 'billing-report',
            modal_selected_row: null,
            modal_selected_valuer: null,
            substitution_modal_open: false,
            valuer_change_modal_open: false,
            owner_search_query: null,
            owner_emails: null,
            valuerSelects: {},
            requestRefSearch: '',
            date_filters: {
                created_at: null,
                modified_at: null,
            },
        }
    },
    computed: {
        ...mapGetters(['flow_type', 'isMobile']),
        ...mapGetters('auth', ['hasRole', 'accessibleModules', 'roles']),
        // return filtered text
        DVMEnabled() {
            return this.$config.ENABLE_DVM
        },
        newDVMEnabled() {
            return this.$config.ENABLE_NEW_DVM
        },
        columns() {
            const customer_ref_search = this.requestFilter === 'search_request'
            return [
                {
                    field: 'deployment',
                    label: this.$t('customer'),
                    width: '30',
                    visible: this.isDispatchDashboard,
                    searchable: true,
                },
                {
                    field: 'customer_ref',
                    label: this.$t('customer_ref'),
                    width: '40',
                    sortable: true,
                    centered: true,
                    searchable: !customer_ref_search,
                },
                {
                    field: 'valuation_request_ref',
                    visible: !this.isDispatchDashboard,
                    label: this.$t('request_ref'),
                    centered: true,
                    searchable: !customer_ref_search,
                },
                {
                    field: 'valuation_type',
                    visible: this.accessibleModules.length > 1,
                    label: this.$t('type'),
                    centered: true,
                },
                {
                    field: 'display_request_ref',
                    label: this.$t('request_ref'),
                    visible: this.isDispatchDashboard,
                    centered: true,
                    searchable: !customer_ref_search,
                },
                {
                    field: 'display_building_type',
                    label: this.$t('features.f_building_type'),
                    centered: true,
                    searchable: !customer_ref_search,
                },
                {
                    field: 'address',
                    label: this.$t('address'),
                    centered: true,
                    searchable: !customer_ref_search,
                },
                {
                    field: 'displayStatus',
                    label: this.$t('status_col'),
                    centered: true,
                    searchable: !customer_ref_search,
                    sortable: true,
                },
                {
                    field: 'created_at',
                    label: this.$t('created_at'),
                    centered: true,
                    sortable: true,
                    date: true,
                    searchable: this.enableDateSearch,
                },
                {
                    field: 'modified_at',
                    label: this.$t('last_modified'),
                    centered: true,
                    sortable: true,
                    date: true,
                    searchable: this.enableDateSearch,
                },
                {
                    field: 'action',
                    label: this.$t('actions'),
                    centered: true,
                },
                {
                    field: 'displayOwner',
                    label: this.$t('owner'),
                    visible: this.requestFilter !== 'my_requests',
                    centered: true,
                    sortable: true,
                    searchable: !customer_ref_search,
                },
                {
                    field: 'last_submission_lang',
                    label: this.$t('lang'),
                    visible: this.hasRole(['dispatcher', 'valuer']),
                    centered: true,
                    sortable: true,
                    searchable: !customer_ref_search,
                },
                {
                    field: 'displayValuer',
                    label: this.$t('valuer'),
                    visible: this.hasRole('dispatcher'),
                    centered: true,
                    sortable: true,
                    searchable: !customer_ref_search,
                },
            ]
        },
        duplicates_columns() {
            return [
                {
                    field: 'deployment',
                    label: this.$t('customer'),
                    width: '30',
                    visible: this.isDispatchDashboard,
                },
                {
                    field: 'customer_ref',
                    label: this.$t('customer_ref'),
                    width: '40',
                    sortable: true,
                    centered: true,
                },
                {
                    field: 'valuation_request_ref',
                    label: this.$t('request_ref'),
                    visible: !this.isDispatchDashboard,
                    centered: true,
                },
                {
                    field: 'display_request_ref',
                    label: this.$t('request_ref'),
                    visible: this.isDispatchDashboard,
                    centered: true,
                },
                {
                    field: 'address',
                    label: this.$t('address'),
                    centered: true,
                },
                {
                    field: 'reasons',
                    label: this.$t('duplicates_modal.reason'),
                    sortable: true,
                },
                {
                    field: 'displayStatus',
                    label: this.$t('status_col'),
                    centered: true,
                    sortable: true,
                },
                {
                    field: 'created_at',
                    label: this.$t('created_at'),
                    centered: true,
                    sortable: true,
                    date: true,
                },
                {
                    field: 'displayOwner',
                    label: this.$t('owner'),
                    visible: this.requestFilter !== 'my_requests',
                    centered: true,
                    sortable: true,
                },
                {
                    field: 'displayValuer',
                    label: this.$t('valuer'),
                    visible: this.hasRole('dispatcher'),
                    centered: true,
                    sortable: true,
                },
            ]
        },
        enableDateSearch() {
            return this.$config.SHOW_DATE_FILTERS
        },
        tableData() {
            let date_threshold = new Date(2021, 4, 26, 23, 0, 0, 0)
            const reqs =
                this.requestFilter === 'my_requests' ? this.userRequests : this.requests
            const loading =
                this.requestFilter === 'my_requests'
                    ? this.userRequestsIsFetching
                    : this.requestsIsFetching
            const skeleton_row = loading
                ? [{ valuation_request_ref: 'skeleton', modified_at: new Date() }]
                : []
            return [
                ...skeleton_row,
                ...(reqs || [])
                    .map((row, index) => {
                        const _row = {
                            ...row,
                            ...this.data_overrides[row.valuation_request_ref],
                        }
                        this.valuerSelects[_row.valuation_request_ref] = _row.valuer
                            ?.assigned
                            ? _row.valuer.username
                            : 'N/A'
                        return {
                            ..._row,
                            index: index,
                            deployment: this.isDispatchDashboard
                                ? _row.valuation_request_ref.split('_')[0]
                                : null,
                            display_request_ref: this.isDispatchDashboard
                                ? _row.valuation_request_ref.split('_')[1]
                                : null,
                            display_building_type: _row.building_type
                                ? this.$t(`value.${_row.building_type}`)
                                : '',
                            displayStatus:
                                _row.valuation_type === 'ers' && _row.status === 'valued'
                                    ? this.$t('status.completed')
                                    : this.$t(`status.${_row.status}`),
                            displayOwner: _row.owner ? _row.owner.email : '',
                            displayValuer: _row.valuer?.assigned
                                ? _row.valuer.username
                                : 'N/A',
                        }
                    })
                    .filter((item) => {
                        return (
                            !['cancelled', 'predraft'].includes(item.status) &&
                            this.shownTypes.includes(item.valuation_type) &&
                            item.modified_at >= date_threshold &&
                            (this.date_filters.created_at
                                ? utils.dateDiffInDays(
                                      this.date_filters.created_at,
                                      new Date(item.created_at)
                                  ) === 0
                                : true) &&
                            (this.date_filters.modified_at
                                ? utils.dateDiffInDays(
                                      this.date_filters.modified_at,
                                      new Date(item.modified_at)
                                  ) === 0
                                : true) &&
                            (item.ovm_status != 'payment-pending' ||
                                !this.isOnlyOvmValuer)
                        )
                    }),
            ]
        },
        isValuer() {
            return this.hasRole('valuer')
        },
        showTabs() {
            return (
                this.hasRole(['dispatcher', 'reporter']) ||
                (this.hasRole('substitute') && this.$config.SUBSTITUTE_FEATURE_ENABLED)
            )
        },
        showAllRequestsTab() {
            return (
                this.hasRole(['dispatcher', 'reporter']) ||
                (this.hasRole('substitute') &&
                    this.$config.SUBSTITUTE_FEATURE_ENABLED &&
                    this.$config.SHOW_ALL_REQUESTS)
            )
        },
        isSearchRefButtonDisabled() {
            return utils.isEmptyStr(this.requestRefSearch)
        },
        isDispatchDashboard() {
            return !!this.$config.DISPATCHED_DEPLOYMENTS?.length
        },
        onlyUserRequests() {
            return this.requestFilter == 'my_requests'
        },
        isOnlyBorrower() {
            const rolesList = utils.rolesToList(this.roles)
            return rolesList.length == 1 && rolesList[0] == 'ovm:borrower'
        },
        isOnlyOvmValuer() {
            let allValuerRoles = utils
                .rolesToList(this.roles)
                .filter((i) => i.includes(':valuer'))
            return allValuerRoles.length == 1 && allValuerRoles[0] == 'ovm:valuer'
        },
    },
    updateValSelects(data) {
        this.valuerSelects = data
    },
    watch: {
        requestFilter(val) {
            if (val === 'search_request') {
                this.$router.push({
                    query: { ...this.$route.query, customer_ref: this.requestRefSearch },
                })
            } else {
                if (this.$route.query.hasOwnProperty('customer_ref')) {
                    const q = this.$route.query
                    delete q.customer_ref
                    this.$router.push({ query: q })
                }
            }
        },
        isValuer(val) {
            if (val) {
                this.shownTypes = this.accessibleModules.filter((m) =>
                    this.hasRole('valuer', m)
                )
            } else {
                this.shownTypes = this.accessibleModules
            }
        },
        userRequests(val) {
            // Auto-redirect if only one request and is only borrower
            if (this.isOnlyBorrower && val.length == 1) {
                const row = val[0]
                const to = {
                    name: 'valuation.ovm.borrower',
                    query: {
                        step: row.ovm_status,
                        valuation_request_ref: row.valuation_request_ref,
                    },
                }
                console.log(to)
                // Note: this redirect will generate a Vue error that we need to intercept:
                this.$router.replace(to).catch(() => {})
            }
        },
    },
    // load data
    mounted() {
        if (this.isValuer) {
            this.shownTypes = this.accessibleModules.filter((m) =>
                this.hasRole('valuer', m)
            )
        } else {
            this.shownTypes = this.accessibleModules
        }
        if (this.hasRole('dispatcher')) {
            this.load_valuers()
        }

        var self = this
        setInterval(function() {
            self.now = Date.now()
        }, 1000)
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Dashboard',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
    methods: {
        requestTypeIcon(requestType) {
            return utils.requestTypeIcon(requestType)
        },
        generateReasons(list) {
            return list
                .map((reason) => this.$t(`duplicates_modal.reasons.${reason}`))
                .join(', ')
        },
        date_format: utils.formatDate,
        display_address(el) {
            let a = utils.full_address(el.address, el.features)
            if (utils.via_address(el.address, el.features) !== '') {
                a = `${a} (via ${utils.via_address(el.address, el.features)})`
            }
            return a
        },
        load_data() {
            /*this.requestFilter =
                !this.isValuer && (!this.showTabs || this.requestsTab === '1')*/
        },
        searchByRef() {
            this.$router.push({
                query: { ...this.$route.query, customer_ref: this.requestRefSearch },
            })
        },
        searchByRefSubmit(event) {
            event.preventDefault()
            if (!this.isSearchRefButtonDisabled) {
                this.searchByRef()
            }
        },
        load_valuers() {
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, 'users')
            let params = { roles: 'valuer', modules: 'dvm' }
            axios.get(url, { params }).then((result) => {
                this.valuers.dvm = result.data
                this.valuers.dvm.sort((a, b) => {
                    if (a.email < b.email) return -1
                    if (a.email > b.email) return 1
                    return 0
                })
            })
            params = { roles: 'valuer', modules: 'ovm' }
            axios.get(url, { params }).then((result) => {
                this.valuers.ovm = result.data
                this.valuers.ovm.sort((a, b) => {
                    if (a.email < b.email) return -1
                    if (a.email > b.email) return 1
                    return 0
                })
            })
        },
        open_sub_modal(row) {
            this.modal_selected_row = {
                ...this.data[row.index],
                index: row.index,
            }
            this.substitution_modal_open = true
        },
        close_sub_modal() {
            this.substitution_modal_open = false
            this.modal_selected_row = null
        },
        close_valuer_change_modal() {
            if (this.valuer_change_modal_open) {
                const prevValuer = !this.isValuer
                    ? 'N/A'
                    : this.modal_selected_row.valuer.assigned
                    ? this.modal_selected_row.valuer.email
                    : 'N/A'
                this.valuerSelects[
                    this.modal_selected_row.valuation_request_ref
                ] = prevValuer
                this.valuer_change_modal_open = false
                this.modal_selected_row = null
                this.modal_selected_valuer = null
            }
        },
        take_ownership() {
            axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_owner(this.modal_selected_row.valuation_request_ref)
                )
                .then((response) => {
                    this.$set(
                        this.data_overrides,
                        this.modal_selected_row.valuation_request_ref,
                        { owner: response.data }
                    )

                    this.close_sub_modal()
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        onValuerChange(valuer, row) {
            if (valuer === 'N/A') {
                this.release_request(row)
            } else if (!row.valuer?.assigned) {
                this.updateValuer(valuer, row)
            } else {
                this.modal_selected_row = row
                this.modal_selected_valuer = valuer
                this.valuer_change_modal_open = true
            }
        },
        release_request(row) {
            return axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_valuer(row.valuation_request_ref),
                    null,
                    {
                        params: { release: 'true' },
                    }
                )
                .then((response) => {
                    this.$set(this.data_overrides, row.valuation_request_ref, {
                        valuer: response.data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        updateValuer(username, row) {
            return axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_valuer(row.valuation_request_ref),
                    null,
                    {
                        params: { username, steal: true },
                    }
                )
                .then((response) => {
                    this.$set(this.data_overrides, row.valuation_request_ref, {
                        valuer: response.data,
                    })
                    this.close_valuer_change_modal()
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        isResubmitted(row) {
            const lastSubmit = new Date(row.last_submission_at).getTime()
            return (
                row.status === 'submitted' &&
                row.last_dvm_action_at &&
                lastSubmit > row.last_dvm_action_at
            )
        },
        isValuerOrTypeDispatcher(row) {
            return this.isTypeDispatcher(row) || row.valuer?.is_self
        },
        isTypeDispatcher(row) {
            return this.hasRole('dispatcher', row.valuation_type)
        },
        needsBorrowerEmail(row) {
            return !!(
                row.status === 'submitted' &&
                row.ovm_status === 'borrower-registration-pending'
            )
        },
        needsBorrowerAction(row) {
            return !!(
                row.status === 'submitted' &&
                ['appointment-pending', 'payment-pending', 'borrower-details'].includes(
                    row.ovm_status
                )
            )
        },
        needsAppointmentConfirmation(row) {
            return !!(
                row.status === 'submitted' && row.ovm_status === 'confirmation-pending'
            )
        },
        isAppointmentConfirmed(row) {
            return !!(
                row.status === 'submitted' && row.ovm_status === 'valuation-pending'
            )
        },
        generateReport() {
            this.reportLoading = true
            return axios
                .get(`${utils.urlJoin(this.$config.VALUATION_API_URL, 'billing_report')}`)
                .then((response) => {
                    var now = new Date()
                    var formattedDate = `${now.getFullYear()}-${(
                        '0' +
                        now.getMonth() +
                        1
                    ).slice(-2)}-${('0' + now.getDate()).slice(-2)}-${(
                        '0' + now.getHours()
                    ).slice(-2)}-${('0' + now.getMinutes()).slice(-2)}`

                    var blob = new Blob(['\ufeff', response.data])

                    this.reportLink = URL.createObjectURL(blob)
                    this.reportFileName = `billing-report-${formattedDate}`

                    this.reportLoading = false
                })
                .catch((error) => {
                    console.error(error)
                    this.reportLoading = false
                })
        },
        valuerSelectType(row) {
            if (!this.isValuer || row.status !== 'submitted') return false
            if (!row.valuer.assigned) return 'is-success'
            if (row.valuer.is_self) return 'is-primary'
            return 'is-danger'
        },
        dateClass(row, column) {
            if (!this.isValuer) return false
            if (column.field !== 'modified_at') return false
            if (row.status !== 'submitted') return false
            const submission = new Date(row.last_submission_at)
            const now = new Date()
            const difference = utils.dateDiffInBusinessDays(submission, now)
            if (difference === 1) return 'has-text-primary'
            if (difference >= 2) return 'has-text-danger'
            return false
        },
        clearDate(field) {
            this.date_filters[field] = null
        },
        load_duplicates(selected_row) {
            this.shownDuplicate = selected_row
            this.shownDuplicate.created_at = new Date(selected_row.created_at)
            this.isDuplicatesLoading = true
            this.currentDuplicatesPage = 1
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                !this.isValuer && (!this.showTabs || this.requestsTab === '1')
                    ? 'user'
                    : null,
                'requests',
            ])
            let dupl = {}
            const params = {}
            params.valuation_request_refs = ''
            selected_row.other_data.similar_requests.forEach((req, i) => {
                if (i) params.valuation_request_refs += ','
                if (req.hasOwnProperty('valuation_request_ref')) {
                    //Similar requests stored as objects
                    const ref = req.valuation_request_ref
                    params.valuation_request_refs += ref
                    dupl[ref] = req
                } else {
                    //Legacy for when similar requests were just strings
                    const ref = req.split(' ')[0]
                    //Save reasons as array for legacy requests too
                    const reasons = req.split(': ')[1].split(', ')
                    params.valuation_request_refs += ref
                    dupl[ref] = {
                        valuation_request_ref: ref,
                        reasons,
                    }
                }
            })
            params.load_all = 'true'
            axios.get(url, { params }).then((result) => {
                this.duplicatesData = result.data.map((row, index) => {
                    return {
                        ...dupl[row.valuation_request_ref],
                        ...row,
                        created_at: new Date(row.created_at),
                        index: index,
                        address: this.display_address(row),
                        deployment: this.isDispatchDashboard
                            ? row.valuation_request_ref.split('_')[0]
                            : null,
                        display_request_ref: this.isDispatchDashboard
                            ? row.valuation_request_ref.split('_')[1]
                            : null,
                        display_building_type: row.building_type
                            ? this.$t(`value.${row.building_type}`)
                            : '',
                        displayStatus: this.$t(`status.${row.status}`),
                        displayOwner: row.owner ? row.owner.email : '',
                        displayValuer: this.isTypeDispatcher(row)
                            ? 'N/A'
                            : row.valuer.assigned
                            ? row.valuer.username
                            : 'N/A',
                    }
                })
                this.isDuplicatesLoading = false
            })
        },
        close_duplicates() {
            this.shownDuplicate = null
            this.isDuplicatesLoading = false
            this.duplicatesData = []
            this.currentDuplicatesPage = 1
        },
        updateRow(ref, data) {
            this.$set(this.data_overrides, ref, data)
        },
        /*view_details(row) {
            return axios
                .get(
                    `${utils.urlJoin(this.$config.VALUATION_API_URL, 'state')}?` +
                        `valuation_request_ref=${row.valuation_request_ref}` +
                        `&lang=${this.$route.params.lang}`
                )
                .then((response) => {
                    this.row_data = response.data.data
                    this.modal_open = true
                })
                .catch((error) => {
                    console.error(error)
                })
        },*/
    },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 999px) {
    .dashboard-page {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .dashboard-container {
            display: flex;
            flex-direction: column;
            gap: 16px;

            & ::v-deep .table-mobile-sort {
                margin-bottom: 0;
            }

            & ::v-deep .table-wrapper {
                overflow: visible;
            }

            .filter-selector {
                width: 100%;

                .control {
                    flex-grow: 1;

                    & ::v-deep .button {
                        border: 1px solid rgba(0, 50, 84, 0.3);
                        background: #fff;
                        color: rgba(0, 50, 84, 0.3);
                        font-size: 14px;

                        &.is-selected {
                            background: var(--color-primary);
                            color: white;
                            border-color: var(--color-primary);
                        }

                        span {
                            font-weight: 500;
                        }
                    }

                    &:not(:last-child) {
                        border-right: none;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.overflow-hidden {
    overflow: hidden;
}

.container {
    overflow: scroll;
}

.new_request-dropdown-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    color: #001837 !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.request_type_filter {
    display: flex;
    flex-direction: row;
    gap: 16px;

    @media screen and (max-width: 999px) {
        flex-direction: column;
        gap: 8px;

        & > div {
            line-height: 14px;
        }
    }

    .control-label {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #001837;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }
}
.val_type_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.tag > span {
    line-height: 25px;
}
.table-wrapper {
    min-height: 300px;
    position: relative;
}
.searchbox {
    min-width: 300px;
}
.clear-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: grey;

    &:hover {
        color: black;
    }
}
.table {
    & > thead > tr:nth-child(2) .th-wrap > span {
        width: 100%;
    }
    & > tbody > tr > td {
        vertical-align: middle !important;
    }
    & .row_highlighted {
        background: #ffdd9fdd;

        &:hover {
            background: #ffdd9fff !important;
        }
    }

    .valuer-select-badge {
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
    }
}
.duplicates-table {
    .nowrap {
        white-space: nowrap;
    }
}
.button-loading .icon {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
