<template>
    <grid-card :title="$t('valuer_tool.commons.apartment.title')">
        <div class="grid_card-content_flex">
            <div class="content_flex-item">
                <b-field :label="$t('features.f_bottom_floor')">
                    <b-input v-model="f_bottom_floor" />
                </b-field>
            </div>
            <div class="content_flex-item">
                <b-field :label="$t('features.f_top_floor')">
                    <b-input v-model="f_top_floor" />
                </b-field>
            </div>
            <div class="content_flex-item content_flex-item--full">
                <b-field :label="$t('features.f_floors')">
                    <b-checkbox-button
                        v-for="(floor, i) in floors"
                        :key="`floor_button_${i}`"
                        v-model="f_floors"
                        :native-value="floor"
                    >
                        <span>{{ floor }}</span>
                    </b-checkbox-button>
                </b-field>
            </div>
        </div>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'

export default {
    name: 'ApartmentFeatures',
    components: {
        GridCard,
    },
    data: () => ({
        f_top_floor: 0,
        f_bottom_floor: 0,
        f_floors: [],
    }),
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFeatures']),
            ...mapMutations(requestObjects, ['SET_FEATURES']),
        }
    },
    computed: {
        floors() {
            let array = []
            for (
                let i = this.getFeatures.f_bottom_floor;
                i <= this.getFeatures.f_top_floor;
                i++
            ) {
                array.push(i)
            }
            return array
        },
    },
    watch: {
        f_top_floor(val) {
            this.SET_FEATURES({ f_top_floor: val })
        },
        f_bottom_floor(val) {
            this.SET_FEATURES({ f_bottom_floor: val })
        },
        f_floors(val) {
            this.SET_FEATURES({ f_floors: val })
        },
    },
    mounted() {
        this.f_top_floor = this.getFeatures.f_top_floor
        this.f_bottom_floor = this.getFeatures.f_bottom_floor
        this.f_floors = this.getFeatures.f_floors
    },
}
</script>
