import { ref, computed, watch } from 'vue'
import { useMutation, useQuery, useQueries, useQueryClient } from '@tanstack/vue-query'
import utils from '@/shared/plugins/utils'
// https://stackoverflow.com/a/75085570/16901626
import { useRoute } from 'vue2-helpers/vue-router'
// import { useRoute } from 'vue-router' // Vue 3

export function useRequests() {
    const route = useRoute()
    const display_address = function(el) {
        let a = utils.full_address(el.address, el.features)
        if (utils.via_address(el.address, el.features) !== '') {
            a = `${a} (via ${utils.via_address(el.address, el.features)})`
        }
        return a
    }
    const load_request = (request) => {
        try {
            return {
                ...request,
                address: display_address(request),
                document_info: request.document_info ?? {},
                building_type: request.features?.f_building_type ?? null,
                modified_at: new Date(request.modified_at),
                created_at: new Date(request.created_at),
                last_dvm_action_at: request.last_dvm_action_at
                    ? new Date(request.last_dvm_action_at)
                    : null,
            }
        } catch (error) {
            console.error({ error, request })
            return { error: true }
        }
    }
    const requestFilter = ref('my_requests')
    const timeFilter = ref('last_month')
    const requests_query = computed(() => {
        const days_delta = timeFilter.value === 'last_month' ? 30 : 365
        const [modified_after] = new Date(
            new Date().setDate(new Date().getDate() - days_delta)
        )
            .toISOString()
            .split('T')
        const query = {
            modified_after,
        }
        if (route.query.valuation_request_refs) {
            query.valuation_request_refs = route.query.valuation_request_refs
        }
        return query
    })

    const {
        isLoading: requestsIsLoading,
        isFetching: requestsIsFetching,
        data: requests,
    } = useQuery({
        queryKey: ['valuation', 'requests', requests_query],
        select: (data) => {
            return data.map(load_request).filter((request) => !request.error)
        },
        placeholderData: [],
        enabled: computed(() => requestFilter.value !== 'my_requests'),
    })
    const queryClient = useQueryClient()

    const {
        isLoading: userRequestsIsLoading,
        isFetching: userRequestsIsFetching,
        data: userRequests,
    } = useQuery({
        queryKey: ['valuation', 'user', 'requests', requests_query],
        select: (data) => {
            return data.map(load_request).filter((request) => !request.error)
        },
        placeholderData: [],
        enabled: computed(() => requestFilter.value === 'my_requests'),
    })

    // Invalidate stale valuation requests based on modified_at
    watch(userRequests, (requests) => {
        for (const request of requests) {
            const queryKey = [
                'valuation',
                'request',
                request.valuation_request_ref,
                { details: 'full' },
            ]
            const request_data = queryClient.getQueryData(queryKey)
            if (
                request_data &&
                new Date(request_data.modified_at) < request.modified_at
            ) {
                queryClient.invalidateQueries({
                    queryKey,
                })
                // console.log('request fetched but stale -> invalidating', {
                //     queryKey,
                // })
            } else {
                // if (!request_data) {
                //     console.log('request not fetched -> fetching', { queryKey })
                // } else {
                //     console.log('request fetched, still valid', { queryKey })
                // }
            }
        }
    })
    // Todo: see if this should be
    //   - turned into multiple queryClient.prefechQuery()
    //   - moved into the watcher
    const fullRequestQueries = useQueries({
        queries: computed(() =>
            userRequests.value
                ? userRequests.value.map(({ valuation_request_ref }) => {
                      return {
                          queryKey: [
                              'valuation',
                              'request',
                              valuation_request_ref,
                              { details: 'full' },
                          ],
                          staleTime: Infinity,
                      }
                  })
                : []
        ),
    })

    return {
        requestFilter,
        timeFilter,
        requestsIsLoading,
        requestsIsFetching,
        requests,
        userRequestsIsLoading,
        userRequestsIsFetching,
        userRequests,
        fullRequestQueries,
    }
}
