<template>
    <grid-card no-padding>
        <template slot="header">
            <h5 class="grid_card-title">
                {{ title }}
            </h5>
            <clear-button
                v-if="!postReno && !isEditing"
                class="edit-button"
                @click="editValues"
            >
                <svg-icon icon="edit" color="#798DA6" width="24" height="24" />
            </clear-button>
            <div v-else-if="isEditing" class="editing-actions">
                <white-button @click="cancelEdit">{{ $t('common.cancel') }}</white-button>
                <dark-button @click="saveEdit">{{ $t('common.save') }}</dark-button>
            </div>
        </template>
        <template slot="default">
            <div v-if="postReno" class="adjustment">
                <h6>{{ $t('valuer_tool.valuation.reno_adjustment') }}</h6>
                <div class="adjustment-slider">
                    <b-slider v-model="adjustment" :min="0" :max="200" />
                    <b-input v-model="adjustment" type="number" />
                </div>
            </div>
            <div class="value_grid">
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>{{ $t('valuer_tool.valuation.estimated_price') }}</h6>
                    <b-skeleton
                        v-if="!get_avm_values"
                        height="20px"
                        width="100%"
                        rounded
                    />
                    <h6 v-else>
                        {{ priceFormat(get_avm_values.market_value) }}
                    </h6>
                </div>
                <div class="value_grid-entry">
                    <h6>{{ $t('valuation.market_value') }}</h6>
                    <b-skeleton
                        v-if="!get_avm_values"
                        height="20px"
                        width="100%"
                        rounded
                    />
                    <h6 v-else-if="postReno || !isEditing">
                        {{ priceFormat(market_value || get_avm_values.market_value) }}
                    </h6>
                    <div v-else class="priceInput">
                        <b-input
                            v-model="market_value"
                            type="number"
                            icon="currency-eur"
                            size="is-small"
                        />
                    </div>
                </div>
                <div class="value_grid-entry">
                    <h6>{{ $t('valuation.forced_sale_value') }}</h6>
                    <b-skeleton
                        v-if="!get_avm_values"
                        height="20px"
                        width="100%"
                        rounded
                    />
                    <h6 v-else-if="postReno || !isEditing">
                        {{
                            priceFormat(
                                forced_sale_value || get_avm_values.forced_sale_value
                            )
                        }}
                    </h6>
                    <div v-else class="priceInput">
                        <b-input
                            v-model="forced_sale_value"
                            type="number"
                            icon="currency-eur"
                            size="is-small"
                        />
                    </div>
                </div>
                <div class="value_grid-entry">
                    <h6>{{ $t('valuation.rental_value') }}</h6>
                    <b-skeleton
                        v-if="!get_avm_values"
                        height="20px"
                        width="100%"
                        rounded
                    />
                    <h6 v-else-if="postReno || !isEditing">
                        {{
                            priceFormat(
                                rental_value || get_avm_values.rental_value,
                                false
                            )
                        }}
                    </h6>
                    <div v-else class="priceInput">
                        <b-input
                            v-model="rental_value"
                            type="number"
                            icon="currency-eur"
                            size="is-small"
                        />
                    </div>
                </div>
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>{{ $t('valuation.reconstruction_value') }}</h6>
                    <h6>
                        {{
                            getValuation.reconstruction_value !== null
                                ? priceFormat(getValuation.reconstruction_value)
                                : 'N/A'
                        }}
                    </h6>
                </div>
                <div v-if="!postReno" class="value_grid-entry value_grid-entry--grey">
                    <h6>{{ $t('valuation.cadastral_income') }}</h6>
                    <h6>
                        {{
                            cadastral_income
                                ? Math.round(cadastral_income * 10) / 10 +
                                  ' ' +
                                  $t('unit.euro')
                                : 'N/A'
                        }}
                    </h6>
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '../../elements/GridCard'
import WhiteButton from '../../elements/WhiteButton.vue'
import DarkButton from '../../elements/DarkButton.vue'
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'ValueCard',
    components: {
        GridCard,
        ClearButton,
        WhiteButton,
        DarkButton,
    },
    props: {
        postReno: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        cadastral_income: null,
        analyticsShown: false,
        adjustment: 0,
        isEditing: false,
        forced_sale_value: null,
        market_value: null,
        rental_value: null,
        prevVals: {
            market_value: null,
            forced_sale_value: null,
            rental_value: null,
        },
    }),
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, [
                'getAvmTransactionEstimations',
                'getAvmRentalEstimations',
                'getFeatures',
                'getValuation',
            ]),
            ...mapMutations(requestObjects, ['SET_VALUATION']),
        }
    },
    computed: {
        building_type() {
            return this.getFeatures.f_building_type
        },
        is_plot() {
            return utils.is_plot(this.building_type)
        },
        title() {
            return this.$t(
                `valuer_tool.valuation.value_${this.postReno ? 'post' : 'pre'}_reno`
            )
        },
        get_avm_values() {
            if (!this.getAvmRentalEstimations || !this.getAvmTransactionEstimations)
                return

            const { asking_price_q50: rental_value } = this.getAvmRentalEstimations
            const { asking_price_q50: market_value } = this.getAvmTransactionEstimations
            const forced_sale_value = utils.forced_sale_value(market_value)

            return {
                forced_sale_value,
                rental_value,
                market_value,
            }
        },
    },
    watch: {
        adjustment(val) {
            if (this.postReno) {
                this.market_value =
                    (this.getValuation.market_value ||
                        this.getAvmTransactionEstimations.asking_price_q50) *
                    (1 + +val / 100)
                this.forced_sale_value =
                    (this.getValuation.forced_sale_value || this.getForcedSaleValue()) *
                    (1 + +val / 100)
                this.rental_value =
                    (this.getValuation.rental_value ||
                        this.getAvmRentalEstimations.asking_price_q50) *
                    (1 + +val / 100)

                this.SET_VALUATION({ renovation_adjustment: +val })
            }
        },
        'getValuation.market_value': function (val) {
            if (this.postReno) {
                this.market_value =
                    this.getValuation.market_value * (1 + this.adjustment / 100)
            }
        },
        'getValuation.forced_sale_value': function (val) {
            if (this.postReno) {
                this.forced_sale_value =
                    this.getValuation.forced_sale_value * (1 + this.adjustment / 100)
            }
        },
        'getValuation.rental_value': function (val) {
            if (this.postReno) {
                this.rental_value =
                    this.getValuation.rental_value * (1 + this.adjustment / 100)
            }
        },
        isEditing(val) {
            if (val) {
                this.rental_value =
                    Math.round(
                        (this.rental_value || this.get_avm_values.rental_value) / 1
                    ) * 1
                this.market_value =
                    Math.round(
                        (this.market_value || this.get_avm_values.market_value) / 1000
                    ) * 1000
                this.forced_sale_value =
                    Math.round(
                        (this.forced_sale_value ||
                            this.get_avm_values.forced_sale_value) / 1000
                    ) * 1000
            }
        },
    },
    mounted() {
        let suffix = this.postReno ? '_post_renovation' : ''

        let market_value =
            this.getValuation['market_value' + suffix] ||
            this.getValuation.market_value ||
            this.getAvmTransactionEstimations.asking_price_q50
        let forced_sale_value =
            this.getValuation['forced_sale_value' + suffix] ||
            this.getValuation.forced_sale_value ||
            this.getForcedSaleValue()
        let rental_value =
            this.getValuation['rental_value' + suffix] ||
            this.getValuation.rental_value ||
            this.getAvmRentalEstimations.asking_price_q50

        this.market_value = market_value
        this.forced_sale_value = forced_sale_value
        this.rental_value = rental_value

        if (this.postReno) {
            this.adjustment = this.getValuation.renovation_adjustment
        } else {
            this.cadastral_income = this.getValuation.cadastral_income
        }
    },
    methods: {
        priceFormat(x, thousands = true) {
            return utils.priceFormat(x, thousands)
        },
        getForcedSaleValue() {
            return utils.forced_sale_value(
                this.market_value || this.get_avm_values.market_value
            )
        },
        editValues() {
            this.prevVals.market_value = this.market_value
            this.prevVals.forced_sale_value = this.forced_sale_value
            this.prevVals.rental_value = this.rental_value
            this.isEditing = true
        },
        cancelEdit() {
            this.market_value = this.prevVals.market_value
            this.forced_sale_value = this.prevVals.forced_sale_value
            this.rental_value = this.prevVals.rental_value
            this.prevVals.market_value = null
            this.prevVals.forced_sale_value = null
            this.prevVals.rental_value = null
            this.isEditing = false
        },
        saveEdit() {
            this.SET_VALUATION({
                market_value: +this.market_value,
                forced_sale_value: +this.forced_sale_value,
                rental_value: +this.rental_value,
            })
            this.prevVals.market_value = null
            this.prevVals.forced_sale_value = null
            this.prevVals.rental_value = null

            this.isEditing = false
        },
    },
}
</script>
<style lang="scss" scoped>
.edit-button {
    padding: 0;

    &:hover {
        svg path {
            fill: #001837 !important;
        }
    }
}

.editing-actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.adjustment {
    padding: 24px;
    padding-top: 0;
    margin-bottom: 24px;
    border-bottom: 1px solid #d6e2e9;

    &-slider {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;

        & > *:first-child {
            flex-basis: 85%;
        }

        * {
            flex-grow: 0;
        }
    }
}

.value_grid {
    padding: 24px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-entry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h6 {
            color: #001837;
            font-size: 14px;
            font-weight: 700;
        }

        &--grey {
            opacity: 0.5;
        }
    }
}
</style>
