<template>
    <div id="sidebar">
        <sidebar-summary />
        <sidebar-section
            :title="$t('valuer_tool.general.title')"
            :active="isActiveRoute('general')"
            @click="goTo('general')"
        />
        <sidebar-section
            :title="$t('valuer_tool.exterior.title')"
            :active="isActiveRoute('exterior')"
            @click="goTo('exterior')"
        />
        <sidebar-section
            :title="$t('valuer_tool.interior.title')"
            :active="isActiveRoute('interior')"
            @click="goTo('interior')"
        />
        <sidebar-section
            v-if="is_apartment"
            :title="$t('valuer_tool.commons.title')"
            :active="isActiveRoute('commons')"
            @click="goTo('commons')"
        />
        <sidebar-section
            :title="$t('valuer_tool.surroundings.title')"
            :active="isActiveRoute('surroundings')"
            @click="goTo('surroundings')"
        />
        <!-- <sidebar-section title="Renovation" /> -->
        <sidebar-section
            :title="$t('valuer_tool.valuation.title')"
            :active="isActiveRoute('value')"
            @click="goTo('value')"
        />
        <span v-if="isMobile" class="sidebar-filler" />
        <div class="internal-remarks">
            <div class="internal-remarks__text">
                <h1>{{ $t('valuer_tool.internal_remarks.title') }}</h1>
                <p>
                    {{ $t('valuer_tool.internal_remarks.description') }}
                </p>
            </div>
            <div class="internal-remarks__text-input">
                <textarea
                    v-model="internal_remarks"
                    :placeholder="$t('valuer_tool.internal_remarks.placeholder')"
                />
            </div>
        </div>
        <clear-button class="sidebar_buttn" @click="saveVal">
            <h5>{{ $t('valuer_tool.sidebar.save_val.label') }}</h5>
            <h6>{{ $t('valuer_tool.sidebar.save_val.sub') }}</h6>
        </clear-button>
        <clear-button
            class="sidebar_buttn submit_buttn"
            :disabled="!isOnline"
            @click="() => saveVal(true)"
        >
            <h5>{{ $t('valuer_tool.sidebar.submit_val') }}</h5>
        </clear-button>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from '@/composables/fake-vuex'
import { mapGetters as vuexGetters } from 'vuex'
import { useRequest } from '@/composables/request'
import { useOnline } from '@/composables/utils/online'
import SidebarSummary from './Summary.vue'
import SidebarSection from './Section.vue'
import utils from '@/shared/plugins/utils'
import ClearButton from '@/components/elements/ClearButton.vue'

export default {
    name: 'Sidebar',
    components: {
        SidebarSummary,
        SidebarSection,
        ClearButton,
    },

    setup() {
        const { isOnline } = useOnline()
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, [
                'getFeatures',
                'getValuation',
                'getDVMFeatures',
                'getOVMFeatures',
                'getRequestRef',
                'isRequestLoaded',
            ]),
            ...mapMutations(requestObjects, ['SET_DVM_FEATURES']),
            ...mapActions(requestObjects, ['saveOnsiteVal']),
            isOnline,
        }
    },
    computed: {
        ...vuexGetters(['isMobile']),
        activeRoute() {
            return this.$route.name.toLowerCase()
        },
        is_apartment() {
            return utils.is_apartment(this.getFeatures.f_building_type)
        },
        internal_remarks: {
            get() {
                return this.getDVMFeatures.internal_remarks
            },
            set(value) {
                this.SET_DVM_FEATURES({ internal_remarks: value })
            },
        },
    },
    methods: {
        isActiveRoute(name) {
            return this.activeRoute.startsWith('valuation.valuer-app.' + name)
        },
        goTo(target) {
            let fullTarget = 'valuation.valuer-app.' + target

            if (fullTarget.toLowerCase() !== this.activeRoute) {
                let { params, query } = this.$route
                this.$router.push({ name: fullTarget, params, query })
            }

            document
                .getElementById('valuer_tool-content')
                .scroll({ top: 0, behavior: 'smooth' })
        },
        saveVal(submit = false) {
            this.saveOnsiteVal().then((res) => {
                if (submit) {
                    this.$axios
                        .put(
                            utils.urlJoin(this.$config.VALUATION_API_URL, [
                                'request',
                                this.getRequestRef,
                                'status',
                            ]),
                            null,
                            { params: { action: 'submit-valuation' } }
                        )
                        .then(() => {
                            this.$router.push({
                                name: 'dashboard',
                            })
                        })
                } else {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('valuer_tool.global.val_saved'),
                        position: this.isMobile ? 'is-top' : 'is-bottom',
                        type: 'is-success',
                    })
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
#sidebar {
    width: 300px;
    height: 100%;
    border-right: 1px solid rgba(214, 226, 233, 1);
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;

    @media screen and (max-width: 999px) {
        width: 100%;
        border-right: 0;
        position: static;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        padding-bottom: 10px;

        .sidebar-filler {
            flex-grow: 1;
            min-height: 10px;
        }
    }

    .sidebar_buttn {
        display: flex;
        flex-direction: column;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 5px;
        background: #001837;
        width: calc(100% - 32px);
        margin: 16px;
        color: white;

        @media screen and (max-width: 999px) {
            margin: 6px 16px;
        }

        h5 {
            font-size: 14px;
            font-weight: 700;
            margin: 0;
        }

        h6 {
            font-size: 10px;
            font-weight: 500;
            margin: 0;
        }

        &:hover {
            background: #001837cf;
        }

        &:disabled {
            background: #abbacc;
        }

        &.submit_buttn {
            background: var(--color-primary);

            &:hover {
                background: #009cffcf;
            }
        }
    }
}

.internal-remarks {
    display: flex;
    margin: 1rem;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6875rem;
    border-radius: 0.5rem;
    border: 1px solid #d6e2e9;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 255, 255, 0.1) 100%
        ),
        #fff;

    /* shadow floating */
    box-shadow: 0px 1px 2px 0px rgba(0, 24, 55, 0.06),
        0px 4px 6px 0px rgba(0, 24, 55, 0.06);

    &__text {
        display: flex;
        align-self: stretch;
        flex-direction: column;
        h1 {
            color: #001837;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            color: #001837;
            font-size: 10px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
        }
    }
    &__text-input {
        min-width: 100%;
        textarea {
            display: flex;
            height: 7.5rem;
            padding: 12px;
            align-items: flex-start;
            gap: 1.5rem;
            resize: none;
            border-radius: 5px;
            border: 1px solid #d6e2e9;
            background: #fff;
            width: 100%;
            &::placeholder {
                color: rgba(0, 24, 55, 0.4);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25rem; /* 142.857% */
            }
        }
    }
}
</style>
