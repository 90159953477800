<template>
    <div class="side-stepper">
        <div class="steps">
            <span id="grey-line"></span>
            <span
                id="progress"
                :style="{
                    'max-height': `${progress}px`,
                    transition: progressTransition,
                }"
            ></span>

            <template v-for="(step, index) in steps">
                <span
                    :key="`step_${step.name}`"
                    class="step"
                    :class="{
                        active: currentStep >= index,
                        current: currentStep === index && !currentSubstep,
                    }"
                >
                    <svg-icon
                        :icon="step.icon"
                        width="20"
                        height="20"
                        :color="currentStep >= index ? '#fff' : '#798DA6'"
                        :fill="true"
                    />
                    <span class="step-name">{{ $t(`steps.${step.name}`) }}</span>
                </span>

                <template v-for="(substep, sub_index) in step.substeps">
                    <span
                        v-show="currentStep === index"
                        :key="`step_${step.name}_${substep}`"
                        class="step substep"
                        :class="{
                            active:
                                currentStep === index && currentSubstep >= sub_index + 1,
                            current:
                                currentStep === index && currentSubstep === sub_index + 1,
                        }"
                    >
                        <span class="step-name">{{ $t(`steps.${substep}`) }}</span>
                    </span>
                </template>
            </template>

            <span
                class="step"
                :class="{
                    active: currentStep >= 5,
                }"
            >
                <svg-icon
                    icon="checkered-flag"
                    width="20"
                    height="20"
                    :color="currentStep >= 5 ? '#fff' : '#798DA6'"
                    :fill="true"
                />
                <span class="step-name">{{ $t(`steps.finish`) }}</span>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { localeMixin } from '@/components/mixins/locale.js'

// logic could be change if route structure
export default {
    name: 'SideSteps',
    mixins: [localeMixin],
    data() {
        return {
            prevRoute: '/',
            progressTransition: 'none',
        }
    },
    computed: {
        ...mapGetters('ersStore', [
            'getHeatingFuel',
            'getAddress',
            'getLivingAreaChanged',
        ]),
        progress() {
            let length = 0

            let actualSubstep =
                this.isHeatingElectric &&
                this.currentSubstep > 5 &&
                this.currentStep === 4
                    ? this.currentSubstep - 1
                    : this.currentSubstep

            const totalSteps = this.currentStep + actualSubstep

            length =
                ((this.currentStep + 1) * 2 - 1) * 20 +
                totalSteps * 20 +
                (actualSubstep * 2 - 1) * 7

            return length
        },
        currentStep() {
            if (typeof this.$route.meta === 'function') {
                return this.$route.meta(this.$route).step
            } else {
                return this.$route.meta.step
            }
        },
        currentSubstep() {
            if (typeof this.$route.meta === 'function') {
                let param
                if (this.$route.name === 'ers.living-area') {
                    param = this.effective_level
                } else if (this.$route.name === 'ers.building-dimensions') {
                    param = this.getLivingAreaChanged
                }
                return this.$route.meta(this.$route, param).substep
            } else {
                return this.$route.meta.substep
            }
        },
        isHeatingElectric() {
            return this.getHeatingFuel === 'electricity'
        },
        effective_level() {
            return +this.getAddress.effective_level
        },
        living_area_changed() {
            return this.getLivingAreaChanged
        },
        dimensionsSubsteps() {
            let substeps = ['attic', 'basement']

            if (this.effective_level <= 2) {
                substeps.push('building_dimensions')
            }

            substeps.push('living_area')

            if (this.living_area_changed) {
                substeps.push('building_dimensions')
            }

            return substeps
        },
        steps() {
            return [
                {
                    name: 'customer_ref',
                    icon: 'location',
                    substeps: [],
                },
                {
                    name: 'building_type',
                    icon: 'house',
                    substeps: [],
                },
                {
                    name: 'address',
                    icon: 'location',
                    substeps: [],
                },
                {
                    name: 'building_dimensions',
                    icon: 'ruler',
                    substeps: this.dimensionsSubsteps,
                },
                {
                    name: 'energy_performance',
                    icon: 'insulation',
                    substeps: [
                        'epc',
                        'f_construction_year',
                        'roof',
                        'walls',
                        'floor',
                        'windows',
                        'heating',
                        'solar_panels',
                    ],
                },
                {
                    name: 'consumption',
                    icon: 'energy',
                    substeps: ['consumers', 'big_consumers'],
                },
            ]
        },
    },
    watch: {
        progress(prevVal, val) {
            this.progressTransition = 'none'
            let maxLength = document.getElementById('grey-line').offsetHeight

            if (prevVal > val) {
                this.progressTransition = 'max-height 300ms ease-in-out'
            }
        },
    },
    /* [Mathieu] had to disable that logic for now because it doesn't take into acount query parameters, which may get lost when going to the faq
    watch: {
        $route(to, from) {
            if (to.params.action === 'faq' && from.params.action !== 'faq') {
                this.prevRoute = from.params.action || '/'
            }
        },
    },*/
}
</script>

<style lang="scss" scoped>
.side-stepper {
    height: 90%;
    width: 100%;
    position: relative;
    right: 80px;

    #grey-line,
    #progress {
        position: absolute;
        top: 20px;
        left: calc(50% - 1px);
        width: 2px;
        z-index: 97;
    }

    #grey-line {
        height: calc(100% - 25px);
        background: #dfe4eb;
    }

    #progress {
        z-index: 98;
        height: 100%;
        background: var(--primary);
    }

    .steps {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: min-content;
        max-height: 100%;
        width: 100%;
        gap: 20px;
    }

    .step {
        z-index: 99;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: #dfe4eb;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: center;

        position: relative;
        overflow: visible;

        .step-name {
            position: absolute;
            left: 60px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            text-align: left;

            font-size: 14px;
            line-height: 40px;
            font-weight: semibold;
            max-width: 300px;
            width: min-content;
            white-space: nowrap;
        }

        &.active {
            background: var(--primary);

            .step-name {
                color: black;
                font-weight: 700;
            }
        }

        &.current {
            .step-name {
                color: var(--primary);
            }
        }
    }

    .substep {
        width: 14px;
        height: 14px;
        background: white;
        border: 2px solid #dfe4eb;
        border-position: outside;

        position: relative;

        &::after {
            content: '';
            height: 0;
            width: 0;
            background: var(--primary);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50px;
            transition: all 0.4s ease-out;
        }

        .step-name {
            left: 46px;
            font-size: 12px;
            line-height: 14px;
        }

        &.active {
            background: white;
            border-color: var(--primary);

            &::after {
                height: 6px;
                width: 6px;
            }
        }

        &.current {
            .step-name {
                color: var(--primary);
            }
        }
    }
}
</style>
