var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ers-page-content"},[_c('div',{staticClass:"ers-page-title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('energy.big_consumers.question'))+" ")])]),_c('div',{staticClass:"ers-page-info"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-lightbulb.svg"),"alt":"","srcset":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('energy.big_consumers.tooltip')))])]),_c('div',{staticClass:"column is-12"},[_c('yes-no',{model:{value:(_vm.hasElectricVehicles),callback:function ($$v) {_vm.hasElectricVehicles=$$v},expression:"hasElectricVehicles"}})],1),_c('div',{staticClass:"column is-12 mt-2"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left","tag":"router-link","to":{
                    name: 'ers.consumers',
                    params: {
                        lang: _vm.$route.params.lang,
                    },
                    query: _vm.$route.query,
                }}},[_vm._v(" "+_vm._s(_vm.$t('previous'))+" ")]),_c('submit-button',{on:{"on-click":function($event){_vm.$emit('open-summary-modal', {
                        unsubmit: false,
                    })
                    _vm.nextPage()}}}),(_vm.hasElectricVehicles !== null)?_c('b-button',{class:{ 'ers-next-bttn-dark': _vm.hasRequestBeenUnsubmitted },attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right"},on:{"click":_vm.nextPage}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.next')))])]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }