<template>
    <grid-card is-full :title="$t('dvm_features.f_swimming_pool')" no-mobile-toggle>
        <template slot="header">
            <label class="flex-label">
                <h5>{{ $t('valuer_tool.exterior.swimming_pool.question') }}</h5>
                <b-checkbox v-model="f_swimming_pool" />
            </label>
        </template>
        <template v-if="f_swimming_pool" slot="default">
            <div class="grid_card-content_grid three-cols">
                <div class="content_grid-item">
                    <label>{{ $t('dvm_features.f_swimming_pool_area') }}</label>
                    <b-input v-model="f_swimming_pool_area" type="number" />
                </div>
            </div>
        </template>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'

export default {
    name: 'SwimmingPool',
    components: {
        GridCard,
    },
    data: () => ({
        f_swimming_pool: false,
        f_swimming_pool_area: null,
    }),
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getDVMFeatures']),
            ...mapMutations(requestObjects, ['SET_DVM_FEATURES']),
        }
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        f_swimming_pool(val) {
            this.SET_DVM_FEATURES({
                f_swimming_pool: val,
            })
        },
        f_swimming_pool_area(val) {
            this.SET_DVM_FEATURES({
                f_swimming_pool_area: val,
            })
        },
    },
    mounted() {
        this.f_swimming_pool = this.getDVMFeatures?.f_swimming_pool || false
        this.f_swimming_pool_area = this.getDVMFeatures?.f_swimming_pool_area || null
    },
}
</script>
