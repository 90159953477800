<template>
    <grid-card :title="!full && $t(`${configLocation}.${feature}`)" :is-full="full">
        <div class="input_row">
            <h2 v-if="full">{{ $t(`${configLocation}.${feature}`) }}</h2>
            <b-input v-model="value" :type="type" />
            <h5 v-if="hasUnit">{{ $t(`unit.${feature_config.unit}`) }}</h5>
        </div>
    </grid-card>
</template>
<script>
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
import { config } from '@/shared/assets/config/config'
import utils from '@/shared/plugins/utils'
import GridCard from '..'
export default {
    name: 'MultiChoiceCard',
    components: {
        GridCard,
    },
    props: {
        feature: {
            type: String,
            required: true,
        },
        configLocation: {
            type: String,
            default: 'dvm_features',
        },
        small: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getFullBuildingData']),
            ...mapMutations(requestObjects, ['SET_UNIVERSAL']),
        }
    },
    computed: {
        feature_config() {
            return config.find(
                (item) =>
                    item.name === this.feature && item.location === this.configLocation
            )
        },
        type() {
            return ['int', 'float'].includes(this.feature_config.type)
                ? 'number'
                : this.feature_config.type
        },
        hasUnit() {
            return this.feature_config.unit && !utils.isEmptyStr(this.feature_config.unit)
        },
        value: {
            get: function() {
                return (
                    this.getFullBuildingData[this.configLocation]?.[this.feature] ?? null
                )
            },
            set: function(val) {
                this.SET_UNIVERSAL({
                    key: this.configLocation,
                    values: { [this.feature]: val },
                })
            },
        },
    },
}
</script>
<style lang="scss" scoped>
.input_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    div {
        flex-grow: 1;
    }

    h2 {
        color: #001837;
        font-size: 18px;
        font-weight: 700;
        flex-grow: 2;
    }
}
</style>
