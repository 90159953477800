import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import Router from '@/router'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        simulation_ref: null,
        is_owner: false,
        request_reference: null,
        customer_reference: null,
        frontend_submission_time: null,
        budget: null,
        target_epc: null,
        address_text: {
            streetname: null,
            streetnumber: null,
            municipality: null,
            postalcode: null,
            boxnumber: null,
            full_address: null,
            building_id: null,
            parcel_ids: [],
            effective_level: null,
            level: null,
        },

        building_type: null,
        building_features: {
            f_inner_volume: 0,
        },
        features: {
            living_area_changed: null,
            initial_features: {
                f_inner_volume: null,
                f_living_area: null,
                f_top_floor: null,
                f_percent_of_roof_flat: null,
                f_neighbour_type: null,
            },
            f_inner_volume: null,
            f_epc_numeric: null,
            f_epc_label: null,
            f_top_floor: null,
            level: null,
            effective_level: null,
            f_percent_of_roof_flat: null,
            f_roof_area: null,
            f_best_pane_area: null,
            f_building_area: null,
            exterior_wall_area: null,
            f_neighbour_type: null,
            is_epc_number: null,
            is_epc_label: null,
            energy_consumption_known: null,

            f_construction_year: null,
            f_living_area: null,
            has_epc_certificate: null,

            wall_attributes: [
                {
                    has_renovation: null,
                    has_insulation: null,
                    renovation_year: null,
                },
            ],

            roof_attributes: [
                {
                    has_renovation: null,
                    has_insulation: null,
                    renovation_year: null,
                },
            ],
            floor_attributes: [
                {
                    has_renovation: null,
                    has_insulation: null,
                    renovation_year: null,
                },
            ],
            heating_attributes: [
                {
                    heating_type: null,
                    fuel: null,
                    f_construction_year: null,
                },
            ],
            window_type: null,

            has_attic: null,
            is_attic_heated: null,
            photovoltaic_available: null,
            has_basement: null,
            ventilation_type: null,
            ventilation_subtype: null,
            n_residents: 0,
            known_consumptions: [],
            estimated_consumptions: [],
            has_big_energy_consumers: null,

            region: null,
            // nr_heated_levels: null,
            // nr_residents: null,
            // electricity_consumption: null,
            // heating_consumption: null,
            // big_energy_consumers: null,
            // heating_construction_year: null,
            // heating_type: null,

            // solar_panels: false,
            // wall_insulation: null,
            // roof_insulation: null,
            // floor_insulation: null,
            // ventilation_system: null,
            // has_basement: false,
            // has_attic: false,
        },
        results: {
            data: [],
            simulation: {
                started_at: null,
                finished_at: null,
                status: 'not_started', // "started", "finished", "failed", "pending"
                wait_time: null,
            },
            selectedPackage: 'best', // budget, custom, target, best
            renovations: [
                {
                    index: 0,
                    name: 'roofIn',
                    renovationCostName: 'roof_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 1,

                    name: 'atticFloor',
                    renovationCostName: 'roof_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 2,
                    name: 'wallExt',
                    renovationCostName: 'wall_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 3,
                    name: 'windowsDouble',
                    renovationCostName: 'window_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 4,
                    name: 'condGas',
                    renovationCostName: 'heating_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 5,
                    name: 'heatPump',
                    renovationCostName: 'heating_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 7,
                    name: 'wallCav',
                    renovationCostName: 'wall_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 8,
                    name: 'unitPv',
                    renovationCostName: 'pv_investment_cost',
                    selected: false,
                    disabled: false,
                },
                {
                    index: 9,
                    name: 'roofExt',
                    renovationCostName: 'roof_investment_cost',
                    selected: false,
                    disabled: false,
                },
            ],
            renovationOptions: null,
            consumptions: {
                gas: {
                    you: null,
                    avg: null,
                },
                elec: {
                    you: null,
                    avg: null,
                },
            },
            selectedScenario: null,
        },
        map_scene2d: {
            parcel_geojson: null,
            building_geojson: null,
            address_geojson: null,
            flood_geojson: null,
            map_data_ready: false,

            center: {
                f_lat: null,
                f_lon: null,
            },
        },
        map_scene3d: {
            map_data_ready: false,
            building_id: null,
        },
        simulation: {
            status: 'not_started',
            request_id: null,
            started_at: null,
            finished_at: null,
        },
        other_data: {
            last_page_info: {},
            unsubmitted: false,
        },
    }
}

function renovations_from_scenario(scenario, all_renovations, features) {
    const renovationsNames = scenario.name.split('_')
    return renovationsNames.map((name) => {
        const currentRenovation = all_renovations.filter(
            (renovation) => renovation.name === name
        )[0]
        const price = scenario[currentRenovation.renovationCostName]
        const title = currentRenovation.title
        const tooltip = currentRenovation.tooltip
        const index = currentRenovation.index
        const area = {
            atticFloor: features.f_building_area * (1 - features.f_percent_of_roof_flat),
            roofIn: features.f_roof_area,
            roofExt: features.f_roof_area,
            unitPv: features.f_best_pane_area * 0.8,
            wallExt: features.exterior_wall_area,
            wallIn: features.exterior_wall_area,
            wallCav: features.exterior_wall_area,
            windowsDouble: null,
            condGas: null,
            heatPump: null,
        }[name]
        const extra_features = {
            atticFloor: { insulation_material: 'pur_pir' },
            roofIn: { insulation_material: 'pur_pir' },
            roofExt: { insulation_material: 'mineral_wool' },
            unitPv: { pv_type: 'monocrystalline' },
            wallExt: { insulation_material: 'pur_pir' },
            wallIn: { insulation_material: 'pur_pir' },
            wallCav: { insulation_material: 'pur_pir' },
            windowsDouble: { window_type: 'hr_double' },
            condGas: {},
            heatPump: { heat_pump_type: 'air_water' },
        }[name]

        return {
            name,
            title,
            tooltip,
            index,
            price,
            area,
            subsidy: null,
            selected: true,
            extra_features,
        }
    })
}

const ersStore = {
    namespaced: true,
    state: { ...getDefaultState() },
    getters: {
        getSimulationRef(state) {
            return state.simulation_ref
        },
        getScenariosAvailable(state) {
            return state.results.data.length > 1
        },
        getBudget(state) {
            return state.budget
        },
        getCustomerReference(state) {
            return state.customer_reference
        },
        getTargetEpc(state) {
            return state.target_epc
        },
        getIsOwner(state) {
            return state.is_owner
        },
        hasRequestBeenUnsubmitted(state) {
            return state.other_data.unsubmitted
        },
        getConsumptionKnown(state) {
            return state.features.energy_consumption_known
        },
        getBuildingId(state) {
            return state.address_text.building_id
        },
        getAddress(state) {
            return {
                ...state.address_text,
            }
        },
        getFeatures(state) {
            return {
                ...state.features,
            }
        },
        getRegion(state) {
            return utils.iso_regions[state.features.region]
        },
        getRegionISO(state) {
            return state.features.region
        },
        getCurrentScenario(state) {
            return state.results.data.filter((scenario) => scenario.name === 'current')[0]
        },
        getCurrentEpcLabel(state, getters) {
            let label = '?'
            const currentScenario = getters.getCurrentScenario
            Object.keys(utils.epc_mapping[state.features.region]).every((key) => {
                label = key
                if (
                    currentScenario.epc_ind >
                    utils.epc_mapping[state.features.region][key]
                ) {
                    return true
                } else return false
            })
            return {
                label: label,
                color: utils.epc_color(utils.iso_regions[state.features.region], label),
            }
        },
        getEpcPercents(state, getters) {
            const currentScenario = getters.getCurrentScenario

            return utils.epc_percent_mapping(
                utils.iso_regions[state.features.region],
                currentScenario.epc_ind
            )
        },
        getTargetEpcScenario: (state, getters) => (epcTarget = 350) => {
            const formatedEpc = epcTarget * -1 + 1 // we need to format the value because the slider have decreasing values
            const scenarios = [
                ...getters.getScenarios.filter(
                    (scenario) =>
                        scenario.epc_ind <= formatedEpc && scenario.name !== 'current'
                ),
            ]

            const targetEpcScenario = scenarios.reduce((prev, current) =>
                Math.abs(formatedEpc - prev.total_investment_cost) <
                Math.abs(formatedEpc - current.total_investment_cost)
                    ? prev
                    : current
            )

            return {
                ...targetEpcScenario,
                renovations: renovations_from_scenario(
                    targetEpcScenario,
                    getters.getRenovations,
                    state.features
                ),
            }
        },
        getBestEpcScenario(state, getters) {
            const bestScenario = getters.getScenarios.reduce((prev, current) =>
                prev.epc_ind < current.epc_ind ? prev : current
            )

            return {
                ...bestScenario,
                renovations: renovations_from_scenario(
                    bestScenario,
                    getters.getRenovations,
                    state.features
                ),
            }
        },
        getSelectedScenario(state) {
            return state.results.selectedScenario || state.results.data[0] // if no scenario available return the first one (current scenario)
        },
        getSelectedPackage(state) {
            return state.results.selectedPackage
        },
        getRenovationOptions(state) {
            return state.results.renovationOptions
        },
        getMapScene2d(state) {
            return {
                ...state.map_scene2d,
            }
        },
        getMapScene3d(state) {
            return {
                ...state.map_scene3d,
            }
        },
        getRequestRef(state) {
            return state.request_reference
        },
        getBuildingType(state) {
            return state.building_type
        },
        getFloorArea(state) {
            return state.features.f_living_area
        },
        getWallInsulation(state) {
            return state.features.wall_attributes
        },
        getRoofInsulation(state) {
            return state.features.roof_attributes
        },
        getFloorInsulation(state) {
            return state.features.floor_attributes
        },
        getHeatingFuel(state) {
            return (
                state.features.heating_attributes[0].fuel ||
                state.features.heating_attributes[0].heating_type
            )
        },
        getEpcVars(state) {
            return {
                f_epc_numeric: state.features.f_epc_numeric,
                f_epc_label: state.features.f_epc_label,
                is_epc_number: state.features.is_epc_number,
                is_epc_label: state.features.is_epc_label,
            }
        },
        getLivingAreaChanged(state) {
            return state.features.living_area_changed
        },
        getSimulation(state) {
            return state.simulation
        },
        getApproxLivingArea(state) {
            if (+state.features.effective_level < 3) {
                const typical_floor_height = 2.5
                const {
                    f_building_area,
                    f_percent_of_roof_flat,
                    is_attic_heated,
                    f_top_floor,
                } = state.features
                const attic_correction = is_attic_heated
                    ? (typical_floor_height / 2) *
                      f_building_area *
                      (1 - f_percent_of_roof_flat)
                    : 0
                const inner_volume =
                    f_building_area * (f_top_floor * typical_floor_height) +
                    attic_correction
                return Math.round(inner_volume / 2.8)
            }
            return Math.round(state.features.f_inner_volume / 2.8)
        },
        getRenovations(state) {
            return state.results.renovations
        },
        getRenovationsSummary(state, getters) {
            if (state.results.data.length === 1) return
            const currentPackage = state.results.selectedPackage
            const currentEpcScore = state.results.data.find(
                (scenario) => scenario.name === 'current'
            ).epc_ind

            const currentScenario = state.results.data.filter(
                (scenario) => scenario.name === 'current'
            )[0]

            const computeRenovationPriceVat = (name, price) => {
                const isOlderThanTenYo =
                    new Date().getFullYear() - state.features.f_construction_year > 10
                const isFlanders = utils.iso_regions[state.features.region] === 'Flanders'
                const isEligibleRenovation = ['heatPump', 'unitPv'].includes(name)
                const priceVat =
                    isOlderThanTenYo && isFlanders && isEligibleRenovation
                        ? price - (price / 100) * 15
                        : price
                return priceVat
            }
            if (['target', 'best', 'budget'].includes(currentPackage)) {
                const renovations = state.results.selectedScenario.renovations
                const prices = renovations.map((reno) => {
                    return getters.getCostFromRenovation(reno.name)
                })
                const subsidies = renovations.map((r) => {
                    return computeRenovationPriceVat(r.name, r.price)
                })
                const totalPrice = prices.reduce((total, num) => total + num, 0)
                const totalSubsidies = subsidies.reduce((total, num) => total + num, 0)
                return {
                    renovations: renovations.map((renovation) => ({
                        title: renovation.title,
                        price: renovation.price,
                        name: renovation.name,
                        subsidy: computeRenovationPriceVat(
                            renovation.name,
                            renovation.price
                        ),
                        afterSubsidy: computeRenovationPriceVat(
                            renovation.name,
                            renovation.price
                        ),
                    })),
                    total: {
                        title: 'Total',
                        price: totalPrice,
                        subsidy: totalSubsidies,
                        afterSubsidies: totalSubsidies,
                    },
                    futureEpcScore: state.results.selectedScenario.epc_ind,
                    epcSaving: Math.abs(
                        currentScenario.epc_ind - state.results.selectedScenario.epc_ind
                    ),
                    co2Savings: Math.abs(
                        state.results.selectedScenario.total_co2 -
                            currentScenario.total_co2
                    ),
                    yearlySavings: state.results.selectedScenario.yearly_savings,
                    labels: [
                        utils.computeLabelFromScore(
                            currentEpcScore,
                            state.features.region
                        ).label,
                        utils.computeLabelFromScore(
                            state.results.selectedScenario.epc_ind,
                            state.features.region
                        ).label,
                    ],
                }
            } else {
                const selectedRenovations = state.results.renovations.filter(
                    (r) => r.selected
                )
                const prices = selectedRenovations.map((reno) => {
                    const priceFromSelectedScenario = getters.getCostFromRenovation(
                        reno.name
                    )
                    return priceFromSelectedScenario
                })
                const subsidies = selectedRenovations.map((r) => {
                    const price = state.results.data.find(
                        (scenario) => scenario.name === r.name
                    ).total_investment_cost
                    return computeRenovationPriceVat(r.name, price)
                })
                const totalPrice = prices.reduce((total, num) => total + num, 0)
                const totalSubsidies = subsidies.reduce((total, num) => total + num, 0)

                const compare = (a, b) => a.sort().join(',') === b.sort().join(',')

                const selectedScenario = state.results.selectedScenario

                return {
                    renovations: selectedRenovations.map((renovation, i) => ({
                        title: renovation.title,
                        name: renovation.name,
                        price: prices[i],
                        subsidy: computeRenovationPriceVat(renovation.name, prices[i]),
                        afterSubsidy: 0,
                    })),
                    total: {
                        title: 'Total',
                        price: totalPrice,
                        subsidy: totalSubsidies,
                        afterSubsidies: totalPrice - totalSubsidies,
                    },
                    futureEpcScore: selectedScenario?.epc_ind || currentEpcScore,
                    epcSaving: Math.abs(
                        currentScenario.epc_ind -
                            (selectedScenario?.epc_ind || currentEpcScore)
                    ),
                    co2Savings: Math.abs(currentScenario.co2_savings),
                    yearlySavings: state.results.selectedScenario.yearly_savings,
                    labels: [
                        utils.computeLabelFromScore(
                            currentEpcScore,
                            state.features.region
                        ).label,
                        utils.computeLabelFromScore(
                            selectedScenario?.epc_ind || currentEpcScore,
                            state.features.region
                        ).label,
                    ],
                }
            }
        },
        getPropertyValue(state) {
            if (
                state.results.data.length === 1 ||
                state.results.selectedScenario.name === 'current'
            ) {
                return '0%'
            }
            let value = Math.round(state.results.selectedScenario.re_value_increase)
            value = value < 1 ? 1 : value
            return value
        },
        getScenarios(state) {
            return state.results.data
        },
        getScenariosNames(state) {
            return state.results.data.map((scenario) => scenario.name)
        },
        getSimulationStatus(state) {
            return state.results.simulation.status
        },
        getOtherData(state) {
            return state.other_data
        },
        getEffectiveLevel(state) {
            return state.address_text.effective_level
        },
        getBudgetScenario: (state, getters) => (price) => {
            // get all scenarios
            let scenarios = state.results.data
            // keep only the ones that are under a threshold (price)
            scenarios = scenarios.filter(
                (scenario) => scenario.total_investment_cost <= price
            )

            // sort them again by epc value (decreasing)
            scenarios = scenarios.sort((a, b) => {
                if (a.epc_ind < b.epc_ind) {
                    return 1
                } else {
                    return -1
                }
            })
            // take the last one (the best EPC value)
            const scenario = scenarios.slice(-1)[0]

            if (!scenario || scenario.name === 'current') return

            return {
                ...scenario,
                renovations: renovations_from_scenario(
                    scenario,
                    state.results.renovations,
                    state.features
                ),
            }
        },
        getSelectedRenovationList(state, getters) {
            return renovations_from_scenario(
                getters.getSelectedScenario,
                state.results.renovations.filter((renovation) => renovation.selected),
                state.features
            )
        },
        getCostFromRenovation: (state, getters) => (renovationName) => {
            return getters.getSelectedScenario[
                getters.getRenovations.find((reno) => reno.name === renovationName)
                    .renovationCostName
            ]
        },
        getVisibleRenovationList(state, getters) {
            const getRenovationByName = (renovationName) => {
                const currentScenario = getters.getScenarios.find(
                    (scenario) => scenario.name === renovationName
                )

                const currentRenovation = getters.getRenovations.find(
                    (renovation) => renovation.name === renovationName
                )

                const priceFromSelectedScenario = getters.getCostFromRenovation(
                    renovationName
                )
                const priceFromDefaultScenario = currentScenario.total_investment_cost
                const price = currentRenovation.selected
                    ? priceFromSelectedScenario
                    : priceFromDefaultScenario
                const index = currentRenovation.index
                const title = currentRenovation.title
                const tooltip = currentRenovation.tooltip
                return {
                    name: renovationName,
                    price,
                    subsidy: 123,
                    index,
                    tooltip,
                    title,
                    selected: currentRenovation.selected,
                }
            }

            let renovations = []
            Object.keys(getters.getRenovationOptions).forEach((key) => {
                getters.getRenovationOptions[key].forEach((item) => {
                    renovations.push(getRenovationByName(item))
                })
            })
            return renovations
        },
        getMinMaxBudget(state, getters) {
            let scenarios = [...getters.getScenarios]
            const compareTotalInvestmentCost = (a, b) => {
                if (a.total_investment_cost < b.total_investment_cost) {
                    return -1
                }
                if (a.total_investment_cost > b.total_investment_cost) {
                    return 1
                }
                return 0
            }
            if (scenarios.length > 1) {
                scenarios = scenarios
                    .sort(compareTotalInvestmentCost)
                    .filter((scenario) => scenario.total_investment_cost > 0)
            }

            const min = scenarios[0].total_investment_cost
            const max = scenarios[scenarios.length - 1].total_investment_cost

            return {
                min,
                max,
                sorted: scenarios.map((scenario) => scenario.total_investment_cost),
            }
        },
    },
    mutations: {
        SET_SIMULATION_REF(state, ref) {
            state.simulation_ref = ref
        },
        SET_CUSTOMER_REF(state, val) {
            state.customer_reference = val
        },
        SET_BUDGET(state, val) {
            state.budget = +val
        },
        SET_TARGET_EPC(state, val) {
            state.target_epc = +val
        },
        UPDATE_RENOVATION_YEAR(state, constructionYear) {
            if (state.features.roof_attributes[0].has_insulation === 'idk') {
                state.features.roof_attributes[0].renovation_year = constructionYear
            }
            if (state.features.wall_attributes[0].has_insulation === 'idk') {
                state.features.wall_attributes[0].renovation_year = constructionYear
            }
            if (state.features.floor_attributes[0].has_insulation === 'idk') {
                state.features.floor_attributes[0].renovation_year = constructionYear
            }
            if (
                state.features.heating_attributes[0].heating_type === 'district_heating'
            ) {
                state.features.heating_attributes[0].installation_year = constructionYear
            }
        },
        SET_IS_OWNER(state, value) {
            state.is_owner = value
        },
        SET_LIVING_AREA_CHANGED(state, value) {
            state.features.living_area_changed = value
        },
        SET_OTHER_DATA(state, obj) {
            state.other_data = {
                ...state.other_data,
                ...obj,
            }
        },
        FLAG_SUBMISSION(state, flag = true) {
            if (flag) {
                state.frontend_submission_time = new Date()
            } else {
                state.frontend_submission_time = null
            }
        },
        RESET_STORE(state) {
            Object.assign(state, { ...getDefaultState() })
        },
        RESET_CONSUMPTION(state) {
            state.features.known_consumptions = getDefaultState().features.known_consumptions
            state.features.estimated_consumptions = getDefaultState().features.estimated_consumptions
        },
        SET_REQUEST_REF(state, ref) {
            state.request_reference = ref
        },
        SET_ADDRESS_TEXT(state, address) {
            Object.assign(state.address_text, address)
            let box_appendix = state.address_text.boxnumber
                ? ` b ${state.address_text.boxnumber}`
                : ''
            if (state.address_text.postalcode !== null) {
                state.address_text.full_address = `${state.address_text.streetname} ${state.address_text.streetnumber}${box_appendix}, ${state.address_text.postalcode}`
            } else {
                state.address_text.full_address = ''
            }
        },
        SET_EFFECTIVE_LEVEL(state, newLevel) {
            state.address_text.effective_level = `${newLevel}`
        },
        RESET_MAP(state) {
            state.map_scene2d = getDefaultState().map_scene2d
            state.map_scene3d = getDefaultState().map_scene3d
        },
        RESET_ADDRESS(state) {
            state.address_text = getDefaultState().address_text
        },
        RESET_FEATURES(state) {
            for (const feature of Object.keys(state.features)) {
                if (feature !== 'f_building_type')
                    state.features[feature] =
                        typeof getDefaultState().features[feature] !== 'undefined'
                            ? getDefaultState().features[feature]
                            : null
            }
        },
        SET_BUILDING_TYPE(state, type) {
            // TODO: cleanup the building type situation: instead of duplicating features.f_building_type and building_type, have one be a view of the other if it really must exist;
            state.building_type = type
        },
        SET_FEATURES(state, features) {
            Object.assign(state.features, features)
        },
        SET_CONSUMPTION(state, data) {
            let fuel = data.consumption.consumption_fuel
            state.features.known_consumptions = state.features.known_consumptions.filter(
                (item) => item.consumption_fuel !== fuel
            )
            state.features.estimated_consumptions = state.features.estimated_consumptions.filter(
                (item) => item.consumption_fuel !== fuel
            )
            if (data.consumption_known) {
                state.features.known_consumptions.push(data.consumption)
            } else {
                state.features.estimated_consumptions.push(data.consumption)
            }
        },
        SET_MAP_SCENE2D(state, map_scene2d) {
            state.map_scene2d = getDefaultState().map_scene2d
            Object.assign(state.map_scene2d, map_scene2d)
            state.map_scene2d.map_data_ready =
                state.map_scene2d.building_geojson !== null &&
                state.map_scene2d.parcel_geojson !== null &&
                state.map_scene2d.address_geojson !== null
        },
        SET_MAP_SCENE3D(state, map_scene3d) {
            state.map_scene3d = getDefaultState().map_scene3d
            Object.assign(state.map_scene3d, map_scene3d)
            state.map_scene3d.map_data_ready = state.map_scene3d.building_id !== null
        },
        SET_PARCEL_IDS(state, parcel_ids) {
            state.address_text.parcel_ids = parcel_ids
            let parcel_features = parcel_ids.map((id) =>
                state.map_scene2d.parcel_geojson.features.find(
                    (parcel) => parcel.properties.parcel_id === id
                )
            )
            state.features.f_parcel_area = parcel_features
                .map((p) => p.properties.parcel_area)
                .reduce((a, b) => a + b, 0)
        },
        SELECT_MAIN_BUILDING_ID(state, id) {
            state.address_text.building_id = id
        },
        SET_SIMULATION(state, simulation_data) {
            if (
                simulation_data.status != 'started' &&
                simulation_data.request_id != state.simulation.request_id
            ) {
                return
            }
            Object.assign(state.simulation, simulation_data)
        },
        SET_HEATING_ATTRIBUTES(state, attributes) {
            // let result = {
            //     "heating_type": "boiler",
            //     "subtype": "condensing",
            //     "fuel": attributes.fuel === 'district_heating',
            //     "f_construction_year": attributes.f_construction_year,
            // }

            const result = attributes

            state.features.heating_attributes = result
        },
        SET_BUILDING_FEATURES(state, features) {
            state.building_features = features
        },
        SET_RENOVATION_CARD_SELECTED(state, index) {
            state.results.renovations = state.results.renovations.map((item) => {
                if (item.index === index) {
                    item.selected = !item.selected
                }
                return item
            })
        },
        SET_SELECTED_SCENARIO(state, scenario) {
            state.results.selectedScenario = scenario
        },
        SET_SELECTED_PACKAGE(state, { packageName, scenario }) {
            state.results.selectedPackage = packageName
            if (scenario) {
                state.results.selectedScenario = scenario
                state.results.renovations = state.results.renovations.map(
                    (renovation) => {
                        const selected = scenario.renovations
                            .map((r) => r.name)
                            .includes(renovation.name)
                        return {
                            ...renovation,
                            selected,
                        }
                    }
                )
            }
        },
        SET_RENOVATION_SELECTED(state, { index, value }) {
            state.results.renovations = state.results.renovations.map((item) => {
                if (item.index === index) {
                    item.selected = value
                }
                return item
            })
        },
        RESET_SIMULATION_STATE(state) {
            state.results.simulation = getDefaultState().results.simulation
        },
        SET_RESULT_DATA(state, data) {
            state.results.data = data.scenarios

            // if we removed scenarios from the list, renovation options could no be consistent anymore
            const scenarioNames = data.scenarios
                .map((scenario) => scenario.name)
                .filter((scenarioName) => scenarioName !== 'current')

            let filteredRenovationOptions = {}

            let renovationOptions = data.renovation_options

            for (let renovationType in renovationOptions) {
                if (renovationOptions.hasOwnProperty(renovationType)) {
                    filteredRenovationOptions[renovationType] = []
                    let renovationArray = renovationOptions[renovationType]
                    renovationArray.map((renovationName) => {
                        if (scenarioNames.includes(renovationName)) {
                            filteredRenovationOptions[renovationType].push(renovationName)
                        }
                    })
                }
            }

            state.results.renovationOptions = filteredRenovationOptions
        },
        SET_SIMULATION_STATUS(state, obj) {
            state.results.simulation = {
                ...state.results.simulation,
                ...obj,
            }
        },
        SET_UNSUBMITTED(state) {
            state.other_data.unsubmitted = true
        },
    },
    actions: {
        flushStore({ state }) {},
        submitFeatures({ state }) {
            return axios.post('/submit', state)
        },
        auto_select_parcels(context) {
            for (
                let i = 0;
                i < context.state.map_scene2d.building_geojson.features.length;
                i++
            ) {
                if (
                    context.state.map_scene2d.building_geojson.features[i].properties
                        .building_id == context.state.features.building_id
                ) {
                    let parcel_ids =
                        context.state.map_scene2d.building_geojson.features[i].properties
                            .parcel_ids
                    context.commit('SET_PARCEL_IDS', parcel_ids)
                }
            }
            return new Promise((res, rej) => {
                res()
            })
        },
        fetch_features(context, ref = null) {
            if (ref) {
                context.commit('SET_REQUEST_REF', ref)
            }

            return axios
                .get(
                    utils.urlJoin(
                        Vue.prototype.$config.VALUATION_API_URL,
                        `request/${ref || context.state.request_reference}?details=full`
                    )
                )
                .then((response) => {
                    if (response.data) {
                        context.commit('SET_OTHER_DATA', response.data.other_data)
                        context.commit('SET_FEATURES', response.data.features)
                        context.commit('SET_CUSTOMER_REF', response.data.customer_ref)
                        context.commit('SET_MAP_SCENE2D', response.data.map_scene2d)
                        context.commit('SET_ADDRESS_TEXT', {
                            ...response.data.address,
                        })
                        if (response.data.features) {
                            context.commit(
                                'SET_BUILDING_TYPE',
                                response.data.features.f_building_type
                            )
                        }
                        if (response.data.map_scene3d) {
                            context.commit('SET_MAP_SCENE3D', response.data.map_scene3d)
                        }
                        return response.data.features
                    }
                })
        },
        create_valuation_request(context, payload) {
            const { customer_ref, ignore_conflicts } = payload
            let params = { customer_ref }
            if (ignore_conflicts) {
                params.ignore_conflicts = 'true'
            }
            params.requested_valuation_type = 'ers'
            return axios
                .post(utils.val_urls(Vue.prototype.$config).request, null, { params })
                .then((response) => {
                    context.commit('SET_REQUEST_REF', response.data.valuation_request_ref)
                    return response
                })
                .catch((error) => {
                    let response = error.response
                    if (
                        response.status === 403 &&
                        response.data.errors?.customer_ref_conflict
                    ) {
                        return response.data.errors.customer_ref_conflict
                            .conflicting_valuation_requests
                    } else {
                        console.error(error)
                        throw error
                    }
                })
        },
        async fetch_simulations(context, request_ref) {
            try {
                const { data: simulations } = await axios.get(
                    utils.urlJoin(
                        Vue.prototype.$config.VALUATION_API_URL,
                        `request/${request_ref}/energy-simulations`
                    )
                )
                return simulations
            } catch (e) {
                console.log(e)
            }
        },
        async fetch_latest_simulation({ commit, dispatch, getters }, request_ref) {
            let simulations = await dispatch('fetch_simulations', request_ref)

            simulations = simulations.map((simulation) => ({
                ...simulation,
                created_at: new Date(simulation.created_at),
            }))

            const latestSimulationRef = simulations.sort(
                (a, b) => b.created_at.getTime() - a.created_at.getTime()
            )[0].ref

            commit('SET_SIMULATION_REF', latestSimulationRef)

            const { data } = await axios.get(
                utils.urlJoin(
                    Vue.prototype.$config.VALUATION_API_URL,
                    `energy-simulation/${latestSimulationRef}?details=full_with_request`
                )
            )
            return {
                data,
                simulation_ref: latestSimulationRef,
            }
        },
        async fetch_simulation(state, simulation_ref) {
            return await axios.get(
                utils.urlJoin(
                    Vue.prototype.$config.VALUATION_API_URL,
                    `energy-simulation/${simulation_ref}?details=full_with_request`
                )
            )
        },
        async load_simulation({ dispatch, commit, getters, state }, payload) {
            const { route } = payload
            const request_ref = route.query.ref

            const { data } = route.query.simulation_ref
                ? await dispatch('fetch_simulation', route.query.simulation_ref)
                : await dispatch('fetch_latest_simulation', request_ref)

            const {
                simulation_result,
                renovation_options,
                saved_scenario,
                valuation_request,
                ref,
            } = data

            commit('RESET_FEATURES')

            commit('SET_SIMULATION_REF', ref)

            const { scenarios } = simulation_result

            const { features, owner, address, valuation_request_ref } = valuation_request

            commit('SET_REQUEST_REF', valuation_request_ref)

            commit('SET_ADDRESS_TEXT', address)

            commit('SET_IS_OWNER', owner.is_self)

            commit('SET_FEATURES', features)
            commit('SET_RESULT_DATA', { scenarios, renovation_options })

            if (route.query.package && getters.getScenariosAvailable) {
                const scenario = getters.getScenarios.find(
                    (scenario) => scenario.name === route.query.scenario
                )
                if (route.query.budget) {
                    commit('SET_BUDGET', route.query.budget)
                }
                if (route.query.targetEpc) {
                    commit('SET_TARGET_EPC', route.query.targetEpc)
                }
                commit('SET_SELECTED_PACKAGE', {
                    packageName: route.query?.package,
                    scenario: {
                        ...scenario,
                        renovations: renovations_from_scenario(
                            scenario,
                            state.results.renovations,
                            state.features
                        ),
                    },
                })
            } else if (saved_scenario && getters.getScenariosAvailable) {
                // load saved scenario if available
                const { package_name, budget, scenario_name, target_epc } = saved_scenario
                let scenario = {}

                if (package_name === 'custom') {
                    const selectedScenario = state.results.data.find(
                        (scenario) => scenario.name === scenario_name
                    )
                    scenario = {
                        ...selectedScenario,
                        renovations: renovations_from_scenario(
                            selectedScenario,
                            state.results.renovations,
                            state.features
                        ),
                    }
                } else {
                    scenario =
                        package_name === 'budget'
                            ? getters.getBudgetScenario(budget)
                            : getters.getTargetEpcScenario(target_epc)
                }

                commit('SET_SELECTED_PACKAGE', {
                    packageName: package_name,
                    scenario,
                })

                commit('SET_BUDGET', budget)
                commit('SET_TARGET_EPC', target_epc)
            } else if (getters.getScenariosAvailable) {
                // else we choose budget package with minimum value by default
                commit('SET_BUDGET', getters.getMinMaxBudget.min)
                commit('SET_TARGET_EPC', getters.getCurrentScenario.epc_ind)
                commit('SET_SELECTED_PACKAGE', {
                    packageName: 'budget',
                    scenario: getters.getBudgetScenario(getters.getBudget),
                })
            }

            commit('SET_SIMULATION_STATUS', {
                status: 'finished',
            })
        },
        fetch_level(context) {
            return axios
                .get(
                    utils.urlJoin(
                        process.env.VUE_APP_API_URL,
                        `features/${context.state.address_text.building_id}?index_type=abex&index_value=1032&multiplicative_adjustment=1`
                    )
                )
                .then((response) => {
                    return response.data.level
                })
        },
        match_address(context) {
            return axios
                .get('/features', {
                    params: {
                        streetnumber: context.state.address_text.streetnumber,
                        streetname: context.state.address_text.streetname,
                        postalcode: context.state.address_text.postalcode,
                        minimum_level: '0',
                    },
                })
                .then((response) => {
                    context.commit('RESET_FEATURES')
                    context.commit('RESET_MAP')
                    context.commit('SELECT_MAIN_BUILDING_ID', response.data.building_id)
                    context.commit('SET_FEATURES', {
                        region: response.data.region,
                        level: response.data.level,
                        effective_level: context.state.address_text.effective_level,
                        building_id: response.data.building_id,
                        building_ids: response.data.building_ids || [],
                        f_lat: response.data.f_lat,
                        f_lng: response.data.f_lng,
                    })
                })
        },
        fetch_map_data(context) {
            return axios
                .get('/map/scene2d', {
                    params: {
                        building_id: context.state.address_text.building_id,
                    },
                })
                .then((response) => {
                    context.commit('SET_MAP_SCENE2D', response.data)
                })
        },
        fetch_map_scene3d(context, solarPotential = false) {
            let buildingId =
                context.state.address_text.building_id ||
                context.state.features.building_id
            if (buildingId) {
                return axios
                    .get(
                        `/map/scene?building_id=${buildingId}&hide_attributions=true&solar_potential=${solarPotential}`
                    )
                    .then((res) => {
                        const isNotValid = utils.isEmptyObject(res.data.building_3d_meta)
                        if (!isNotValid) {
                            context.commit('SET_MAP_SCENE3D', res.data)
                        }
                    })
            }
        },
        fetch_building_features(context) {
            axios
                .get(`/features/${context.state.address_text.building_id}`)
                .then((res) => {
                    context.commit('SET_BUILDING_FEATURES', res.data)
                })
        },
        submit_features(context, routeName) {
            if (
                context.state.features.heating_attributes[0].fuel === 'district_heating'
            ) {
                context.state.features.heating_attributes[0].fuel = null
            }

            let features = { ...context.state.features }

            delete features.building_id
            delete features.building_ids
            delete features.f_building_type
            delete features.parcel_ids

            return axios
                .patch(
                    utils.urlJoin(
                        Vue.prototype.$config.VALUATION_API_URL,
                        `request/house/${context.state.request_reference}`
                    ),
                    {
                        features,
                    }
                )
                .then(() => {
                    axios.patch(
                        utils.urlJoin(
                            Vue.prototype.$config.VALUATION_API_URL,
                            `request/${context.state.request_reference}`
                        ),
                        {
                            other_data: {
                                last_page_info: {
                                    name: routeName,
                                },
                                unsubmitted: context.state.other_data.unsubmitted,
                            },
                        }
                    )
                })
        },
        set_and_submit_feature(context, payload) {
            const { feature, nextPage } = payload
            context.commit('SET_FEATURES', feature)

            if (feature.hasOwnProperty('heating_attributes')) {
                if (feature.heating_attributes[0].fuel === 'district_heating') {
                    feature.heating_attributes[0].fuel = null
                }
            }

            if (
                // level 0.7 is an ambigous case where multiple buildings can be on the same parcel. the level in the address blob can be different so we force the effective_level to follow the features level instead of the address one
                context.getters.getFeatures.level === '0.7' &&
                context.getters.effective_level !== '0.7'
            ) {
                context.commit('SET_EFFECTIVE_LEVEL', 0.7)
                feature.effective_level = context.getters.getEffectiveLevel
            }

            let features = { ...feature }

            delete features.building_id
            delete features.building_ids
            delete features.f_building_type
            delete features.parcel_ids
            return axios.patch(
                utils.urlJoin(
                    Vue.prototype.$config.VALUATION_API_URL,
                    `request/house/${context.state.request_reference}`
                ),
                {
                    features,
                    other_data: {
                        last_page_info: {
                            name: nextPage,
                        },
                        unsubmitted: context.state.other_data.unsubmitted,
                    },
                }
            )
        },
        unsubmit_request(context, routeName) {
            context.commit('SET_UNSUBMITTED')
            context.commit('RESET_SIMULATION_STATE')
            context.commit('FLAG_SUBMISSION', false)
            axios
                .patch(
                    utils.urlJoin(
                        Vue.prototype.$config.VALUATION_API_URL,
                        `request/${context.state.request_reference}`
                    ),
                    {
                        other_data: {
                            last_page_info: {
                                name: routeName,
                            },
                            unsubmitted: context.state.other_data.unsubmitted,
                        },
                    }
                )
                .then(() => {
                    return axios.put(
                        utils.urlJoin(
                            Vue.prototype.$config.VALUATION_API_URL,
                            `request/${context.state.request_reference}/status?action=unsubmit`
                        )
                    )
                })
        },
        submitRequest(context, lang) {
            if (
                context.state.features.heating_attributes[0].fuel === 'district_heating'
            ) {
                context.state.features.heating_attributes[0].fuel = null
            }

            let features = { ...context.state.features }

            delete features.building_id
            delete features.building_ids
            delete features.f_building_type
            delete features.parcel_ids
            context.commit('FLAG_SUBMISSION')
            return axios
                .patch(
                    utils.urlJoin(
                        Vue.prototype.$config.VALUATION_API_URL,
                        `request/house/${context.state.request_reference}`
                    ),
                    {
                        features,
                    }
                )
                .then((res) => {
                    return axios
                        .put(
                            utils.urlJoin(
                                Vue.prototype.$config.VALUATION_API_URL,
                                `request/${context.state.request_reference}/status?action=submit&lang=${lang}`
                            )
                        )
                        .then((res2) => {
                            return res2
                        })
                })
        },
        track_event(context, { event, route, data }) {
            axios.post(utils.urlJoin(Vue.prototype.$config.VALUATION_API_URL, '/track'), {
                event: {
                    name: event,
                    location: {
                        fullPath: route.fullPath,
                        query: route.query,
                        params: route.params,
                        path: route.path,
                        name: route.name,
                    },
                    data,
                },
            })
        },
    },
}

export default ersStore
