<template>
    <div class="main">
        <div class="main__wrapper">
            <div v-if="editing || !borrower" class="main__content">
                <div class="main__content-address">
                    <re-map
                        class="address-map"
                        :building-id="getAddress.building_id"
                        :parcel-ids="get_map_scene2d.parcel_ids"
                        level="2"
                        :center="{
                            lat: get_map_scene2d.center?.lat,
                            lng: get_map_scene2d.center?.lng,
                        }"
                        :map-scene2d="get_map_scene2d"
                        :flood-visible="false"
                        :focus-layer="'building'"
                        read-only
                        marker
                        size="small"
                        :layers-control-visible="false"
                        :options="{
                            zoomControl: false,
                            attributionControl: false,
                            scrollWheelZoom: false,
                            attributionControl: false,
                            scrollWheelZoom: false,
                            doubleClickZoom: false,
                            boxZoom: false,
                            dragging: false,
                        }"
                    />
                    <div class="address-text">
                        <div class="address-text__icon">
                            <img src="@/assets/icons/ic-marker-pin.svg" alt="" />
                        </div>
                        <div class="address-text__content">
                            <p>
                                {{ formatAddress.firstLine }}
                            </p>
                            <p>
                                {{ formatAddress.secondLine }}
                            </p>
                        </div>
                        <p class="request-ref">{{ getRequestRef }}</p>
                    </div>
                </div>
                <form ref="email_form" class="main__form">
                    <h2 class="main__question">
                        {{ $t('valuer_tool.borrower.submitter.email_question') }}
                    </h2>
                    <p class="main__text">
                        {{ $t('valuer_tool.borrower.submitter.email_text') }}
                    </p>
                    <div class="main__input">
                        <b-field grouped position="is-centered" custom-class="is-medium">
                            <b-input
                                v-model="email"
                                size="is-medium"
                                name="email"
                                expanded
                                :placeholder="
                                    $t('valuer_tool.borrower.borrower_email.placeholder')
                                "
                                type="email"
                                autocorrect="off"
                                spellcheck="false"
                                aria-label="borrower email"
                                required
                            ></b-input>
                        </b-field>
                    </div>
                    <h2 class="main__question mt-5">
                        {{ $t('valuer_tool.borrower.submitter.transaction_question') }}
                    </h2>
                    <p class="main__text">
                        {{ $t('valuer_tool.borrower.submitter.transaction_text') }}
                    </p>
                    <div class="main__input">
                        <b-field position="is-centered" custom-class="is-medium">
                            <p class="control">
                                <span class="button is-static is-medium">
                                    €
                                </span>
                            </p>
                            <b-input
                                v-model="transactionValue"
                                size="is-medium"
                                name="transaction_value"
                                expanded
                                :placeholder="
                                    $t(
                                        'valuer_tool.borrower.borrower_trans_val.placeholder'
                                    )
                                "
                                type="number"
                                autocorrect="off"
                                spellcheck="false"
                                aria-label="transaction value"
                                :loading="transactionValue === undefined"
                                :disabled="transactionValue === undefined"
                                required
                            ></b-input>
                        </b-field>
                        <b-field grouped position="is-centered" custom-class="is-medium">
                            <p class="control is-flex is-justify-content-center">
                                <b-button
                                    :disabled="!isFormValid"
                                    size="is-medium "
                                    label="Send"
                                    type="is-primary"
                                    :loading="saving"
                                    @click="saveBorrowerInfo"
                                />
                            </p>
                            <p
                                v-if="editing"
                                class="control is-flex is-justify-content-center"
                            >
                                <b-button
                                    :disabled="!isFormValid"
                                    size="is-medium "
                                    label="Cancel"
                                    type="is-dark"
                                    @click="editing = false"
                                ></b-button>
                            </p>
                        </b-field>
                    </div>
                </form>
            </div>
            <div v-else class="main__success">
                <div class="success__icon">
                    <img src="@/assets/icons/ic-mail.svg" alt="" />
                </div>
                <h2 class="success__title">
                    {{ $t('valuer_tool.borrower.submitter.success_title') }}
                </h2>
                <p class="success__text">
                    {{ $t('valuer_tool.borrower.submitter.success_description') }}
                </p>
                <div class="success__actions">
                    <router-link
                        :to="{ params: { action: 'extra-info' } }"
                        class="success__button gray"
                    >
                        {{ $t('common.previous') }}
                    </router-link>
                    <router-link
                        :to="{ name: 'dashboard' }"
                        class="success__button black"
                    >
                        {{ $t('valuer_tool.borrower.submitter.dashboard_button') }}
                    </router-link>
                    <clear-button
                        class="success__button primary"
                        @click="saveBorrowerInfo"
                    >
                        {{ $t('global.shared.resend_email') }}
                        <b-loading
                            v-model="saving"
                            :is-full-page="false"
                            :can-cancel="false"
                        ></b-loading>
                    </clear-button>
                    <clear-button class="success__button primary" @click="editing = true">
                        {{ $t('global.shared.edit_email') }}
                    </clear-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import ReMap from '@/components/common/Map.vue'
import ClearButton from '@/components/elements/ClearButton.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'OVMSubmitter',
    components: {
        ReMap,
        ClearButton,
    },
    data: () => ({
        email: '',
        editing: false,
        saving: false,
        isFormValid: false,
        transactionValue: undefined,
    }),
    computed: {
        ...mapGetters('valuationStore', [
            'borrower',
            'getTransactionValue',
            'getRequestRef',
        ]),
        ...mapGetters('valuationStore/Address', [
            'getAddress',
            'getFeatures',
            'get_map_scene2d',
        ]),
        formatAddress() {
            return utils.formatAddress(this.getAddress)
        },
    },
    watch: {
        'borrower.email'() {
            this.email = this.borrower.email
        },
        getTransactionValue() {
            this.transactionValue = this.getTransactionValue
        },
        email() {
            this.isFormValid = this.$refs.email_form?.checkValidity()
        },
        transactionValue() {
            this.isFormValid = this.$refs.email_form?.checkValidity()
        },
    },
    mounted() {
        if (this.borrower) this.email = this.borrower.email
        this.transactionValue = this.getTransactionValue
    },
    methods: {
        googleMaps([lat, lng]) {
            return `https://www.google.com/maps?q=${lat},${lng}&z=19`
        },
        saveBorrowerInfo() {
            this.saving = true

            this.$axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_transaction_value(
                            this.$store.state.valuationStore.valuation_request_ref
                        ),
                    { transaction_value: this.transactionValue }
                )
                .then(() => {
                    this.$store.commit(
                        'valuationStore/SET_TRANSACTION_VALUE',
                        this.transactionValue
                    )

                    return this.$axios.put(
                        utils
                            .val_urls(this.$config)
                            .request_ref_borrower(
                                this.$store.state.valuationStore.valuation_request_ref
                            ),
                        null,
                        { params: { email: this.email } }
                    )
                })
                .then(() => {
                    this.$store.commit('valuationStore/UPDATE_STATUS', {
                        borrower: { email: this.email },
                    })
                    this.editing = false
                    if (!(this.editing || !this.borrower)) {
                        this.$buefy.notification.open({
                            message: this.$t('global.shared.email_notification'),
                            duration: 8000,
                            type: 'is-primary',
                            position: 'is-bottom',
                        })
                    }
                })
                .finally(() => {
                    this.saving = false
                })
        },
    },
}
</script>

<style scoped lang="scss">
.main {
    background: #f4f6f9;
    max-width: 100vw;
    flex-grow: 1;
    position: relative;
    min-height: 100%;
    &__wrapper {
        max-width: 45rem;
        margin-inline: auto;
        padding-top: 5rem;
    }
    &__content {
        display: flex;
        padding-top: 6rem;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 3rem;
        flex: 1 0 0;
        min-width: 100%;
        margin-inline: auto;
        min-height: 100%;

        &-address {
            border-radius: 0.5rem;
            border: 1px solid #d6e2e9;
            background: #fff;
            display: flex;
            min-width: 100%;
            flex-grow: 1;
            min-height: 10rem;
        }
    }
    &__success {
        display: flex;
        min-width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin-inline: auto;
    }
    &__question {
        color: #001837;
        text-align: center;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0.75rem;
    }
    &__text {
        color: #798da6;
        text-align: center;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 2rem;
    }
}
.address-map,
.address-text {
    flex: 1 1 0;
}
.address-map {
    min-height: 10rem;
}

.address-text {
    display: flex;
    padding-block: 1rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    &__icon {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.52081rem;
        width: 2.5rem;
        height: 2.5rem;
        margin-block: auto;
        border-radius: 4.16669rem;
        background: rgba(0, 150, 255, 0.2);
    }
    &__content {
        color: #001837;
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem; /* 142.857% */
    }
}

.success {
    &__icon {
        display: flex;
        width: 5rem;
        height: 5rem;
        padding: 1.04169rem;
        justify-content: center;
        align-items: center;
        gap: 1.04169rem;
        border-radius: 8.33331rem;
        background: rgba(0, 150, 255, 0.2);
    }
    &__title {
        color: #001837;
        text-align: center;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    &__text {
        color: #798da6;
        text-align: center;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &__button {
        display: flex;
        padding: 0.75rem 1.25rem;
        align-items: flex-start;
        gap: 0.5rem;
        border-radius: 0.3125rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        &.black {
            background-color: #001837;
            color: #fff;
        }
        &.primary {
            background-color: var(--primary);
            color: #fff;
        }
        &.gray {
            background-color: rgb(223, 228, 235);
            color: rgb(121, 141, 166);
        }
    }
    &__actions {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.dashboard-button {
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.request-ref {
    color: #798da6;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 166.667% */
}
</style>
