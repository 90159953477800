<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('property_type.title') }}

                <Tooltip :label="$t('energy.building_type.title_tooltip')" />
            </h1>
        </div>
        <div class="column is-12">
            <div class="types">
                <div
                    class="type-house enabled"
                    :class="{ active: buildingType === 'house' }"
                    @click="setBuildingType('house')"
                >
                    <div class="type-icon home">
                        <svg-icon
                            icon="home"
                            height="50"
                            scale="1.4"
                            :color="buildingType === 'house' ? '#009CFF' : '#798DA6'"
                        />
                    </div>
                    <div class="type-title">
                        {{ $t('value.house') }}
                    </div>
                    <div class="type-text">
                        {{ $t('value.house_tooltip') }}
                    </div>
                </div>

                <div
                    class="type-house disabled"
                    :class="{ active: buildingType === 'apartment' }"
                >
                    <Tooltip
                        warning
                        class="building-type-tooltip"
                        :label="$t('energy.building_type.unavailable_option')"
                    />
                    <div class="type-icon home">
                        <svg-icon
                            icon="apartment"
                            height="50"
                            scale="1.4"
                            :color="buildingType === 'apartment' ? '#009CFF' : '#798DA6'"
                        />
                    </div>
                    <div class="type-title">
                        {{ $t('value.apartment') }}
                    </div>
                    <div class="type-text">
                        {{ $t('value.apartment_tooltip') }}
                    </div>
                </div>

                <div
                    class="type-house disabled"
                    :class="{ active: buildingType === 'apartment' }"
                >
                    <Tooltip
                        warning
                        class="building-type-tooltip"
                        :label="$t('energy.building_type.unavailable_option')"
                    />
                    <div class="type-icon home">
                        <svg-icon
                            icon="apartment"
                            height="50"
                            scale="1.4"
                            :color="buildingType === 'apartment' ? '#009CFF' : '#798DA6'"
                        />
                    </div>
                    <div class="type-title">
                        {{ $t('value.apartment_block') }}
                    </div>
                    <div class="type-text">
                        {{ $t('value.apartment_block_info') }}
                    </div>
                </div>
            </div>

            <div
                class="is-flex is-flex-direction-row is-justify-content-space-between ers-page-buttons"
            >
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    tag="router-link"
                    :to="{
                        name: 'ers.customer-ref',
                        params: {
                            lang: $route.params.lang,
                        },
                        query: $route.query,
                    }"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <b-button
                    v-if="buildingType"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="nextPage"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import Tooltip from '../elements/Tooltip.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'BuildingType',
    components: {
        Tooltip,
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters('ersStore', ['getFeatures', 'getRequestRef']),
        buildingType: {
            get() {
                return this.$store.state.ersStore.building_type
            },
            set(value) {
                this.SET_BUILDING_TYPE(value)
            },
        },
    },
    watch: {
        buildingType(val) {
            this.nextPage()
        },
    },
    methods: {
        ...mapMutations('ersStore', ['SET_BUILDING_TYPE']),
        nextPage() {
            const nextPage =
                this.buildingType === 'house' ? 'ers.address' : 'ers.out-of-bounds'
            this.$axios
                .patch(
                    utils.urlJoin(
                        this.$config.VALUATION_API_URL,
                        `request/${this.getRequestRef}`
                    ),
                    {
                        features: { f_building_type: 'house' },
                        other_data: {
                            last_page_info: {
                                name: nextPage,
                            },
                        },
                    }
                )
                .then(() => {
                    this.$router.push({
                        name: nextPage,
                        params: {
                            lang: this.$route.params.lang,
                        },
                        query: this.$route.query,
                    })
                })
        },
        setBuildingType(type) {
            this.buildingType = type
        },
    },
}
</script>

<style lang="scss">
.building-type-tooltip {
    .tooltip-content {
        background: #001837 !important;
        &:before {
            border-top-color: #001837 !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.is {
    &-text {
        &_line {
            font-style: normal;
            font-size: 18px;
            line-height: 22px;
        }
    }
    &-btn-box {
        max-width: 760px;
        @media screen and (max-width: 1023px) {
            max-width: 100%;
        }
    }
}

.type-house {
    position: relative;
    transition: all 0.3s ease-in-out;
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 0.3125rem;
    border: 1px solid #d6e2e9;
    background: #fff;
    &.active {
        .home {
            background: rgba(0, 156, 255, 0.2);
        }
    }
    &.enabled {
        &:hover {
            box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);
        }
        cursor: pointer;
    }

    &.disabled {
        .type-title,
        .type-text {
            color: rgba(121, 141, 166, 0.6);
        }
        .type-icon {
            border-radius: 6.25rem;
            border: 1px solid rgba(121, 141, 166, 0.2);
            opacity: 0.6;
            background: rgba(121, 141, 166, 0.1);
        }
    }
    &.active {
        border: 1px solid var(--color-primary);
        box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);

        .type-title {
            color: var(--color-primary);
        }
    }
}

.building-type-tooltip {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.type-title {
    color: #001837;
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.type-text {
    color: #798da6;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem; /* 150% */
}

.type-icon {
    display: flex;
    width: 5rem;
    height: 5rem;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6.25rem;
    border: 1px solid rgba(121, 141, 166, 0.2);
    background: rgba(121, 141, 166, 0.1);
}

.types {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
}

.ers-page-buttons {
    margin-block-start: 3rem;
}
</style>
