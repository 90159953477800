<template>
    <grid-card is-full :title="$t('valuer_tool.exterior.roof_information')">
        <div class="grid_card-content_grid three-cols">
            <div class="content_grid-item">
                <b-field :label="$t('dvm_features.n_roof_dormers')">
                    <b-input v-model="n_roof_dormers" />
                </b-field>
            </div>
            <div class="content_grid-item">
                <b-field :label="$t('dvm_features.n_roof_windows')">
                    <b-input v-model="n_roof_windows" />
                </b-field>
            </div>
        </div>
    </grid-card>
</template>
<script>
import GridCard from '@/apps/Valuation/components/ValuerTool/elements/GridCard'
import { mapGetters, mapMutations } from '@/composables/fake-vuex'
import { useRequest } from '@/composables/request'
export default {
    name: 'RoofInformation',
    components: {
        GridCard,
    },
    data: () => ({
        n_roof_dormers: 0,
        n_roof_windows: 0,
    }),
    setup() {
        const requestObjects = useRequest()
        return {
            ...mapGetters(requestObjects, ['getDVMFeatures']),
            ...mapMutations(requestObjects, ['SET_DVM_FEATURES']),
        }
    },
    watch: {
        // Debounce everything to not trigger updates on every keystroke
        n_roof_dormers(val) {
            this.SET_DVM_FEATURES({
                n_roof_dormers: val,
            })
        },
        n_roof_windows(val) {
            this.SET_DVM_FEATURES({
                n_roof_windows: val,
            })
        },
    },
    mounted() {
        this.n_roof_dormers = this.getDVMFeatures?.n_roof_dormers || 0
        this.n_roof_windows = this.getDVMFeatures?.n_roof_windows || 0
    },
}
</script>
