<template>
    <div class="ers-page-content">
        <div class="ers-page-title">
            <h1>
                {{ $t('energy.window.question') }}
                <Tooltip :html="$t('energy.window.tooltip_popup')" />
            </h1>
        </div>

        <div class="ers-page-windows">
            <div
                v-for="answer in answers"
                :key="`answer_${answer.value}`"
                class="ers-page-windows__item"
                :class="{ active: window_type == answer.value }"
                @click="window_type = answer.value"
            >
                <img :src="answer.picto" alt="" srcset="" />
                <p>{{ $t(`value.${answer.value}`) }}</p>
            </div>
        </div>

        <div class="column is-12 mt-2">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    size="is-medium"
                    type="is-dark"
                    icon-left="arrow-left"
                    @click="changePage(false)"
                >
                    {{ $t('common.previous') }}
                </b-button>
                <submit-button
                    @on-click="
                        $emit('open-summary-modal', {
                            unsubmit: false,
                        })
                        changePage(true)
                    "
                />
                <b-button
                    v-if="showNextButton"
                    :class="{ 'ers-next-bttn-dark': hasRequestBeenUnsubmitted }"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="changePage(true)"
                >
                    <span>{{ $t('common.next') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import SubmitButton from '../../elements/SubmitButton.vue'
import Tooltip from '../../elements/Tooltip'

export default {
    name: 'WindowGlazing',
    components: {
        SubmitButton,
        Tooltip,
    },
    props: {
        proceedFn: {
            type: Function,
            required: false,
            default: null,
        },
        cancelFn: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data: () => ({
        window_type: null,
        isFirstPass: true,
    }),
    computed: {
        ...mapGetters('ersStore', [
            'getFeatures',
            'getOtherData',
            'hasRequestBeenUnsubmitted',
        ]),
        answers() {
            return [
                {
                    value: 'single',
                    picto: this.glazingIcon('single', this.window_type === 'single'),
                },
                {
                    value: 'ordinary_double',
                    picto: this.glazingIcon(
                        'double',
                        this.window_type === 'ordinary_double'
                    ),
                },
                {
                    value: 'hr_double_before_2000',
                    picto: this.glazingIcon(
                        'double',
                        this.window_type === 'hr_double_before_2000'
                    ),
                },
                {
                    value: 'hr_double_after_2000',
                    picto: this.glazingIcon(
                        'double',
                        this.window_type === 'hr_double_after_2000'
                    ),
                },
                {
                    value: 'triple',
                    picto: this.glazingIcon('triple', this.window_type === 'triple'),
                },
            ]
        },
        showNextButton() {
            return this.window_type !== null
        },
    },
    watch: {
        window_type() {
            if (this.isFirstPass) {
                this.changePage(true)
            } else {
                this.isFirstPass = true
            }
        },
    },
    mounted() {
        const prevValue = this.getFeatures.window_type
        if (prevValue) this.isFirstPass = false
        this.window_type = prevValue
    },
    methods: {
        ...mapMutations('ersStore', ['UPDATE_STATUS']),
        ...mapMutations('ersStore', ['SET_FEATURES']),
        ...mapActions('ersStore', ['set_and_submit_feature']),
        changePage(isNextPage) {
            const nextPage = isNextPage ? 'ers.heating' : 'ers.floor-insulation'

            const feature = {
                window_type: this.window_type,
            }

            this.set_and_submit_feature({ feature, nextPage })
            this.$router.push({
                name: nextPage,
                params: {
                    lang: this.$route.params.lang,
                },
                query: this.$route.query,
            })
        },
        glazingIcon(target, active = false) {
            // necessary for dynamic asset import because Vue adds a hash to asset names
            let imgImport = require.context(
                '@/assets/img/ers/glazing-icons',
                false,
                /\.png$/
            )
            return imgImport(`./${target}${active ? '-active' : ''}.png`)
        },
    },
}
</script>

<style scoped lang="scss">
.ers-page-windows {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    &__item {
        color: #001837;
        text-align: center;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: normal;

        display: flex;
        padding: 1.5rem;
        gap: 1.25rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;

        border-radius: 0.3125rem;
        border: 1px solid #d6e2e9;
        background: #fff;
        min-width: 20.25rem;

        img {
            margin: auto;
        }

        &:hover {
            box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);
        }

        &.active {
            border: 1px solid var(--color-primary);
            box-shadow: 0px 2px 14px rgba(0, 150, 255, 0.3);

            p {
                color: var(--color-primary);
            }
        }
    }
}
</style>
